import services from "@services/classroom";
import { handleAxiosError, sendNotification } from "@utils/notifications";
import Student from "@classes/student";
import Classroom from "@classes/classroom";

const initialState = () => ({
  classrooms: [],
  classLoaded: false,
  pendingClassroom: {},
});

const state = initialState();

const mutations = {
  resetState(state) {
    Object.assign(state, initialState());
  },
  /**
   * Mutation that creates classrooms from BE
   *
   * @returns {null}
   */
  SET_CLASSROOMS(state, classrooms) {
    /** reset classrooms from previous calls */
    state.classrooms = [];
    for (const classroom of classrooms) {
      /** for each classroom creates an Object and pushes it to the classrooms array */
      state.classrooms.push(
        new Classroom(classroom.id, classroom.name, classroom.courses)
      );
    }
    state.classLoaded = true;
  },
  /**
   * Mutation that creates students from BE
   *
   * @returns {null}
   */
  SET_STUDENTS(state, { data, classroomId }) {
    /** Find the classroom's array index */
    const classIndex = state.classrooms.findIndex(
      (classroom) => classroom.id === classroomId
    );
    /** if I've found the classroom */
    if (classIndex >= 0) {
      /** reset students and pendings from the classrooms found */
      state.classrooms[classIndex].students = [];
      /** if BE returns valid values */
      if (data !== null) {
        for (const student of data) {
          /** for each student creates a Student Object and pushes it to the classrooms Array  */
          state.classrooms[classIndex].students.push(
            new Student(
              student.email,
              student.name,
              student.surname,
              student.id,
              student.sec_email,
              student.can_contact
            )
          );
        }
      }
    }
  },
  SUBSCRIBE_CLASS_TO_COURSE(state, { courseId, classroomId }) {
    const i = state.classrooms.findIndex((c) => c.id === classroomId);
    state.classrooms[i].courses.push(courseId);
  },
  UNSUBSCRIBE_CLASS_FROM_COURSE(state, { courseId, classroomId }) {
    const i = state.classrooms.findIndex((c) => c.id === classroomId);
    const j = state.classrooms[i].courses.findIndex((c) => c === courseId);
    state.classrooms[i].courses.splice(j, 1);
  },
  /**
   * Mutation that sets the pending classroom from BE
   *
   * @returns {null}
   */
  SET_PENDING_CLASSROOM(state, classroom) {
    /** reset pendingClassroom from previous calls */
    state.pendingClassroom = new Classroom(
      classroom.id,
      classroom.name,
      [],
      classroom.professor,
      classroom.school
    );
  },
};
const actions = {
  /**
   * Action that gets classrooms from BE
   *
   * @returns {Object} data that contains classrooms
   * @throws err if the call throws an error
   */
  async getClassroomsProf({ commit }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      const { data } = await services.getClassroomsProf();
      commit("SET_CLASSROOMS", data);
      return data;
    } catch (err) {
      await handleAxiosError(
        `Problema durante il caricamento delle classi, provare a ricaricare la pagina`,
        err
      );
      return null;
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  /**
   * Action that gets students from a given classroom
   *
   * @param {String} id of the classroom
   * @returns {Object} data that contains classrooms
   * @throws err if the call throws an error
   */
  async getClassroomStudents({ commit }, id) {
    try {
      commit("loading/startLoading", {}, { root: true });
      const { data } = await services.getClassroomStudents(id);
      commit("SET_STUDENTS", { data, classroomId: id });
      return data;
    } catch (err) {
      await handleAxiosError(
        `Problema durante il caricamento degli studenti, provare a ricaricare la pagina`,
        err
      );
      return null;
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  /**
   * Action that adds new classroom
   *
   * @param {String} className of the classroom
   * @returns {Object} className
   * @throws err if the call throws an error
   */
  async addNewClassroom({ dispatch, commit }, className) {
    try {
      commit("loading/startLoading", {}, { root: true });
      await services.addNewClassroom(className);
      sendNotification(`Classe creata con successo`, `success`);
      await dispatch("getClassroomsProf");
      return className;
    } catch (err) {
      await handleAxiosError(
        `Problema durante la creazione della classe, provare a ricaricare la pagina`,
        err
      );
      return null;
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  /**
   * Action that deletes classroom from a given id
   *
   * @param {String} idToDelete of the classroom
   * @returns {String} idToDelete id of the classroom
   * @throws err if the call throws an error
   */
  async deleteClassroom({ dispatch, commit }, idToDelete) {
    try {
      commit("loading/startLoading", {}, { root: true });
      await services.deleteClassroom(idToDelete);
      sendNotification(`Classe eliminata con successo`, `success`);
      await dispatch("getClassroomsProf");
      return idToDelete;
    } catch (err) {
      await handleAxiosError(
        `Problema durante l'eliminazione della classe, provare a ricaricare la pagina`,
        err
      );
      return null;
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  /**
   * Action that deletes classroom from a given id
   *
   * @param {String} idToDelete of the classroom
   * @returns {String} idToDelete id of the classroom
   * @throws err if the call throws an error
   */
  async renameClassroom({ dispatch, commit }, { idToRename, newName }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      await services.renameClassroom(idToRename, newName);
      sendNotification(`Classe rinominata con successo`, `success`);
      await dispatch("getClassroomsProf");
      return idToRename;
    } catch (err) {
      await handleAxiosError(
        `Problema durante la modifica della classe, provare a ricaricare la pagina`,
        err
      );
      return null;
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  /**
   * Action that add a new student
   *
   * @param {string} email of the student
   * @param {string} classroomId
   * @returns {Object} that contains given data
   * @throws err if the call throws an error
   */
  async addStudent({ dispatch, commit }, { email, classroomId }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      await services.addStudent(email, classroomId);
      sendNotification(`Studente ${email} aggiunto con successo`, `success`);
      dispatch("getClassroomStudents", classroomId);
      return { email, classroomId };
    } catch (err) {
      await handleAxiosError(
        `Problema durante l'aggiunta dello studente, controlla la formattazione dell'email inserita`,
        err
      );
      return null;
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },

  /**
   * Action that adds new students through a xlsx file
   *
   * @param {file} file of the classroom
   * @param {string} classroomId
   * @returns {Object} that contains given data
   * @throws err if the call throws an error
   */

  async uploadStudents({ dispatch, commit }, { file, classroomId }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      await services.uploadStudents(file, classroomId);
      sendNotification(`Studenti aggiunti con successo`, `success`);
      dispatch("getClassroomStudents", classroomId);
      return { file, classroomId };
    } catch (err) {
      await handleAxiosError(
        `Problema durante l'aggiunta degli studenti, controlla il formato del file`,
        err
      );
      return null;
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },

  /**
   * Action that deletes a student from the classroom only if already registered (no pending)
   *
   * @param {String} classroomId of the student
   * @param {String} studentId of the student
   * @returns {Object} that contains given data
   * @throws err if the call throws an error
   */
  async removeStudent(
    { dispatch, commit },
    { classroomId, studentId, studentEmail }
  ) {
    try {
      commit("loading/startLoading", {}, { root: true });
      if (studentId) {
        await services.removeStudent(classroomId, studentId);
      } else {
        await services.removePendingStudent(classroomId, studentEmail);
      }
      sendNotification(`Studente rimosso con successo`, `success`);
      dispatch("getClassroomStudents", classroomId);
      return { classroomId, studentId };
    } catch (err) {
      await handleAxiosError(
        `Problema durante la rimozione dello studente, provare a ricaricare la pagina`,
        err
      );
      return null;
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async confirmInvite({ commit }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      const { data } = await services.confirmInvite();
      commit("auth/SET_TOKEN", data, { root: true });
      const STUDENT_CODE = 3;
      commit("auth/SET_ACCESS_LEVEL", STUDENT_CODE, { root: true });
      sendNotification(`Invito alla classe accettato con successo!`, `success`);
      return;
    } catch (err) {
      await handleAxiosError(
        `Si è verificato un errore durante l'accettazione dell'invito.`,
        err
      );
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async rejectInvite({ commit }) {
    try {
      commit("loading/startLoading", {}, { root: true });

      const { data } = await services.rejectInvite();
      commit("auth/SET_TOKEN", data, { root: true });
      sendNotification(`Invito alla classe rifiutato con successo!`, `success`);
      return;
    } catch (err) {
      await handleAxiosError(
        `Si è verificato un errore durante il rifiuto dell'invito.`,
        err
      );
      throw err;
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async getPendingClassroom({ commit }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      const { data } = await services.getPendingClassroom();
      commit("SET_PENDING_CLASSROOM", data);
      return data;
    } catch (err) {
      await handleAxiosError(
        `Problema durante il caricamento della classe, provare a ricaricare la pagina`,
        err
      );
      return null;
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  /**
   * Action that subscribe a classroom to a quiz
   *
   * @param {String} classroomID
   * @param {String} quizID
   * @returns {Null} null
   * @throws err if the call throws an error
   */
  async subscribeClassroomToQuiz(
    { commit, dispatch },
    { classroomID, quizID }
  ) {
    try {
      commit("loading/startLoading", {}, { root: true });
      await services.subscribeQuiz(classroomID, quizID);
      sendNotification(`Classe iscritta al quiz!`, `success`);
      dispatch("quiz/getQuizzes", {}, { root: true });
      return null;
    } catch (err) {
      await handleAxiosError(
        `Problema durante l'iscrizione della classe al quiz.`,
        err
      );
      return null;
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  /**
   * Action that unsubscribe a classroom to a quiz
   *
   * @param {String} classroomID
   * @param {String} quizID
   * @returns {Null} null
   * @throws err if the call throws an error
   */
  async unsubscribeClassroomToQuiz(
    { commit, dispatch },
    { classroomID, quizID }
  ) {
    try {
      commit("loading/startLoading", {}, { root: true });
      await services.unsubscribeQuiz(classroomID, quizID);
      sendNotification(`Classe disiscritta al quiz!`, `success`);
      dispatch("quiz/getQuizzes", {}, { root: true });
      return null;
    } catch (err) {
      await handleAxiosError(
        `Problema durante la disiscrizione della classe al quiz.`,
        err
      );
      return null;
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async getClassroomsByLpId(_context, { learningPathId }) {
    try {
      const { data } = await services.getClassroomsByLpId(learningPathId);
      return data;
    } catch (err) {
      await handleAxiosError(
        "Errore nel recupero delle classi associate al corso ondemand.",
        err
      );
      return null;
    }
  },
};

const getters = {
  /**
   * Getter that returns classrooms
   *
   * @returns {Array} classrooms
   */
  getClassrooms(state) {
    return state.classrooms;
  },
  /**
   * Getter that returns the pending Classroom
   *
   * @returns {Array} pendingClassroom
   */
  getPendingClassroom(state) {
    return state.pendingClassroom;
  },
  /**
   * Getter that returns registered students
   *
   * @param  {String} classroomId
   * @returns {Array} students
   */
  getStudents: (state) => (classroomId) =>
    state.classrooms.find((classroom) => classroom.id === classroomId).students,
  /**
   * Getter that returns classrooms already registered to a course
   *
   * @param  {String} courseId
   * @returns {Array} classrooms
   */
  getClassroomsAlreadyRegistered: (state) => (courseId) =>
    state.classrooms.filter((classroom) => {
      for (const course of classroom.courses) {
        if (course === courseId) {
          return true;
        }
      }
      return false;
    }),
  /**
   * Getter that returns classrooms that aren't registered to a course
   *
   * @param {string} courseId
   * @returns {Array} unregistered classrooms
   */
  getClassroomsNotAlreadyRegistered: (state) => (courseId) =>
    state.classrooms.filter((classroom) => {
      for (const course of classroom.courses) {
        /** if course === courseId => the classroom is already registered, skip */
        if (course === courseId) {
          return false;
        }
      }
      return true;
    }),
  /**
   * Getter that returns pending students of a given classroomId
   *
   * @param {string} classroomId
   * @returns {Array} pending students
   */
  getPendingStudents: (state) => (classroomId) =>
    state.classrooms.find((classroom) => classroom.id === classroomId).pendings,
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
