<template>
  <v-dialog
    :persistent="persistent"
    v-model="alwaysTrue"
    content-class="custom-dialog"
    :max-width="width"
    :scrollable="scrollable"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    @click:outside="handleOutsideClick()"
  >
    <v-card
      v-if="!background"
      :class="{
        'rounded-xl': $vuetify.breakpoint.xsOnly,
        glassify,
        'bit-text': bitText
      }"
      :dark="glassify"
    >
      <v-btn
        v-if="showCloseButton"
        class="modal-close-button ma-3"
        icon
        color="red"
        :dark="glassify"
        @click="$emit('closeModal')"
      >
        <v-icon large>
          mdi-close
        </v-icon>
      </v-btn>
      <v-card-title
        v-if="title"
        align="start"
        class="modal-title"
        :dark="glassify"
        :class="titleLeft ? 'title-left' : null"
      >
        {{ title }}
      </v-card-title>
      <v-card-text
        v-if="$slots['content']"
        class="modal-text"
      >
        <slot name="content" />
      </v-card-text>
      <v-card-text
        v-if="$slots['content-center']"
        class="modal-text-center"
      >
        <slot name="content-center" />
      </v-card-text>
    </v-card>
    <v-card
      v-else
      class="modal-bg"
    >
      <button
        v-if="showCloseButton"
        class="modal-close-button"
        icon
        :dark="glassify"
        @click="$emit('closeModal')"
      >
        <v-icon large>
          mdi-close
        </v-icon>
      </button>
      <v-card-title
        class="modal-title textwhite"
        :class="titleLeft ? 'title-left' : null"
      >
        {{ title }}
      </v-card-title>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "Modal",
  props: {
    title: {
      type: String,
      required: true,
    },
    background: {
      type: Boolean,
    },
    width: {
      type: String,
      default: "1000px",
    },
    glassify: {
      type: Boolean(),
      default: true,
    },
    bitText:{
      type: Boolean(),
      default: true,
    },
    titleLeft: Boolean,
    showCloseButton: {
      type: Boolean(),
      default: true,
    },
    scrollable: {
      type: Boolean(),
      default: false,
    },
    persistent: {
      type: Boolean(),
      default: false,
    }
  },
  emits:["closeModal"],
  data() {
    return {
      alwaysTrue: true,
    };
  },
  methods: {
    handleOutsideClick() {
      if (this.persistent) {
        return;
      }
      this.$emit('closeModal');
    }
  }
};
</script>

<style>
/** do not scope this style !! or classes applied to dialog won't work  **/

.custom-dialog {
  border-radius: 10px !important;
  overflow: auto;
}

.bit-text >
.modal-title,
.bit-text >
.modal-text,
.bit-text >
.modal-text-center {
  font-family: "Karasuma Gothic", sans-serif;
  justify-content: center;
  font-size: 1.2em;
}

.title-left {
  justify-content: start;
}

.modal-text {
  text-align: start;
}

.modal-title {
  font-family: 'outerorbit', 'Orbitron' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 24px !important;
  line-height: 24px !important;
  text-transform: uppercase !important;
  color: #F4F5F6 !important;
}

.modal-close-button {
  margin-top: 5px;
  margin-right: 5px;
  font-size: 1em !important;
  position: absolute;
  top: 0;
  right: 0;
}

.modal-close-button > i {
  color: red !important;
}

.modal-bg {
  background-color: #01002f;
}

.textwhite {
  color: white !important;
}

::-webkit-scrollbar-track {
  border-radius: 10px !important;
  background-color: #8f9092 !important;
}

::-webkit-scrollbar {
  width: 10px !important;
  border-radius: 10px !important;
  background-color: transparent !important;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px !important;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
  background-color: #636490 !important;
}

.v-card__title {
  word-break: normal; /* maybe !important  */
}
</style>
