<template>
  <div
    class="desktop-menu-tile mb-3"
    :class="{ 'desktop-menu-tile_disabled': !isOpen }"
    @click="changeRoute"
  >
    <h3
      class="desktop-menu-tile--text"
      :style="{ color: isActive ? '#F39200' : '#F4F5F6' }"
      :class="{ 'journey-text': text === 'Journey' }"
    >
      {{ text }}
    </h3>
    <img
      :src="
        require(`@assets/menu/desktopMenu/DesktopMenuTile-${
          isActive ? 'active' : 'inactive'
        }.svg`)
      "
      alt="Menu Option"
      :class="{ 'journey-image': text === 'Journey' }"
    />
  </div>
</template>

<script>
import { pushJourneyUrl } from "@/utils/externalRouter";

export default {
  name: "DesktopMenuTile",
  props: {
    text: {
      type: String,
      required: true,
    },
    routeName: {
      type: String,
      required: true,
    },
    urlPrefix: {
      type: String,
      required: true,
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    isActive() {
      return this.$route.name === this.urlPrefix + "-" + this.routeName;
    },
  },
  methods: {
    changeRoute() {
      if (this.isOpen) {
        if (this.routeName.includes("journey")) {
          const token = JSON.parse(localStorage.getItem("user"));
          pushJourneyUrl("/auth/sso", token.access_token);
          return;
        } else if (this.routeName.includes("classes")) {
          pushJourneyUrl("/classroom-management");
        } else
          this.$router.push({ name: this.urlPrefix + "-" + this.routeName });
      }
    },
  },
};
</script>

<style scoped>
.desktop-menu-tile {
  width: 232px;
  height: 41px;
  position: relative;
  cursor: pointer;
  display: block;
}
.desktop-menu-tile_disabled {
  cursor: default;
  opacity: 0.5;
}

.desktop-menu-tile--text {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-family: "outerorbit", "Orbitron";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  text-transform: uppercase;
  line-height: 20px;
  text-align: center;
}
.journey-image {
  filter: invert(50%) sepia(100%) saturate(745%) hue-rotate(338deg)
    brightness(95%) contrast(87%);
}
.journey-text {
  color: orange !important;
}
</style>
