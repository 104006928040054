import learningResources from "../../services/learningResources";
import trackerService from "../../services/ondemandTracker";

const initialState = () => ({
  token: localStorage.getItem("ondemand-tracker-token"),
  episodeId: "",
});

const state = initialState();
const actions = {
  async refreshToken({ commit, state }) {
    try {
      const { data } = await learningResources.getVideoTrackerToken(
        state.episodeId
      );
      commit("setTrackerToken", {
        trackerToken: data,
      });
      return data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  },
  async getTrackerToken({ commit }, episodeId) {
    try {
      const { data } = await learningResources.getVideoTrackerToken(episodeId);
      commit("setTrackerToken", {
        trackerToken: data,
        episodeId,
      });
      return data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  },
  async pingVideo() {
    try {
      const data = await trackerService.createPing();
    } catch (err) {
      console.error(err);
      throw err;
    }
  },
  setTrackerToken({ commit }, trackerInfo) {
    commit("setTrackerToken", trackerInfo);
  },
};
const mutations = {
  resetState(state) {
    Object.assign(state, initialState());
  },
  setTrackerToken(state, { trackerToken, episodeId = null }) {
    if (trackerToken) {
      state.token = trackerToken;
      if (episodeId) {
        state.episodeId = episodeId;
      }
      localStorage.setItem("ondemand-tracker-token", trackerToken);
    }
  },
};
export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
