import { mapGetters } from "vuex";
import userHandler from "./userHandler";

export default {
  props: {
    type: {
      type: Number,
      default: 2,
    },
  },
  mixins: [userHandler],
  computed: {
    ...mapGetters("courses", ["courses"]),
    getCourses() {
      return this.courses.filter((course) => {
        if (this.type === 2) {
          if (!course.onDemand) {
            return true;
          }
        } else if (this.type === 1) {
          if (course.onDemand) {
            return true;
          }
        } else {
          if (course.subscribed) {
            return true;
          }
        }
        return false;
      });
    },
    // function than retrives correct title based on course type
    getTitle() {
      if (this.type === 1) {
        return "On Demand";
      } else if (this.type === 2) {
        return "Corsi Live";
      } else if (this.isTeacher) {
        return "Tutti i corsi";
      } else if (this.isGuest) {
        return "Tutti i corsi";
      }
      return "I miei corsi";
    },
    getLink() {
      return (course) => {
        if (course.episodes && course.episodes.length > 0) {
          return `/course/${course.id}/video/${course.episodes[0].videoId}`;
        }
        return `/waiting`;
      };
    },
  },

  methods: {
    getLastEpisodeDate(course) {
      const now = Date.now() / 1000;
      return course.episodes.some(
        (episode) => episode.start > now
      );
    },

    getLastEpisodeDateGreaterThanMonthAgo(course) {
      const monthAgo = (Date.now() - 2678400000) / 1000; // 2678400000 ms = 31 days
      return course.episodes.some(
        (episode) => episode.start > monthAgo
      );
    },

    outlineCourse(index, course) {
      return (
        (index === 0 &&
          !course.onDemand &&
          this.getLastEpisodeDate(course)) ||
        (course.onDemand &&
          this.getLastEpisodeDateGreaterThanMonthAgo(
            course
          ))
      );
    },
  },
};
