import Vue from "vue";
import VueRouter from "vue-router";
import Meta from "vue-meta";

import store from "@/store";

import routes from "./routes";
import NProgress from "nprogress/nprogress";
import "nprogress/nprogress.css";
import { pushAuthUrl } from "@utils/externalRouter";

NProgress.configure({
  easing: "ease",
  speed: 500,
  showSpinner: false,
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject);
  }
  return originalPush.call(this, location).catch((err) => {
    if (VueRouter.isNavigationFailure(err)) {
      // resolve err
      return err;
    }
    // rethrow error
    return Promise.reject(err);
  });
};

Vue.use(VueRouter);

Vue.use(Meta, {
  // The component option name that vue-meta looks for meta info on.
  keyName: "metaInfo",
  refreshOnceOnNavigation: true,
});

const router = new VueRouter({
  routes,
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
});

function handleRouting(next) {
  if (!store.getters["auth/isAuthenticated"]) {
    pushAuthUrl();
  } else if (store.getters["auth/isTeacher"]) {
    if (store.getters["auth/decodedToken"].school) {
      next({ name: "teacher-platform-home-page" });
    } else {
      next({ name: "teacher-school-choice" });
      return;
    }
  } else if (store.getters["auth/isStudent"]) {
    if (store.getters["auth/decodedToken"].asked_sec_email) {
      next({ name: "student-platform-home-page" });
    } else {
      next({ name: "student-update-sec-email" });
      return;
    }
    if (!store.getters["auth/decodedToken"].has_pending_class) {
      next({ name: "student-platform-home-page" });
    } else {
      next({ name: "pending-classroom" });
      return;
    }
  } else if (store.getters["auth/isGuest"]) {
    if (!store.getters["auth/decodedToken"].has_pending_class) {
      next({ name: "guest-platform-home-page" });
    } else {
      next({ name: "pending-classroom" });
      return;
    }
  } else if (store.getters["auth/isSorint"]) {
    next({ name: "sorint-platform-home-page" });
  }
}

/* eslint-disable consistent-return */
/* eslint-disable complexity */
router.beforeEach((to, from, next) => {
  if (to.name === "root") {
    handleRouting(next);
    return;
  }
  if (from.name !== null) {
    NProgress.start();
  }

  store.commit("loading/startLoading");

  if (to.matched.some((record) => record.meta.accessible)) {
    next();
    return;
  } else if (to.matched.some((record) => record.meta.noAbsoluteAuth)) {
    handleRouting(next);
  } else {
    if (store.getters["auth/isAuthenticated"]) {
      /* Un giorno in ms */
      const one_day = 1000 * 3600 * 24;

      /* Se il token è scaduto anche per il refresh, faccio direttamente il logout */
      const now = new Date();
      const exp_refresh = store.getters["auth/user"].login_time + one_day;

      if (now > exp_refresh) {
        store.dispatch("auth/logout");
        return pushAuthUrl();
      }
      /* Se il token è valido */
      if (to.matched.some((record) => record.meta.requiresAuth)) {
        next();
      } else if (
        to.matched.some((record) => record.name?.includes("teacher"))
      ) {
        if (!store.getters["auth/isTeacher"]) {
          if (from.matched.some((record) => record.name === "401")) {
            pushAuthUrl();
          }
          next({ name: "401" });
        } else {
          if (store.getters["auth/decodedToken"].school) {
            if (to.name === "teacher-school-choice") {
              next({ name: "teacher-platform-home-page" });
            }
          } else {
            if (to.name !== "teacher-school-choice") {
              next({ name: "teacher-school-choice" });
            }
          }
        }
      } else if (
        to.matched.some((record) => record.name?.includes("student"))
      ) {
        if (!store.getters["auth/isStudent"]) {
          if (from.name === "401") {
            pushAuthUrl();
          }
          next({ name: "401" });
        } else {
          if (store.getters["auth/decodedToken"].asked_sec_email) {
            if (to.name !== "student-update-sec-email") {
              next();
            } else if (to.name === "student-update-sec-email") {
              next({ name: "student-platform-home-page" });
            }
          } else {
            if (to.name !== "student-update-sec-email") {
              next({ name: "student-update-sec-email" });
            }
          }
          if (!store.getters["auth/decodedToken"].has_pending_class) {
            if (to.name === "pending-classroom") {
              next({ name: "student-platform-home-page" });
            }
          } else {
            if (to.name !== "pending-classroom") {
              next({ name: "pending-classroom" });
            }
          }
        }
      } else if (to.matched.some((record) => record.name?.includes("guest"))) {
        if (!store.getters["auth/isGuest"]) {
          if (from.name === "401") {
            pushAuthUrl();
          }
          next({ name: "401" });
        } else {
          if (!store.getters["auth/decodedToken"].has_pending_class) {
            if (to.name === "pending-classroom") {
              next({ name: "guest-platform-home-page" });
            }
          } else {
            if (to.name !== "pending-classroom") {
              next({ name: "pending-classroom" });
            }
          }
        }
      } else if (to.matched.some((record) => record.name?.includes("admin"))) {
        if (!store.getters["auth/isAdmin"]) {
          if (from.name === "401") {
            pushAuthUrl();
          }
          next({ name: "401" });
        }
      } else if (to.matched.some((record) => record.name?.includes("sorint"))) {
        if (!store.getters["auth/isSorint"]) {
          if (from.name === "401") {
            pushAuthUrl();
          }
          next({ name: "401" });
        }
      }
    } else {
      next({ path: "/" });
    }
  }
  next();
});

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  store.commit("loading/removeLoading");
  NProgress.done();
});

router.onError(() => {
  store.commit("loading/removeLoading");
});

export default router;
