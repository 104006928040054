var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pa-4 pl-md-2",attrs:{"fill-height":""}},[_c('v-row',{staticClass:"chat-msg",attrs:{"id":`msg-list-${_vm.roomId}`},on:{"scroll":_vm.onScroll}},[(_vm.messages.length > 0)?_c('v-col',[_c('transition-group',{attrs:{"name":"opacity"}},_vm._l((_vm.messages),function(message,i){return _c('div',{key:`message-${i}`,staticClass:"my-3"},[(
              i === 0 ||
              message.ts.getDate() !== _vm.messages[i - 1].ts.getDate() ||
              message.ts.getMonth() !== _vm.messages[i - 1].ts.getMonth()
            )?_c('div',{staticClass:"d-flex justify-center"},[_c('span',{staticClass:"my-5 text-subtitle-2 text-center justify-center px-4 py-1 rounded-pill white--text",class:_vm.projects ? 'project-date' : 'dashboard-date'},[_vm._v(" "+_vm._s(`${message.ts.getDate()} ${message.ts.toLocaleString( "default", { month: "long" } )}`)+" ")])]):_vm._e(),(
              message.u._id !== _vm.userId &&
              (i === 0 || message.u.name !== _vm.messages[i - 1].u.name)
            )?_c('span',{staticClass:"d-block text-caption",class:message.u._id === _vm.userId ? 'text-right' : 'text-left'},[_vm._v(" "+_vm._s(message.u.name)+" ")]):_vm._e(),(message.u._id === _vm.userId)?_c('div',{staticClass:"d-flex flex-column msg-text-wrapper align-end"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({staticClass:"text-body-2 d-block rounded-xl rounded-tr-sm pa-3",class:_vm.projects
                      ? 'projects-personal-msg'
                      : 'dashboard-personal-msg'},'div',attrs,false),on),[_vm._v(" "+_vm._s(message.msg)+" ")])]}}],null,true),model:{value:(message.show_time),callback:function ($$v) {_vm.$set(message, "show_time", $$v)},expression:"message.show_time"}},[_c('span',[_vm._v(" "+_vm._s(`${_vm.pad(message.ts.getHours())}:${_vm.pad( message.ts.getMinutes() )}`)+" ")])])],1):_c('div',{staticClass:"d-flex flex-column msg-text-wrapper align-start"},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({staticClass:"text-body-2 d-block rounded-xl rounded-tl-sm pa-3 msg"},'div',attrs,false),on),[_vm._v(" "+_vm._s(message.msg)+" ")])]}}],null,true),model:{value:(message.show_time),callback:function ($$v) {_vm.$set(message, "show_time", $$v)},expression:"message.show_time"}},[_c('span',[_vm._v(_vm._s(`${_vm.pad(message.ts.getHours())}:${_vm.pad( message.ts.getMinutes() )}`))])])],1)])}),0)],1):_c('v-col',[_c('span',{staticClass:"text-caption d-block text-center"},[_vm._v("Nessun messaggio")])])],1),_c('v-row',{staticClass:"chat-bottom mx-1"},[_c('v-col',{attrs:{"cols":"10"}},[_c('v-textarea',{staticClass:"rounded-lg",attrs:{"solo":"","no-resize":"","rows":"2","row-height":"15","label":"Scrivi un messaggio"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return _vm.sendMsg.apply(null, arguments)}},model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}})],1),_c('v-col',{attrs:{"cols":"2","align":"right"}},[_c('v-btn',{class:_vm.projects ? 'deep-purple accent-1' : 'orange darken-2',attrs:{"disabled":_vm.text.length === 0,"fab":""},on:{"click":_vm.sendMsg}},[_c('v-icon',{staticClass:"white--text"},[_vm._v(" mdi-send ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }