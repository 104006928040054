<template>
  <v-container id="container-create" class="pt-0">
    <v-row no-gutters>
      <v-col cols="12" md="8">
        <h2 class="class-title">Gestione classi</h2>
        <p class="class-subtitle pt-5">
          Ti diamo il benvenuto nell’area di
          <strong>Gestione Classi</strong>.<br />
          Da qua potrai creare, eliminare, modificare e gestire le tue aule
          virtuali, permettendo ai tuoi studenti di avere accesso ai nostri
          corsi.
        </p>
      </v-col>

      <v-col align="end" cols="12" md="4">
        <v-btn
          id="download-teacher-guide"
          large
          dark
          outlined
          class="rounded-lg mb-4"
          color="orange"
          target="_blank"
          href="/docs/guida_docenti_2022.pdf"
          @click="
            () => trackEvent('PlatformClassesPage', 'DownloadTeachersGuide')
          "
        >
          <v-icon left dark>mdi-tray-arrow-down</v-icon>
          <span>Guida Insegnanti</span>
        </v-btn>
      </v-col>
    </v-row>

    <h4 class="class-list-title pt-4 mb-3">Lista classi</h4>
    <v-row no-gutters>
      <v-col
        v-for="(classroom, index) in classrooms"
        :key="index"
        cols="12"
        sm="6"
        md="2"
        :class="{
          'px-2': $vuetify.breakpoint.smAndDown,
        }"
      >
        <v-card
          :width="$vuetify.breakpoint.mdAndUp ? '140' : '100%'"
          :height="$vuetify.breakpoint.mdAndUp ? '120' : '45'"
          class="classrooms-info-card mb-3 pa-3"
          :class="{
            'classroom-clicked': selectedClass.id === classroom.id,
            'py-1': $vuetify.breakpoint.smAndDown,
          }"
          @click="selectClass(classroom.id, classroom.name)"
        >
          <v-flex
            :class="{
              'd-flex pt-0': $vuetify.breakpoint.smAndDown,
            }"
          >
            <v-row justify="center" no-gutters>
              <h5
                class="classroom-name-card"
                :class="{
                  'classroom-name-card-clicked':
                    selectedClass.id === classroom.id,
                  'pt-2': $vuetify.breakpoint.mdAndUp,
                }"
              >
                {{ classroom.name }}
              </h5>
            </v-row>
            <v-row
              class="justify-center"
              no-gutters
              :class="{
                'pt-2': $vuetify.breakpoint.mdAndUp,
              }"
            >
              <v-btn icon>
                <v-icon
                  class="delete-class-icon"
                  :color="
                    selectedClass.id === classroom.id ? '#F39200' : '#F4F5F6'
                  "
                  @click.stop="openRenameModal(classroom)"
                >
                  mdi-pencil
                </v-icon>
              </v-btn>
              <v-btn icon>
                <v-icon
                  class="delete-class-icon"
                  :color="
                    selectedClass.id === classroom.id ? '#F39200' : '#F4F5F6'
                  "
                  @click.stop="openDeleteModal(classroom)"
                >
                  mdi-delete-outline
                </v-icon>
              </v-btn>
            </v-row>
          </v-flex>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="2"
        :class="{
          'px-2': $vuetify.breakpoint.smAndDown,
        }"
      >
        <v-card
          :width="$vuetify.breakpoint.mdAndUp ? '140' : '100%'"
          :height="$vuetify.breakpoint.mdAndUp ? '120' : '45'"
          class="classrooms-info-card mb-3 pa-3"
          @click="triggerDialog(true)"
        >
          <v-flex
            :class="{
              'd-flex pt-0': $vuetify.breakpoint.smAndDown,
            }"
          >
            <v-row no-gutters justify="center">
              <v-img
                v-if="$vuetify.breakpoint.mdAndUp"
                src="@assets/plus.svg"
                max-width="40"
                :class="{
                  'mt-2': $vuetify.breakpoint.mdAndUp,
                }"
              />
              <v-icon v-else color="#7FBAF4" size="32"> mdi-plus </v-icon>
            </v-row>
            <v-row no-gutters justify="center">
              <h5
                class="add-classroom-card"
                :class="{
                  'mt-2': $vuetify.breakpoint.mdAndUp,
                }"
              >
                Crea classe
              </h5>
            </v-row>
          </v-flex>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col order="2" order-lg="1" cols="12">
        <v-card
          v-if="classrooms.length > 0 && selectedClass"
          class="pa-4 content-card rounded-xl"
          dark
        >
          <v-row class="mb-2">
            <v-col cols="12" md="9">
              <h4 class="content-card-title pl-2">
                Gestione classe: {{ selectedClass.name }}
              </h4>
            </v-col>
            <v-col
              cols="12"
              md="3"
              class="pt-0"
              :class="{
                'd-flex justify-end': $vuetify.breakpoint.mdAndUp,
              }"
            >
              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <v-btn
                    large
                    color="#00A1FF"
                    class="white--text mt-2 rounded-lg copy-button"
                    :width="$vuetify.breakpoint.smAndDown ? '100%' : '206'"
                    height="40"
                    v-bind="attrs"
                    @click="showCopyDialog = true"
                    v-on="on"
                  >
                    <v-icon left dark> mdi-content-copy </v-icon>
                    Copia link invito
                  </v-btn>
                </template>
                Link di invito per la registrazione degli studenti della classe
              </v-tooltip>
            </v-col>
          </v-row>
          <h5 class="pl-2 content-card-subtitle">
            Quando aggiungi uno studente, la piattaforma gli spedisce una
            <span class="orange--text">email di invito</span>
            con la richiesta di completamento dell'iscrizione
          </h5>
          <ClassCard
            :id="selectedClass.id"
            :title="selectedClass.name"
            :show-copy-dialog="showCopyDialog"
            :close-copy-dialog="closeCopyDialog"
          />
        </v-card>
      </v-col>
    </v-row>

    <Modal
      v-if="dialog"
      title="Creazione Nuova Classe"
      :show-close-button="false"
      @closeModal="dialog = false"
    >
      <template #content-center>
        <v-form ref="form" v-model="form" @submit.prevent="addCard">
          <v-card-text>
            <v-container>
              <v-row justify="center">
                <v-col cols="8">
                  <v-text-field
                    v-model="className"
                    :rules="nameRules"
                    :counter="13"
                    label="Nome della Classe"
                    data-cy="class-name-input"
                    required
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions class="mt-3">
            <v-row justify="center">
              <v-btn
                large
                plain
                class="white--text mx-3"
                @click="dialog = false"
              >
                <v-icon class="mr-1"> mdi-close </v-icon>
                ANNULLA
              </v-btn>
              <v-btn
                color="#F39200"
                class="white--text mx-3 rounded-lg"
                large
                :disabled="!form"
                data-cy="confirm-create-class"
                @click="addCard"
              >
                <v-icon left> mdi-plus </v-icon>
                CREA CLASSE
              </v-btn>
            </v-row>
          </v-card-actions>
        </v-form>
      </template>
    </Modal>

    <Modal
      v-if="dialogClassroomRename"
      title="Rinomina Classe"
      :show-close-button="false"
      @closeModal="dialogClassroomRename = false"
    >
      <template #content-center>
        <v-form ref="form" v-model="form" @submit.prevent="addCard">
          <v-card-text>
            <v-container>
              <v-row justify="center">
                <v-col cols="8">
                  <v-text-field
                    v-model="newName"
                    :rules="nameRules"
                    :counter="13"
                    label="Nuovo Nome della Classe"
                    data-cy="class-name-input"
                    required
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions class="mt-3">
            <v-row justify="center">
              <v-btn
                large
                plain
                class="white--text mx-3"
                @click="dialogClassroomRename = false"
              >
                <v-icon class="mr-1"> mdi-close </v-icon>
                ANNULLA
              </v-btn>
              <v-btn
                color="#F39200"
                class="white--text mx-3 rounded-lg"
                large
                :disabled="!form"
                data-cy="confirm-create-class"
                @click="confirmRename"
              >
                <v-icon left> mdi-pencil </v-icon>
                RINOMINA CLASSE
              </v-btn>
            </v-row>
          </v-card-actions>
        </v-form>
      </template>
    </Modal>

    <Modal
      v-if="dialogClassroomDelete"
      title="Conferma Eliminazione Classe"
      :show-close-button="false"
      @closeModal="dialogClassroomDelete = false"
    >
      <template #content-center>
        <v-row>
          <v-col>
            <v-card-text class="modal-text">
              <p>
                ATTENZIONE! Sei sicuro di voler eliminare la classe
                <strong>{{ classroomToDelete.name }}</strong
                >?
              </p>
            </v-card-text>
          </v-col>
        </v-row>
        <v-card-actions class="mt-3">
          <v-row justify="center">
            <v-btn
              large
              plain
              class="white--text mx-3"
              @click="dialogClassroomDelete = false"
            >
              <v-icon class="mr-1"> mdi-close </v-icon>
              <span>ANNULLA</span>
            </v-btn>
            <v-btn
              color="red darken-2"
              class="white--text mx-3 rounded-lg"
              large
              data-cy="confirm-delete-class"
              @click="confirmDelete"
            >
              <v-icon left> mdi-delete-outline </v-icon>
              ELIMINA
            </v-btn>
          </v-row>
        </v-card-actions>
      </template>
    </Modal>
  </v-container>
</template>

<script>
import ClassCard from "@cmp-teacher/ClassCard";
import { mapGetters, mapActions } from "vuex";
import Modal from "@cmp-shared/utils/Modal";
import matomoTracker from "@mixins/matomoTracker";

const MAX_NAME_LENGTH = 13;
export default {
  name: "Classes",
  components: {
    ClassCard,
    Modal,
  },
  mixins: [matomoTracker],
  data() {
    return {
      showCopyDialog: false,
      selectedItem: 0,
      dialog: false,
      dialogClassroomDelete: false,
      dialogClassroomRename: false,
      valid: false,
      form: false,
      classroomToDelete: false,
      classroomToRename: false,
      selectedClass: {
        id: "",
        name: "",
      },
      className: "",
      newName: "",
      nameRules: [
        (v) => Boolean(v) || "Nome richiesto",
        (v) =>
          v.length <= MAX_NAME_LENGTH ||
          "Il nome deve contenere massimo 13 caratteri ",
      ],
    };
  },
  computed: {
    ...mapGetters("classrooms", {
      classrooms: "getClassrooms",
    }),
  },
  watch: {
    selectedItem(newVal, oldVal) {
      if (typeof newVal === "undefined") {
        this.selectedItem = oldVal;
      }
    },
  },
  created() {
    const classId = this.$route.params.classId;
    if (classId) {
      this.selectClass(classId, this.getClassNameFromId(classId).name);
    } else if (this.classrooms.length > 0) {
      this.selectClass(this.classrooms[0].id, this.classrooms[0].name);
    }
  },
  methods: {
    ...mapActions("classrooms", [
      "getClassroomsProf",
      "addNewClassroom",
      "deleteClassroom",
      "renameClassroom",
    ]),
    async addCard() {
      await this.addNewClassroom(this.className);
      const response = await this.getClassroomsProf();
      const lastCreated = response.find((c) => c.name === this.className);
      this.selectClass(lastCreated.id, lastCreated.name);
      this.resetForm();
      this.triggerDialog(false);
    },
    triggerDialog(value) {
      if (typeof this.$refs.form !== "undefined") {
        this.$refs.form.resetValidation();
      }
      this.dialog = value;
    },
    resetForm() {
      this.className = "";
      this.$refs.form.resetValidation();
    },
    async confirmDelete() {
      await this.deleteClassroom(this.classroomToDelete.id);
      this.selectedItem = Math.min(
        Math.max(0, this.selectedItem),
        this.classrooms.length - 1
      );
      this.dialogClassroomDelete = false;
      this.classroomToDelete = "";
    },
    async confirmRename() {
      await this.renameClassroom({
        idToRename: this.classroomToRename.id,
        newName: this.newName,
      });
      this.dialogClassroomRename = false;
      this.classroomToRename = "";
      this.newName = "";
    },
    openDeleteModal(classroom) {
      this.dialogClassroomDelete = true;
      this.classroomToDelete = classroom;
    },
    openRenameModal(classroom) {
      this.dialogClassroomRename = true;
      this.classroomToRename = classroom;
    },
    selectClass(id, name) {
      this.selectedClass.name = name;
      this.selectedClass.id = id;

      const currentRoute = { ...this.$route };
      currentRoute.params.classId = id;
      this.$router.replace(currentRoute);
    },
    closeCopyDialog() {
      this.showCopyDialog = false;
    },
    getClassNameFromId(id) {
      if (this.classrooms.length) {
        return this.classrooms.find((c) => c.id === id);
      }
    },
  },
};
</script>

<style scoped>
.v-icon.v-icon {
  font-size: 20px;
}

.card-title {
  font-family: "Karasuma Gothic", sans-serif;
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 1px;
  padding-left: 8px;
  text-transform: uppercase;
}

.class-title {
  font-weight: 700;
  font-size: 48.83px;
  line-height: 50px;
  letter-spacing: 1px;
  color: #ffffff;
}

.class-subtitle {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #98a6ae;
}

.class-list-title {
  font-family: "outerorbit";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  text-transform: uppercase;
  color: #f4f5f6;
}

.classrooms-info-card {
  background: linear-gradient(
      109.46deg,
      rgba(1, 0, 46, 0.3) 1.57%,
      rgba(1, 0, 46, 0.3) 100%
    ),
    linear-gradient(
      109.46deg,
      rgba(201, 201, 201, 0.3) 1.57%,
      rgba(196, 196, 196, 0.05) 100%
    ) !important;
  backdrop-filter: blur(30px);
  border-radius: 12px;
}

.classroom-name-card {
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
  text-align: center;
  letter-spacing: 1px;
  color: #ffffff;

  overflow: hidden;
  max-width: 13ch;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.classroom-name-card-clicked {
  color: #f39200;
}

.classroom-students-card {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.75px;
  color: #c6ced2;
}

.add-classroom-card {
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.75px;
  color: #7fbaf4;
}

.content-card {
  text-align: start;
  font-family: "Karasuma Gothic", sans-serif;
  font-weight: 100;
  font-size: 14px;
  background: linear-gradient(
      109.46deg,
      rgba(1, 0, 46, 0.3) 1.57%,
      rgba(1, 0, 46, 0.3) 100%
    ),
    linear-gradient(
      109.46deg,
      rgba(201, 201, 201, 0.3) 1.57%,
      rgba(196, 196, 196, 0.05) 100%
    ) !important;
  backdrop-filter: blur(30px);
  border-radius: 12px;
}

.content-card-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
}

.content-card-subtitle {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}

.classroom-clicked {
  border: 2px solid #f39200;
}

.delete-class-icon {
  align-content: center;
}

.copy-button {
  text-transform: none;
  font-size: 16px;
  font-weight: 400;
}

#container-create {
  height: 100%;
}

.wrap {
  padding: 8px;
  margin-top: 10px;
  width: 100%;
  display: block;
  position: relative;
  z-index: 0;
  text-align: center;
}
</style>
