import learningResourcesService from "@services/learningResources";
import { handleAxiosError, sendNotification } from "@utils/notifications";

const actions = {
  async getOndemandQuizzesById({ commit }, { id }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      const { data } = await learningResourcesService.getOndemandQuizzesById(
        id
      );
      if (!Object.hasOwn(data, "questions")) {
        data.questions = [];
      }
      return data;
    } catch (err) {
      await handleAxiosError("Errore nel recupero dei quiz ondemand", err);
      return null;
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async getOndemandQuizzes({ commit }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      const { data } = await learningResourcesService.getOndemandQuizzes();
      return data;
    } catch (err) {
      await handleAxiosError("Errore nel recupero dei quiz ondemand", err);
      return null;
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async getOndemandVideo({ commit }, { id }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      const { data } = await learningResourcesService.getOndemandVideo(id);
      return data;
    } catch (err) {
      await handleAxiosError("Errore nel recupero del video ondemand", err);
      return null;
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async getOndemandExerciseDetails({ commit }, { id }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      const { data } =
        await learningResourcesService.getOndemandExerciseDetails(id);
      return data;
    } catch (err) {
      await handleAxiosError(
        "Errore nel recupero dell'esercizio ondemand",
        err
      );
      return null;
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async getOndemandExerciseHint({ commit }, { id }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      const { data } = await learningResourcesService.getOndemandExerciseHint(
        id
      );
      return data;
    } catch (err) {
      await handleAxiosError(
        "Errore nel recupero del suggerimento dell'esercizio",
        err
      );
      return null;
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async completeOndemandExercise({ commit }, { id }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      await learningResourcesService.completeOndemandExercise(id);
      sendNotification("Esercizio completato con successo!", "success");
    } catch (err) {
      await handleAxiosError("Errore nel completamento dell'esercizio", err);
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async getStudentVideoInfo({ commit, dispatch }, { id, isStudent }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      const { data } = await learningResourcesService.getStudentVideoInfo(id);
      if (!data.completed && isStudent) {
        await dispatch("ondemandTracker/getTrackerToken", id, {
          root: true,
        });
      }
      return data;
    } catch (err) {
      await handleAxiosError("Errore nel recupero delle informazioni", err);
      return null;
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async startOndemandQuiz({ commit }, { id }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      const { data } = await learningResourcesService.startOndemandQuiz(id);
      return data;
    } catch (err) {
      await handleAxiosError("Errore nel recupero del quiz ondemand", err);
      return null;
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async selectOndemandQuizAnswers(_context, { ondemandQuizAnswers }) {
    const { answers, quizId } = ondemandQuizAnswers;
    const dataAnswers = [];
    answers.map((a) =>
      dataAnswers.push({ answer_id: a.answerId, question_id: a.questionId })
    );
    try {
      const { data } = await learningResourcesService.selectOndemandQuizAnswers(
        dataAnswers,
        quizId
      );
      return data;
    } catch (err) {
      await handleAxiosError("Errore nel salvataggio delle risposte", err);
      return null;
    }
  },
  async finishOndemandQuiz({ commit }, { id }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      const { data } = await learningResourcesService.finishOndemandQuiz(id);
      return data;
    } catch (err) {
      await handleAxiosError("Errore nella chiusura del quiz", err);
      return null;
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async getOndemandComments({ commit }, { id }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      const { data } = await learningResourcesService.getOndemandComments(id);
      return data;
    } catch (err) {
      await handleAxiosError("Errore nel recupero dei commenti", err);
      return null;
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async postNewQuestion({ commit }, { newQuestion }) {
    const { learningPathId, text } = newQuestion;
    try {
      commit("loading/startLoading", {}, { root: true });
      await learningResourcesService.postNewQuestion(learningPathId, text);
    } catch (err) {
      await handleAxiosError("Errore nella pubblicazione della domanda", err);
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async postNewComment({ commit }, { newComment }) {
    const { learningPathId, text, mainQuestion } = newComment;
    try {
      commit("loading/startLoading", {}, { root: true });
      await learningResourcesService.postNewComment(
        learningPathId,
        text,
        mainQuestion
      );
    } catch (err) {
      await handleAxiosError("Errore nella pubblicazione del commento", err);
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async approveOndemandQuestion({ commit }, { id }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      await learningResourcesService.approveOndemandQuestion(id);
    } catch (err) {
      await handleAxiosError(
        "Errore nell'approvazione della domanda anonima",
        err
      );
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async deleteOndemandComment({ commit }, { id }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      await learningResourcesService.deleteOndemandComment(id);
    } catch (err) {
      await handleAxiosError("Errore nella cancellazione della domanda", err);
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
};

export default {
  namespaced: true,
  actions,
};
