<template>
  <v-app class="app-container">
    <v-row class="gradient pt-7" justify="center">
      <HeaderS4S />
    </v-row>
    <v-main>
      <v-container>
        <v-row justify="space-around">
          <v-col cols="12" sm="10" md="10" lg="7" xl="5">
            <div class="glassify pa-6 rounded-xl mb-8">
              <v-row>
                <v-col>
                  <h2
                    class="white--text font-weight-light text-center text-sm-left pb-6"
                  >
                    HAI RICEVUTO UN INVITO!
                  </h2>
                  <p class="white--text text-left my-0 pb-3 text-body-1">
                    Sei stato invitato da <strong>{{ teacher }}</strong> ad
                    entrare nella classe:
                  </p>
                  <p class="white--text text-left my-0 text-body-1">
                    Classe: <strong>{{ classroom }}</strong>
                  </p>
                  <p class="white--text text-left text-body-1">
                    Scuola: <strong>{{ school }}</strong>
                  </p>
                </v-col>
              </v-row>
              <v-row class="justify-center">
                <v-btn
                  color="success"
                  dark
                  x-large
                  class="btn-font ma-4"
                  @click="() => invitationResponse(true)"
                >
                  ACCETTA INVITO
                </v-btn>
                <v-btn
                  v-if="decodedToken.school && isStudent"
                  color="error"
                  dark
                  x-large
                  class="btn-font ma-4"
                  @click="() => invitationResponse(false)"
                >
                  RIFIUTA INVITO
                </v-btn>
              </v-row>
            </div>
          </v-col>
        </v-row>
        <v-row justify="space-around">
          <v-col cols="12" sm="10" md="10" lg="7" xl="5">
            <Supporters />
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapActions, mapState } from "vuex";
import HeaderS4S from "@cmp/platform/HeaderS4S";
import Supporters from "@cmp/platform/Supporters";
import store from "@store";
import userHandler from "@mixins/userHandler";

export default {
  name: "PendingClassroom",
  components: { HeaderS4S, Supporters },
  mixins: [userHandler],
  data() {
    return {};
  },

  computed: {
    ...mapState("classrooms", ["pendingClassroom"]),

    teacher() {
      return `${this.pendingClassroom.professor.name} ${this.pendingClassroom.professor.surname}`;
    },
    classroom() {
      return this.pendingClassroom.name;
    },
    school() {
      return `${this.pendingClassroom.school.name} - ${this.pendingClassroom.school.city}`;
    },
  },
  methods: {
    ...mapActions("classrooms", ["confirmInvite", "rejectInvite"]),
    async invitationResponse(response) {
      if (response) {
        await this.confirmInvite();
      } else {
        await this.rejectInvite();
      }
      this.$router.push({ name: "student-courses" });
    },
  },
  beforeRouteEnter: async (to, from, next) => {
    try {
      await store.dispatch("classrooms/getPendingClassroom");
      next();
    } catch (err) {
      next(err);
    }
  },
};
</script>

<style scoped>
.gradient {
  background: rgb(2, 0, 36);
  background: linear-gradient(
    180deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(65, 9, 121, 1) 0%,
    rgba(65, 9, 121, 0) 100%
  );
}
.col-md-auto {
  flex: 0 0 auto;
  width: 50%;
  max-width: 100%;
}
</style>
