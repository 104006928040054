import api from "@services/axiosInstance";

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/swagger/index.html#/Exam/post_exam
 */
function startExam(quizId) {
  return api.post(`/exam?quiz_id=${quizId}`);
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/swagger/index.html#/Exam/post_exam_answers
 */
function saveAnswers(answers) {
  return api.post(`/exam/answers`, answers);
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/Exam/post_exam_terminate
 */
function terminateExam(examId) {
  return api.post(`/exam/terminate?exam_id=${examId}`);
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/Exam/get_exam_results
 */
function resultsExams() {
  return api.get("/exam/results");
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/Exam/get_exam_results_aggregated
 */
function resultsAggregated(classId) {
  return api.get(`/exam/results/aggregated?class_id=${classId}`);
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/Exam/get_exam_results_student_list
 */
function resultsOfStudents(classId, quizId) {
  return api.get(
    `/exam/results/student-list?class_id=${classId}&quiz_id=${quizId}`
  );
}
function examSummary(studentId, quizId) {
  return api.get(`/exam/summary?student_id=${studentId}&quiz_id=${quizId}`, {
    responseType: "blob",
  });
}
function allExamsSummary(classId, quizId) {
  return api.get(`/exam/summary/class?class_id=${classId}&quiz_id=${quizId}`, {
    responseType: "blob",
  });
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/Exam/get_exam_certificate
 */
function getCertificate(studentId, quizId) {
  return api.get(
    `/exam/certificate?student_id=${studentId}&quiz_id=${quizId}`,
    {
      responseType: "blob",
    }
  );
}
function getAllCertificates(classId, quizId) {
  return api.get(
    `/exam/certificate/class?class_id=${classId}&quiz_id=${quizId}`,
    {
      responseType: "blob",
    }
  );
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/Exam/post_exam_badge
 */
function generateBadge(data) {
  return api.post("/exam/badge", {
    ...data,
  });
}

export default {
  startExam,
  saveAnswers,
  terminateExam,
  resultsExams,
  resultsAggregated,
  resultsOfStudents,
  examSummary,
  allExamsSummary,
  getCertificate,
  getAllCertificates,
  generateBadge,
};
