<template>
  <DefaultTable
    :key="classId"
    :headers="headers"
    :store-name="'learningPaths'"
    :method="'getLearningPathsByClassroomId'"
    :item-type="{ classId }"
    :custom-slots="[
      'downloadOverviewReport',
      'courseSelector'
    ]"
  >
    <template #downloadOverviewReport="{ item }">
      <Download
        :store-name="'learningPaths'"
        :store-action="'downloadOndemandOverviewReportExcel'"
        :object-to-send="{
          classId: classId,
          learningPathId: item.id,
          learningPathName: item.title,
        }"
      />
    </template>
    <template #courseSelector="{ item }">
      <v-btn
        color="#F39200"
        width="40px"
        height="40px"
        min-width="0"
        class="rounded-lg open-button"
        @click="selectCourse(item)"
      >
        <v-icon>mdi-login</v-icon>
      </v-btn>
    </template>
  </DefaultTable>
</template>

<script>
import Download from "@cmp/shared/actions/Download.vue";
import DefaultTable from "@cmp/shared/DefaultTable.vue";
export default {
  name: "ReportOnDemandTable",
  components: { DefaultTable, Download },
  props: {
    classId: {
      type: String,
      required: true,
    },
    selectCourse: {
      type: Function,
      required: true,
    }
  },
  data() {
    return {
      headers: [
        {
          text: "Corso",
          value: "title",
        },
        {
          text: "Avanzamento corso",
          value: "downloadOverviewReport",
        },
        {
          text: "Report risorse",
          value: "courseSelector"
        }
      ],
    };
  },
};
</script>

<style scoped>
.open-button {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #f4f5f6;
  text-transform: none;
}
</style>
