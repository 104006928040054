<template>
  <v-container>
    <CredlyBadgeDialog
      v-if="credlyForm"
      :secondary-email="userSecondaryEmail"
      :primary-email="userPrimaryEmail"
      :generated-badge="generatedBadge"
      @closeForm="closeCredlyDialog"
      @confirm="createBadge"
    />
    <h1
      class="page-title mb-10"
      :class="$vuetify.breakpoint.mdAndDown ? 'text-center' : 'text-left'"
    >
      Report
    </h1>
    <h5 class="page-subtitle mb-10">
      Visualizza e scarica i certificati e i report relativi ai corsi Ondemand,
      Live e Test.
    </h5>

    <v-tabs
      v-model="tab"
      background-color="transparent"
      dark
      color="#F39200"
      class="mb-5 flex-wrap"
      active-class="tab-selected"
      hide-slider
    >
      <v-tab v-for="page in tabs" :key="page" class="tabs-title">
        {{ page }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab" class="tabs-item-container glassify">
      <!-- ONDEMAND SECTION -->
      <v-tab-item :key="'Ondemand'">
        <v-col cols="12">
          <h5
            class="pt-2 mb-4 card-title flex-grow-1"
            :class="
              $vuetify.breakpoint.smAndDown ? 'text-center' : 'text-start pl-4'
            "
          >
            Ondemand
          </h5>
          <h6 class="page-subtitle pl-4 mb-5">
            Visualizza i dati relativi ai tuoi progressi sui corsi Ondemand.
          </h6>
          <ReportOnDemandStudentTable />
        </v-col>
      </v-tab-item>

      <!-- LIVE SECTION -->
      <v-tab-item :key="'Live'">
        <v-col cols="12">
          <h5
            class="pt-2 mb-4 card-title flex-grow-1"
            :class="
              $vuetify.breakpoint.smAndDown ? 'text-center' : 'text-start pl-4'
            "
          >
            Coming soon
          </h5>
        </v-col>
      </v-tab-item>

      <!-- TEST SECTION -->
      <v-tab-item :key="'Test'">
        <v-col cols="12">
          <h5
            class="pt-2 mb-4 card-title flex-grow-1"
            :class="
              $vuetify.breakpoint.smAndDown ? 'text-center' : 'text-start pl-4'
            "
          >
            Test
          </h5>
          <h6 class="page-subtitle pl-4 mb-5">
            Visualizza e scarica i risultati dei tuoi test e certificati.
          </h6>
          <DefaultTable
            :headers="tableHeader"
            :store-name="'exam'"
            :method="'resultsExams'"
            :custom-slots="[
              'result',
              'download_enabled',
              'credly_badge',
              'details',
            ]"
          >
            <template #result="{ item }">
              <span v-if="!item.taken" class="quiz-status-label"
                >Non effettuato</span
              >
              <span
                v-else-if="item.passed"
                class="quiz-status-label success--text"
                >Passato</span
              >
              <span v-else class="quiz-status-label error--text"
                >Non passato</span
              >
            </template>

            <template #download_enabled="{ item }">
              <template v-if="item.passed">
                <v-btn
                  width="40px"
                  height="40px"
                  min-width="0px"
                  color="#9B51E0"
                  class="rounded-lg"
                  @click="() => downloadCertificate(item.quiz_id, item.title)"
                >
                  <v-img src="@assets/landingPage/download.svg" />
                </v-btn>
              </template>
              <template v-else>
                <span class="grey--text card-font">NON DISPONIBILE</span>
              </template>
            </template>
            <template #credly_badge="{ item }">
              <template v-if="item.taken && item.passed">
                <v-btn
                  width="40px"
                  height="40px"
                  min-width="0"
                  color="#F39200"
                  class="rounded-lg"
                  @click="() => openCredlyDialog(item)"
                >
                  <v-icon size="24px"> mdi-medal-outline </v-icon>
                </v-btn>
              </template>
              <template v-else>
                <span class="grey--text card-font">NON DISPONIBILE</span>
              </template>
            </template>
            <template #details="{ item }">
              <template>
                <v-btn
                  v-if="item.download_enabled && item.taken"
                  width="40px"
                  height="40px"
                  min-width="0"
                  color="#00A1FF"
                  class="rounded-lg"
                  @click="() => viewExamDetail(item.quiz_id, item.title)"
                >
                  <v-icon size="24px"> mdi-eye </v-icon>
                </v-btn>
                <v-tooltip v-else bottom>
                  <template #activator="{ on }">
                    <span class="grey--text card-font" v-on="on"
                      >NON DISPONIBILE</span
                    >
                  </template>
                  Disponibile dal momento in cui non ci saranno più sessioni
                  attive
                </v-tooltip>
              </template>
            </template>
          </DefaultTable>
        </v-col>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CredlyBadgeDialog from "@cmp/platform/student/CredlyBadgeDialog.vue";
import DefaultTable from "@cmp/shared/DefaultTable.vue";
import ReportOnDemandStudentTable from "@cmp/platform/student/ReportOnDemandStudentTable.vue";

export default {
  name: "Report",
  components: {
    CredlyBadgeDialog,
    DefaultTable,
    ReportOnDemandStudentTable,
  },
  data() {
    return {
      results: [],
      tableHeader: [
        {
          text: "Nome corso",
          align: "start",
          sortable: true,
          value: "title",
        },
        {
          text: "Esito",
          align: "start",
          sortable: true,
          value: "result",
        },
        {
          text: "Download Certificato",
          align: "start",
          sortable: false,
          value: "download_enabled",
        },
        {
          text: "Badge Credly",
          align: "start",
          sortable: false,
          value: "credly_badge",
        },
        {
          text: "Dettaglio test",
          align: "start",
          sortable: false,
          value: "details",
        },
      ],
      credlyForm: false,
      selectedQuizId: null,
      generatedBadge: false,
      userSecondaryEmail: "",
      userPrimaryEmail: "",
      tabs: ["Ondemand", "Live", "Test"],
      tab: "",
    };
  },
  computed: {
    ...mapGetters("auth", ["decodedToken", "user"]),
  },
  async created() {
    if (this.user && this.user.access_level === 3) {
      try {
        await this.getMyProfile();
      } catch (e) {
        this.$logger(e);
      }
    }
    if (this.user) {
      this.userSecondaryEmail = this.user.sec_email;
      this.userPrimaryEmail = this.user.email;
    }
  },
  methods: {
    ...mapActions({
      getCertificate: "exam/getCertificate",
      generateBadge: "exam/generateBadge",
      updateStudentProfile: "auth/updateStudentProfile",
      getMyProfile: "auth/getMyProfile",
      examSummary: "exam/examSummary",
      isEmailFree: "auth/isEmailFree",
    }),
    async downloadCertificate(quizId, quizTitle) {
      try {
        await this.getCertificate({
          studentId: this.decodedToken.id,
          quizId,
          filename: `certificato_${quizTitle}_${this.decodedToken.name}_${this.decodedToken.surname}`,
        });
      } catch (err) {}
    },
    async createBadge(saveEmail, email, canContact) {
      try {
        let emailFree = true;
        if (saveEmail) {
          emailFree = await this.isEmailFree(email);
          if (emailFree) {
            await this.updateStudentProfile({
              payload: {
                secondaryMail: email,
                canContact,
              },
            });
          }
        }
        if (emailFree) {
          await this.generateBadge({
            email,
            quizId: this.selectedQuizId,
          });
        }
      } catch (e) {
        this.$logger(e);
      } finally {
        this.credlyForm = false;
        this.selectedQuizId = null;
      }
    },
    openCredlyDialog(item) {
      this.credlyForm = true;
      this.selectedQuizId = item.quiz_id;
      this.generatedBadge = item.generated_badge;
    },
    closeCredlyDialog() {
      this.credlyForm = false;
      this.selectedQuizId = null;
      this.generatedBadge = false;
    },
    async viewExamDetail(quizId, quizTitle) {
      await this.examSummary({
        studentId: this.decodedToken.id,
        quizId,
        filename: `${quizTitle}_${this.decodedToken.name}_${this.decodedToken.surname}`,
      });
    },
    quizStatus(item) {
      if (!item.taken) {
        return "Non effettuato";
      } else if (item.passed) {
        return "Passato";
      }
      return "non passato";
    },
  },
};
</script>

<style scoped>
.content-card {
  text-align: start;
  font-family: "Karasuma Gothic", sans-serif;
  font-weight: 100;
  font-size: 14px;
  border: 3px solid
    linear-gradient(
      109.46deg,
      rgba(201, 201, 201, 0.4) 1.57%,
      rgba(196, 196, 196, 0.1) 100%
    );
  border-radius: 12px;
}

.quiz-status-label {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #98a6ae;
  text-transform: uppercase;
}

.page-subtitle {
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #98a6ae;
}

.page-title {
  font-weight: 700;
  font-size: 48.83px;
  line-height: 50px;
  letter-spacing: 1px;
  color: #ffffff;
}

.card-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 27.5px;
  letter-spacing: 1px;
  color: #ffffff;
}

.tabs-title {
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  padding: 0;
  min-width: 0;
  margin-right: 60px;
}

.tabs-item-container {
  background: transparent;
}

.navigation-text {
  color: #f4f5f6;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  align-items: center;
  cursor: pointer;
}

.card-font {
  font-size: 16px;
  font-weight: 400;
  line-height: 27.5px;
}

.v-tab {
  text-transform: none !important;
}
</style>
