<template>
  <v-card
    :width="$vuetify.breakpoint.mdAndDown ? '100vw' : '40vw'"
    :height="$vuetify.breakpoint.mdAndDown ? '100vh' : '60vh'"
  >
    <v-toolbar color="secondary">
      <v-toolbar-title
        class="clickable bit-text yellow--text"
        @click="$emit('maximizeChat', { name })"
      >
        {{ truncatedName }}
      </v-toolbar-title>
      <v-spacer />
      <v-btn
        v-if="showMinimize && !$vuetify.breakpoint.xsOnly"
        small
        icon
        color="yellow"
        dark
        class="mr-3"
        @click="$emit('minimizeChatCard')"
      >
        <v-icon large>
          mdi-chevron-down
        </v-icon>
      </v-btn>
      <v-btn
        class="mr-1"
        color="yellow"
        icon
        dark
        small
        @click="$emit('closeChatCard')"
      >
        <v-icon large>
          mdi-close
        </v-icon>
      </v-btn>
    </v-toolbar>

    <Chat
      :projects="projects"
      :room-id="channelId"
      :public-channel="publicChannel"
    />
  </v-card>
</template>

<script>
import Chat from "@cmp-chat/ChatCustom";

export default {
  name: "ChatCard",
  components: {
    Chat,
  },

  props: {
    channelId: {
      type: String,
      required: true,
    },
    publicChannel: {
      type: Boolean,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    showMinimize: {
      type: Boolean,
      required: true,
    },
    projects: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      alwaysTrue: true,
    };
  },

  computed: {
    channelPath() {
      return (this.publicChannel ? "channel/" : "group/") + this.channelName;
    },
    truncatedName() {
      if (this.name.length > 18) {
        return `${this.name.substring(0, 15)}...`;
      }
      return this.name;
    },
  },
};
</script>

<style scoped>
.close-button {
  font-size: 1em !important;
  top: 0;
  right: 0;
}
</style>
