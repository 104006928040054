<template>
  <DefaultTable
    :headers="headers"
    :store-name="'learningPaths'"
    :method="'getOndemandCourses'"
    :custom-slots="['completion', 'pcto']"
  >
    <template #completion="{ item }">
      <span v-if="item.progress_info.percentage_completion" class="card-font">
        {{ item.progress_info.percentage_completion }} %
      </span>
      <span v-else class="grey--text card-font"> N/A </span>
    </template>

    <template #pcto="{ item }">
      <span v-if="item.progress_info.percentage_completion" class="card-font">
        {{ item.progress_info.internship_hours }} h
      </span>
      <span v-else class="grey--text card-font">N/A</span>
    </template>
  </DefaultTable>
</template>

<script>
import DefaultTable from "@cmp/shared/DefaultTable.vue";
export default {
  name: "ReportOnDemandStudentTable",
  components: { DefaultTable },
  data() {
    return {
      headers: [
        {
          text: "Corso",
          value: "title",
        },
        {
          text: "Completamento",
          value: "completion",
        },
        {
          text: "Ore certificate",
          value: "pcto",
        },
      ],
    };
  },
};
</script>

<style scoped>
.data-table {
  background-color: transparent;
  font-weight: 500;
}

.open-button {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #f4f5f6;
  text-transform: none;
}

.card-font {
  font-size: 16px;
  font-weight: 400;
  line-height: 27.5px;
}
</style>
