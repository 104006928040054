<template>
  <Modal
    :glassify="true"
    title="AGGIUNGI GRUPPO DI STUDENTI"
    @closeModal="$emit('onCloseModal')"
  >
    <template #content>
      <v-container>
        <v-row justify="center" class="ma-1">
          <p class="text-modal">
            Carica un file XLSX con la lista degli studenti che vuoi aggiungere.
            Scarica il file di esempio per sapere come impostarlo.
          </p>
        </v-row>
        <v-row justify="center" class="mt-2" no-gutters>
          <v-col cols="12" md="5" class="mt-2">
            <v-btn
              large
              dark
              class="rounded-lg dotted-border"
              color="transparent"
              width="100%"
              @click="selectFile"
            >
              <v-icon left dark> mdi-tray-arrow-up </v-icon>
              Carica file XLSX
            </v-btn>
            <input
              type="file"
              ref="fileInput"
              style="display: none"
              @change="handleFileUpload"
            />
          </v-col>

          <v-col cols="12" md="5" offset-md="1" class="mt-2">
            <v-btn
              large
              dark
              color="orange darken-3"
              width="100%"
              class="rounded-lg"
              @click="$emit('onDownloadExample')"
            >
              <v-icon left dark> mdi-tray-arrow-down </v-icon>
              Scarica file XLSX di esempio
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </Modal>
</template>

<script>
import Modal from "@cmp-shared/utils/Modal";
export default {
  name: "AddStudentsModal",
  methods: {
    selectFile() {
      this.$refs.fileInput.click();
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      event.target.value = null;
      this.$emit("onUploadFile", file);
    },
  },
  components: {
    Modal,
  },
  emits: ["onCloseModal", "onDownloadExample", "onUploadFile"],
};
</script>

<style scoped>
.text-modal {
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}

.dotted-border {
  border: 1px dashed #ffffff !important;
}
</style>
