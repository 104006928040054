<template>
  <div>
    <iframe
      id="video"
      :key="resourceId"
      ref="videoPlayer"
      :class="$vuetify.breakpoint.mdAndUp ? 'video-size' : 'video-size-mobile'"
      :src="videoUri"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
      webkitallowfullscreen
      mozallowfullscreen
    />
    <h1 class="course-title mt-2">
      <p
        class="orange--text"
        :class="
          $vuetify.breakpoint.lgAndUp ? 'text-subtitle-2' : 'text-caption'
        "
      >
        Il video deve essere guardato per intero a velocità standard per
        completare la lezione.
      </p>
      {{ title }}
    </h1>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import userHandler from "@mixins/userHandler";
import trigger from "@mixins/trigger";
import VimeoPlayer from "@vimeo/player";
export default {
  name: "OndemandVideoPlayer",
  mixins: [userHandler, trigger],
  data: () => ({
    videoUri: "",
    title: "",
    player: null,
    dataVideoInfo: {},
    trackerInterval: null,
    statusInterval: null,
    isLoading: false,
  }),
  computed: {
    resourceId() {
      return this.$route.params.resourceId;
    },
    routePath() {
      return this.$route.path;
    },
  },
  watch: {
    async routePath(value) {
      if (value.includes("video_lesson")) {
        await this.updateVideo();
      } else {
        this.deactivateTrackers();
      }
    },
  },
  destroyed() {
    this.deactivateTrackers();
  },
  async mounted() {
    await this.updateVideo();
  },
  methods: {
    ...mapActions("learningResources", ["getStudentVideoInfo"]),
    ...mapActions("ondemandTracker", ["pingVideo"]),
    ...mapActions("learningPaths", ["getOndemandById"]),
    deactivateTrackers() {
      if (this.trackerInterval) {
        clearInterval(this.trackerInterval);
        this.trackerInterval = null;
      }
      if (this.statusInterval) {
        clearInterval(this.statusInterval);
        this.statusInterval = null;
      }
    },
    async updateVideo() {
      this.isLoading = true;
      await this.getVideoInfo(this.isStudent);
      if (!this.isStudent) {
        return;
      }
      this.deactivateTrackers();
      const videoPlayerNode = this.$refs.videoPlayer;
      this.player = new VimeoPlayer(videoPlayerNode);
      this.player.play();
      this.player.on("play", this.onPlay);
      this.player.on("pause", this.onPause);
      this.getStatus();
    },
    async getVideoInfo(isStudent) {
      this.dataVideoInfo = await this.getStudentVideoInfo({
        id: this.resourceId,
        isStudent,
      });
      this.videoUri = this.dataVideoInfo.video_uri;
      this.title = this.dataVideoInfo.title;
    },
    async sendPing() {
      if (this.dataVideoInfo.completed) {
        return;
      }
      await this.pingVideo();
    },
    getStatus() {
      this.$logger("Updating status...");
      this.triggerUpdate();
    },
    startStatusInterval() {
      if (this.statusInterval) {
        clearInterval(this.statusInterval);
        this.statusInterval = null;
      }
      this.statusInterval = setInterval(this.getStatus, 15000);
    },
    onPlay() {
      if (this.trackerInterval) {
        clearInterval(this.trackerInterval);
        this.trackerInterval = null;
      }
      if (this.dataVideoInfo.completed) {
        this.getStatus();
        return;
      }
      this.trackerInterval = setInterval(this.sendPing, 10000);

      this.startStatusInterval();
    },
    async onPause() {
      if (this.dataVideoInfo.completed) {
        return;
      }
      if (this.trackerInterval) {
        clearInterval(this.trackerInterval);
        this.trackerInterval = null;
        await this.sendPing();
      }
      if (this.statusInterval) {
        clearInterval(this.statusInterval);
        this.statusInterval = null;
        this.getStatus();
      }
    },
  },
};
</script>

<style scoped>
#video {
  background-color: black;
  width: 100%;
  border: none;
}

.video-size {
  min-height: 500px;
}

.video-size-mobile {
  min-height: 250px;
}

.course-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #ffffff;
}

.question-title {
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #ffffff;
}
</style>
