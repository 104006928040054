<template>
  <v-navigation-drawer
    v-model="$store.state.toolbar.drawer"
    absolute
    temporary
    width="75%"
    class="glassify bit-text text-uppercase"
  >
    <v-list-item class="mt-2" @click="openMyProfile">
      <v-list-item-avatar>
        <v-img :src="avatarUrl">
          <template #placeholder>
            <v-container class="fill-height ma-0 grey-bg" />
          </template>
        </v-img>
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title id="drawer-title" class="white--text">
          {{ user ? user.name + " " + user.surname : "..." }}
        </v-list-item-title>
        <v-list-item-subtitle
          v-if="user && user.access_level !== 1"
          class="text--lighten-1 grey--text"
        >
          APRI PROFILO
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-divider class="my-2" />

    <v-list dense>
      <v-list-item-group color="primary">
        <v-list-item
          v-if="$vuetify.breakpoint.mdAndDown"
          @click="
            $router.push({
              name: `${currentUserRedirect}-platform-home-page`,
            })
          "
        >
          <v-list-item-icon>
            <v-icon color="white"> mdi-home </v-icon>
          </v-list-item-icon>
          <v-list-item-content class="white--text"> Home </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="$vuetify.breakpoint.mdAndDown && (isGuest || isSorint)"
          @click="
            $router.push({
              name: `${currentUserRedirect}-ondemand-courses`,
            })
          "
        >
          <v-list-item-icon>
            <v-icon color="white"> mdi-school </v-icon>
          </v-list-item-icon>
          <v-list-item-content class="white--text">
            Corsi on demand
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="$vuetify.breakpoint.mdAndDown && (isGuest || isSorint)"
          @click="
            $router.push({
              name: `${currentUserRedirect}-courses`,
            })
          "
        >
          <v-list-item-icon>
            <v-icon color="white"> mdi-play </v-icon>
          </v-list-item-icon>
          <v-list-item-content class="white--text">
            Corsi live
          </v-list-item-content>
        </v-list-item>

        <!-- <v-list-item
          v-if="$vuetify.breakpoint.mdAndDown && !isGuest"
          @click="
            $router.push({
              name: `${currentUserRedirect}-projects`,
            })
          "
        >
          <v-list-item-icon>
            <v-icon color="white"> mdi-seal </v-icon>
          </v-list-item-icon>
          <v-list-item-content class="white--text">
            Progetti
          </v-list-item-content>
        </v-list-item> -->

        <v-list-item
          v-if="$vuetify.breakpoint.mdAndDown && isTeacher"
          @click="goToClassManagement()"
        >
          <v-list-item-icon>
            <v-icon color="white"> mdi-account-group </v-icon>
          </v-list-item-icon>
          <v-list-item-content class="white--text">
            Classi
          </v-list-item-content>
        </v-list-item>

        <!-- <v-list-item
          v-if="$vuetify.breakpoint.mdAndDown"
          @click="
            $router.push({
              name: `${currentUserRedirect}-test`,
            })
          "
        >
          <v-list-item-icon>
            <v-icon color="white"> mdi-file-edit </v-icon>
          </v-list-item-icon>
          <v-list-item-content class="white--text"> Test </v-list-item-content>
        </v-list-item> -->

        <v-list-item
          v-if="$vuetify.breakpoint.mdAndDown && isTeacher"
          @click="
            $router.push({
              name: `${currentUserRedirect}-report`,
            })
          "
        >
          <v-list-item-icon>
            <v-icon color="white"> mdi-download </v-icon>
          </v-list-item-icon>
          <v-list-item-content class="white--text">
            Report
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="$vuetify.breakpoint.mdAndDown && (isStudent || isTeacher)"
          @click="changeRoute()"
        >
          <v-list-item-icon>
            <v-icon color="white"> mdi-source-branch </v-icon>
          </v-list-item-icon>
          <v-list-item-content class="white--text">
            Journey
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="$vuetify.breakpoint.mdAndDown"
          @click="
            $router.push({
              name: `${currentUserRedirect}-support`,
            })
          "
        >
          <v-list-item-icon>
            <v-icon color="white"> mdi-help </v-icon>
          </v-list-item-icon>
          <v-list-item-content class="white--text">
            Supporto
          </v-list-item-content>
        </v-list-item>

        <v-divider
          v-if="$vuetify.breakpoint.mdAndDown"
          light
          class="my-5 white--text"
        />

        <v-list-item @click="quit">
          <v-list-item-icon>
            <v-icon color="white"> mdi-logout </v-icon>
          </v-list-item-icon>
          <v-list-item-content class="white--text">
            Logout
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import userHandler from "@mixins/userHandler";
import { pushJourneyUrl, pushAuthUrl } from "@/utils/externalRouter";

export default {
  name: "NavigationDrawer",
  mixins: [userHandler],
  props: {
    avatarUrl: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters("toolbar", ["tabs"]),
    ...mapGetters("auth", ["user"]),
    ...mapState("toolbar", ["drawer"]),
    isProfile() {
      return this.$router.currentRoute.name === "my-profile";
    },
    currentUserRedirect() {
      const user = { ...this.user };
      switch (user?.access_level) {
        case 2:
          return "teacher";
        case 3:
          return "student";
        case 5:
          return "sorint";
        default:
          return "guest";
      }
    },
  },
  methods: {
    ...mapActions("auth", ["logout"]),
    ...mapMutations("toolbar", ["setDrawer"]),
    openMyProfile() {
      if (this.user && this.user.access_level !== 1) {
        this.setDrawer(false);
        this.$router.push({ name: "my-profile" });
      }
    },

    goToClassManagement() {
      pushJourneyUrl("/classroom-management");
    },

    changeRoute() {
      const token = JSON.parse(localStorage.getItem("user"));
      pushJourneyUrl("/auth/sso", token.access_token);
    },

    async quit() {
      try {
        await this.logout();
        pushAuthUrl();
      } catch (err) {
        console.info("Router error");
      }
    },
  },
};
</script>

<style scoped>
#drawer-title {
  font-size: 0.95rem;
}
.grey-bg {
  background-color: rgba(100, 100, 100, 0.5);
}
</style>
