<template>
  <v-card
    :width="$vuetify.breakpoint.mdAndUp ? '140' : '100%'"
    :height="$vuetify.breakpoint.mdAndUp ? '120' : '48'"
    class="classrooms-info-card mb-3 pa-3"
    :class="{ 'classroom-clicked': id === currentId }"
    @click="() => $emit('onClassSelected', { id, name })"
  >
    <v-flex
      class="fill-height"
      :class="{ 'd-flex pt-0': $vuetify.breakpoint.smAndDown }"
    >
      <v-row justify="center" align="center" class="fill-height" no-gutters>
        <h5
          class="classroom-name-card"
          :class="{ 'classroom-name-card-clicked': id === currentId }"
        >
          {{ name }}
        </h5>
      </v-row>
      <v-row v-if="showStudentsNumber" justify="center" no-gutters>
        <h6 class="classroom-students-card">{{ numberOfStudents }} studenti</h6>
      </v-row>
    </v-flex>
  </v-card>
</template>

<script>
export default {
  name: "SmallClassCard",
  props: {
    name: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    currentId: {
      type: [String, null],
      default: null,
    },
    numberOfStudents: {
      type: Number,
      required: true,
    },
    showStudentsNumber: {
      type: Boolean,
    },
  },
};
</script>

<style scoped>
.classrooms-info-card {
  background: linear-gradient(
      109.46deg,
      rgba(1, 0, 46, 0.3) 1.57%,
      rgba(1, 0, 46, 0.3) 100%
    ),
    linear-gradient(
      109.46deg,
      rgba(201, 201, 201, 0.3) 1.57%,
      rgba(196, 196, 196, 0.05) 100%
    ) !important;
  backdrop-filter: blur(30px);
  border-radius: 12px;
}

.classroom-name-card {
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
  text-align: center;
  letter-spacing: 1px;
  color: #ffffff;

  overflow: hidden;
  max-width: 13ch;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.classroom-name-card-clicked {
  color: #f39200;
}

.classroom-clicked {
  border: 2px solid #f39200;
}

.classroom-students-card {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.75px;
  color: #c6ced2;
}
</style>
