import api from "@services/axiosInstance";

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/Learning%20path/get_learning_paths
 */
function getOndemandCourses() {
  return api.get("/learning-paths");
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/Learning%20path/get_learning_paths__id_
 */
function getOndemandById(id) {
  return api.get(`/learning-paths/${id}`);
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/Learning%20path/get_learning_paths_classroom__id_
 */
function getLearningPathsByClassId(classId) {
  return api.get(`/learning-paths/classroom/${classId}`);
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/Learning%20path/get_learning_paths_classroom__id_
 */
function updateLearningPathVideo(resourceId) {
  return api.get(`/learning-paths/video-lessons/${resourceId}/status`);
}

function getOndemandReportByClassId({ classId, learningPathId }) {
  return api.get(
    `/report/resources-report?class_id=${classId}&learning_path_id=${learningPathId}`
  );
}
function downloadOndemandOverviewReportExcel({ classId, learningPathId }) {
  return api.get(
    `/report/overview?class_id=${classId}&learning_path_id=${learningPathId}`,
    { responseType: "blob" }
  );
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/index.html#/Learning%20resource/post_learning_paths_video_lessons__id__complete
 */
function completeVideo(id) {
  return api.post(`/learning-paths/video-lessons/${id}/complete`);
}
export default {
  getOndemandCourses,
  getOndemandById,
  getLearningPathsByClassId,
  updateLearningPathVideo,
  getOndemandReportByClassId,
  downloadOndemandOverviewReportExcel,
  completeVideo,
};
