<template>
  <DefaultTable
    :key="classId"
    :headers="headers"
    :local-values="getCoursesByIds(selectedClass.courses)"
    :custom-slots="[
      'downloadReport'
    ]"
  >
    <template #downloadReport="{ item }">
      <Download
        :store-name="'reports'"
        :store-action="'getReportByCourse'"
        :object-to-send="{
          classroomId: classId,
          courseId: item.id,
          fileName: `report_${item.title}_${selectedClass.name}`
        }"
      />
    </template>
  </DefaultTable>
</template>

<script>
import { mapGetters } from "vuex";
import Download from "@cmp/shared/actions/Download.vue";
import DefaultTable from "@cmp/shared/DefaultTable.vue";
export default {
  name: "ReportPing",
  components: { DefaultTable, Download },
  props: {
    selectedClass: {
      type: Object,
      required: true,
    },
    classId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        {
          text: "Corso",
          value: "title",
        },
        { text: "Avanzamento corso",
          value: "downloadReport", 
        },
      ]
    };
  },
  computed: {
    ...mapGetters("courses", ["getCoursesByIds"]),
  },
};
</script>


<style scoped>
.small-font {
  font-size: 1.2rem;
}

.open-button {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #f4f5f6;
  text-transform: none;
}
</style>