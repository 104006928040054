<template>
  <v-row justify="center">
    <v-dialog v-model="open" persistent max-width="600px">
      <v-card v-if="generatedBadge" class="glassify rounded-lg white--text">
        <v-card-title>
          <span class="bit-text text-uppercase text-center dialog-title"
            >Badge gia generato</span
          >
        </v-card-title>
        <v-card-text>
          <v-container class="pa-2 white--text dialog-roboto">
            <v-row>
              <v-img
                class="dialog-image"
                contain
                :src="require('@/assets/student/medal.webp')"
              />
            </v-row>
            <v-row justify="center" class="pt-2">
              Hai già generato il tuo badge Credly associato a questo test.
            </v-row>
            <v-row justify="center">
              Se hai bisogno di supporto contatta
            </v-row>
            <v-row justify="center">
              <span class="orange--text text-decoration-underline"
                >hackersgen@sorint.com</span
              >
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-container>
            <v-row justify="center">
              <v-btn
                color="green lighten-2"
                class="white--text font-weight-bold"
                dense
                @click="closeForm"
              >
                Ho capito
              </v-btn>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
      <v-card v-else class="glassify rounded-lg white--text">
        <v-card-title>
          <span class="bit-text text-uppercase text-center dialog-title"
            >Genera il tuo badge Credly</span
          >
        </v-card-title>
        <v-card-text>
          <v-container class="pa-2 white--text dialog-roboto">
            <v-row>
              <v-img
                class="dialog-image"
                contain
                :src="require('@/assets/student/medal.webp')"
              />
            </v-row>
            <v-row class="text-center pt-2">
              <span>{{ message1 }}</span>
            </v-row>
            <v-row class="mt-4">
              <v-text-field
                v-model="email"
                label="Email*"
                required
                color="white"
                dark
                :rules="[rules.required, rules.check]"
              />
            </v-row>
            <v-row class="ma-0 pa-0 text-caption">
              <v-checkbox v-model="emailFlag" color="orange">
                <template #label>
                  <div class="checbox-label">
                    {{ message2 }}
                  </div>
                </template>
              </v-checkbox>
            </v-row>
          </v-container>
          <small class="white--text">*campo obbligatorio</small>
        </v-card-text>
        <v-card-actions>
          <v-container>
            <v-row>
              <v-col align="end">
                <v-btn
                  class="font-weight-bold"
                  color="orange"
                  outlined
                  text
                  dark
                  @click="closeForm"
                >
                  Non ora
                </v-btn>
              </v-col>
              <v-col align="start">
                <v-btn
                  class="white--text font-weight-bold"
                  :disabled="isGeneraDisabled"
                  color="orange darken-2"
                  dense
                  dark
                  @click="generateBadge"
                >
                  Genera il badge
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "CredlyBadgeDialog",
  props: {
    secondaryEmail: {
      type: [String, null],
      required: true,
    },
    primaryEmail: {
      type: [String, null],
      required: true,
    },
    generatedBadge: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      open: true,
      email: "",
      message1: "",
      message2:
        "Associa questa email come tua email personale nella piattaforma HackersGen per poter ricevere avvisi su opportunità lavorative.",
      message3: "Contatta hackersgen@sorint.com per supporto",
      emailFlag: true,
      rules: {
        required: (value) => Boolean(value) || "Required.",
        check: (value) =>
          value !== this.primaryEmail ||
          "L'email non deve coincidere con l'email primaria.",
      },
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    isGeneraDisabled() {
      return (
        !this.email ||
        this.email.trim().length === 0 ||
        this.email.trim() === this.primaryEmail
      );
    },
  },
  created() {
    this.emailFlag = this.user.can_contact;
    this.email = this.secondaryEmail;
    if (!this.secondaryEmail || this.secondaryEmail.trim().length === 0) {
      this.message1 = `Ottieni il badge Credly associato a questo test e condividilo sui tuoi profili social!
                      Inserisci una tua email personale per poter ricevere il badge.`;
    } else {
      this.message1 = `Ottieni il badge Credly associato a questo test e condividilo sui tuoi profili social!
                      Il badge ti arriverà a questo indirizzo email:`;
    }
  },
  methods: {
    closeForm() {
      this.$emit("closeForm");
    },
    generateBadge() {
      const saveEmail = this.email !== this.secondaryEmail;
      this.$emit("confirm", saveEmail, this.email, this.emailFlag);
    },
  },
};
</script>

<style scoped>
.checbox-label {
  font-size: 10px;
  line-height: 1;
  color: white;
}

.dialog-title {
  width: 100%;
  font-size: 30px;
}

.dialog-image {
  width: 90px;
  height: 90px;
}

.dialog-roboto {
  font-family: "Roboto", Verdana, Geneva, Tahoma, sans-serif;
  font-size: 16px;
}
</style>
