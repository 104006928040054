<template>
  <div :class="landingPage ? 'landingPage my-7' : ''">
    <v-row>
      <v-col>
        <h2
          class="mb-5 text-center"
          :class="landingPage ? 'black--text' : 'white--text'"
        >
          Con il supporto di:
        </h2>
      </v-col>
    </v-row>
    <v-row
      align="center"
      justify="space-around"
      class="mx-1 my-0 py-2 white rounded-xl"
      max-width="50"
    >
      <v-col
        cols="8"
        sm="4"
      >
        <a
          href="https://www.itispaleocapa.edu.it/"
          target="_blank"
          rel="noopener"
        >
          <v-img
            max-height="100"
            contain
            src="@/assets/paleocapa_logo.webp"
            alt="logo_paleocapa"
          />
        </a>
      </v-col>
      <v-col
        cols="12"
        sm="3"
      >
        <a
          href="https://itisdalmine.edu.it"
          target="_blank"
          rel="noopener"
        >
          <v-img
            contain
            :max-height="$vuetify.breakpoint.md ? 60 : 90"
            src="@/assets/marconi_logo.webp"
            alt="logo_marconi"
          />
        </a>
      </v-col>
      <v-col
        cols="12"
        sm="4"
      >
        <a
          href="http://www.retem2a.it"
          target="_blank"
          rel="noopener"
        >
          <v-img
            contain
            max-height="100"
            src="@/assets/m2a_logo.webp"
            alt="logo_m2a"
          />
        </a>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Supporters",
  props: {
    landingPage: {
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>
.landingPage {
  max-width: 1100px;
  margin: auto;
  padding: 0 50px;
}
</style>
