var render = function render(){var _vm=this,_c=_vm._self._c;return _c('DefaultTable',{key:_vm.classId,attrs:{"headers":_vm.headers,"store-name":'learningPaths',"method":'getLearningPathsByClassroomId',"item-type":{ classId: _vm.classId },"custom-slots":[
    'downloadOverviewReport',
    'courseSelector'
  ]},scopedSlots:_vm._u([{key:"downloadOverviewReport",fn:function({ item }){return [_c('Download',{attrs:{"store-name":'learningPaths',"store-action":'downloadOndemandOverviewReportExcel',"object-to-send":{
        classId: _vm.classId,
        learningPathId: item.id,
        learningPathName: item.title,
      }}})]}},{key:"courseSelector",fn:function({ item }){return [_c('v-btn',{staticClass:"rounded-lg open-button",attrs:{"color":"#F39200","width":"40px","height":"40px","min-width":"0"},on:{"click":function($event){return _vm.selectCourse(item)}}},[_c('v-icon',[_vm._v("mdi-login")])],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }