<template>
  <v-card
    class="rounded-lg glassify pa-0 mb-3"
    dark
    :class="outlined ? 'corso-outlined' : null"
  >
    <v-container>
      <v-row class="pa-0" no-gutters>
        <v-col cols="12" sm="5" md="3" lg="2" align="center">
          <v-img
            :src="course.thumbnailPath || require('@/assets/course_bg.webp')"
            class="course-img"
            alt="course image"
            :class="{
              'course-img-mobile': $vuetify.breakpoint.smAndDown,
            }"
          >
            <template #placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular indeterminate color="grey lighten-5" />
              </v-row>
            </template>
            <div
              v-if="isSubscribed"
              class="corner-ribbon sticky font-weight-bold"
              :class="
                $vuetify.breakpoint.smAndDown ? 'top-left-mobile' : 'top-left'
              "
            >
              ISCRITTO
            </div>
          </v-img>
        </v-col>
        <v-col
          cols="12"
          sm="7"
          md="9"
          lg="10"
          class="course-container"
          :class="$vuetify.breakpoint.smAndUp ? 'pl-5' : 'pt-2'"
        >
          <v-row class="course-row-header" align="start" no-gutters>
            <v-col align="start">
              <h4 class="courses-labels">
                {{ hasEpisodes ? dates : "Prossimamente" }}
              </h4>
            </v-col>
          </v-row>
          <v-row align="start" no-gutters>
            <v-col>
              <h3 class="text-left course-title">
                {{ course.title }}
              </h3>
            </v-col>
          </v-row>
          <v-row align="center" no-gutters>
            <v-col class="py-0">
              <h5 class="course-paragraph">
                {{ course.description }}
              </h5>
            </v-col>
          </v-row>
          <v-row no-gutters align="end" class="mt-6">
            <v-col cols="12" lg="6">
              <p
                v-for="(author, authorIndex) of course.authors"
                :key="authorIndex"
                class="text-left my-0"
              >
                {{ author.name }} -
                <em class="text-caption">{{ author.role }}</em>
              </p>
              <p v-show="hasEpisodes" class="text-left my-0 lessons-number">
                {{ course.episodes.length }}
                {{ course.episodes.length > 0 ? "lezioni" : "lezione" }}
              </p>
            </v-col>
            <v-col class="d-flex flex-wrap justify-end mt-3" cols="12" lg="6">
              <v-btn
                v-if="isTeacher && !course.onDemand"
                dark
                color="#F39200"
                class="rounded-lg mb-2 card-actions"
                :width="$vuetify.breakpoint.smAndDown ? '100%' : ''"
                @click="$emit('subscribe')"
              >
                <v-icon left size="24"> mdi-account-plus-outline </v-icon>
                Iscrivi classi
              </v-btn>

              <v-btn
                v-if="isStudent"
                :disabled="!hasSchool || isSubscribed"
                dark
                color="#F39200"
                class="rounded-lg mb-2 card-actions"
                :width="$vuetify.breakpoint.smAndDown ? '100%' : ''"
                @click="needSubscription(course.id)"
              >
                <img
                  src="@assets/landingPage/send.svg"
                  alt="send"
                  class="mr-3"
                  :class="{ 'icon-disabled': !hasSchool || isSubscribed }"
                />
                Richiedi iscrizione
              </v-btn>
              <v-tooltip bottom :disabled="hasEpisodes">
                <template #activator="{ on }">
                  <div
                    :class="{ 'button-wrapper': $vuetify.breakpoint.smAndDown }"
                    v-on="on"
                  >
                    <v-btn
                      dark
                      :disabled="!hasEpisodes"
                      :width="$vuetify.breakpoint.smAndDown ? '100%' : ''"
                      :data-cy="
                        hasEpisodes
                          ? 'open-course-button'
                          : 'close-course-button'
                      "
                      color="#F39200"
                      class="rounded-lg card-actions"
                      :class="$vuetify.breakpoint.smAndDown ? '' : 'ml-5'"
                      @click="() => navigateTo(courseLink)"
                    >
                      <v-icon left size="24"> mdi-arrow-expand </v-icon>
                      Apri corso
                    </v-btn>
                  </div>
                </template>
                <span> Maggiori dettagli disponibili a breve </span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-row v-if="getClassroomsAlreadyRegistered(id).length > 0">
            <v-divider />
          </v-row>

          <v-row
            v-if="getClassroomsAlreadyRegistered(id).length > 0"
            align="center"
            justify="center"
          >
            <v-col>
              <span>Classi iscritte:</span>
            </v-col>
            <v-col cols="12" md="9">
              <v-chip-group show-arrows>
                <v-chip
                  v-for="(classroom, index) of getClassroomsAlreadyRegistered(
                    id
                  )"
                  :key="index"
                  outlined
                  color="yellow"
                  disabled
                  class="readonly"
                >
                  <span class="text-uppercase">
                    {{ classroom.name }}
                  </span>
                </v-chip>
              </v-chip-group>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import matomoTracker from "@mixins/matomoTracker";
import courseHandler from "@mixins/courseHandler";

export default {
  name: "CoursePreview",
  mixins: [matomoTracker, courseHandler],
  props: {
    id: {
      type: String,
      default: "",
    },
    course: {
      type: Object,
      required: true,
    },
    outlined: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters("classrooms", ["getClassroomsAlreadyRegistered"]),
    courseLink() {
      if (this.course.episodes && this.course.episodes.length > 0) {
        return {
          name: "live-video-page",
          params: {
            id: this.course.id,
            videoId: this.todayLiveEpisode
              ? this.todayLiveEpisode.videoId
              : this.lastLiveEpisodeDone
              ? this.lastLiveEpisodeDone.videoId
              : this.course.episodes[0].videoId,
          },
        };
      }
      return { name: "waiting-approval" };
    },
    dates() {
      const formatOption = {
        day: "numeric",
        month: "long",
        year: "numeric",
      };

      if (this.course.episodes.length === 0) {
        return "";
      }

      const firstDay = new Date(this.course.episodes[0].start * 1000);
      const beginString = firstDay.toLocaleDateString("it-IT", formatOption);

      const lastDay = new Date(
        this.course.episodes[this.course.episodes.length - 1].start * 1000
      );
      const endString = lastDay.toLocaleDateString("it-IT", formatOption);

      return beginString === endString
        ? beginString
        : `${beginString} - ${endString}`;
    },
    hasEpisodes() {
      return this.course.episodes.length > 0;
    },
    isSubscribed() {
      return this.isStudent && this.course.subscribed;
    },
  },
  methods: {
    ...mapActions("courses", ["requestSubscription"]),
    async navigateTo(routeObject) {
      await this.trackEvent("PlatformLiveCoursesPage", "ClickOnOpenCourse");
      this.$router.push(routeObject);
    },
    async needSubscription(id) {
      await this.requestSubscription(id);
    },
  },
};
</script>

<style scoped>
.corner-ribbon {
  width: 200px;
  background: rgb(246, 127, 0);
  position: absolute;
  top: 25px;
  left: -50px;
  text-align: center;
  line-height: 30px;
  letter-spacing: 1px;
  color: #f0f0f0;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.corner-ribbon.sticky {
  position: relative;
}

.corner-ribbon.top-left {
  top: 25px;
  left: -55px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.corner-ribbon.top-left-mobile {
  top: 25px;
  left: -125px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.corso-outlined {
  box-shadow: 0 0 10px 2px rgba(127, 186, 244, 1) !important;
}

.lessons-number {
  font-size: 0.7em;
  color: #dddddd;
}

.course-container {
  display: flex;
  flex-direction: column;
}

.text-left {
  align-self: center;
}

.course-row-header > div {
  padding-top: 0.2rem;
  padding-bottom: 0;
  color: #dddddd;
  font-size: 0.9em;
}

.new-course {
  color: rgb(0, 255, 0);
  font-size: 1rem;
}

.course-thumbnail-horizontal {
  border-radius: 8px 0 0 8px;
}

.course-thumbnail-vertical {
  border-radius: 8px 8px 0 0;
}

.course-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #ffffff;
}

.course-paragraph {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #98a6ae;
}

.ask-subscription {
  font-size: 1.2rem;
}

.not-selectable {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently */
}

.readonly {
  opacity: 1;
}

.course-img {
  border-radius: 12px;
  width: 197px;
  height: 157;
  object-fit: contain;
  object-position: center;
}

.course-img-mobile {
  width: 100%;
}

.courses-labels {
  font-weight: 400;
  font-size: 10px;
  line-height: 18px;
  color: #98a6ae;
}

.icon-disabled {
  filter: saturate(0);
  opacity: 0.8;
}

.card-actions {
  text-transform: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}

.button-wrapper {
  width: 100%;
}
</style>
