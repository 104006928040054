export default {
  props: {
    course: {
      required: true,
      type: Object,
    },
    episode: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    lastLiveDay() {
      return new Date(
        Math.max(
          ...this.course.episodes.map((episode) => Number(episode.end))
        ) * 1000
      ).setHours(23, 59, 59, 0);
    },
    liveEpisode() {
      const now = Date.now() / 1000;
      const currentEpisodes = this.course.episodes.filter(
        (episode) => now <= episode.end && now >= episode.start
      );
      return currentEpisodes.length > 0 ? currentEpisodes[0] : null;
    },
    todayLiveEpisode() {
      const today = new Date();

      return this.course.episodes.find((item) => {
        const itemDate = new Date(item.start * 1000);
        return (
          itemDate.getDate() === today.getDate() &&
          itemDate.getMonth() === today.getMonth() &&
          itemDate.getFullYear() === today.getFullYear()
        );
      });
    },
    lastLiveEpisodeDone() {
      const today = new Date();
      const sortedEpisodes = this.course.episodes;
      let latestEpisode = null;

      if (!sortedEpisodes) {
        return null;
      }

      sortedEpisodes.sort((epA, epB) => {
        return epA.start < epB.start ? -1 : epA.start > epB.start ? 1 : 0;
      });
      sortedEpisodes.find((episode) => {
        if (episode.start * 1000 > today) return true;
        latestEpisode = episode;
      });

      return latestEpisode;
    },
    isCorrectEpisode() {
      return !(
        this.liveEpisode !== null && this.liveEpisode.id !== this.episode.id
      );
    },
  },
  methods: {
    getEpisodeLink(episode) {
      return `/course/${this.course.id}/video/${episode.videoId}`;
    },
  },
};
