<template>
  <v-app class="app-container">
    <v-container class="text-center">
      <v-row
        align="center"
        class="my-8"
      >
        <v-col
          v-if="isConfirmed"
          cols="12"
        >
          <h1 class="white--text font-weight-light">
            Grazie per aver confermato la tua mail.
          </h1>
          <h2 class="font-weight-light white--text">
            Torna alla schermata di login per accedere alla piattaforma
          </h2>
        </v-col>
        <v-col
          v-else
          cols="12"
        >
          <h1 class="white--text font-weight-light">
            Errore nella conferma della mail.
          </h1>
          <h2 class="font-weight-light white--text">
            Contatta hackersgen@sorint.com per chiedere supporto!
          </h2>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-btn
          color="secondary_accent white--text"
          :to="{ name: `login` }"
        >
          Torna alla login
        </v-btn>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>

import { mapActions } from "vuex";

export default {
  name: "ConfirmVisitor",
  data() {
    return {
      isConfirmed: null,
      visitorID: this.$route.params.visitorID,
    };
  },
  async created() {
    this.isConfirmed = await this.confirmVisitorAddress({visitorId: this.visitorID})
  },
  methods: {
    ...mapActions("auth", ["confirmVisitorAddress"])
  },
};
</script>

<style scoped>
#video-emb {
  max-width: 560px;
  max-height: 315px;
  height: 50vh;
  width: 100%;
}

#a {
  color: #f37021;
  text-decoration: none;
}

#a:visited {
  color: #f37021;
  text-decoration: none;
}

#a:hover {
  color: white;
  text-decoration: underline;
}

#a:active {
  text-decoration: underline;
}
</style>
