<template>
  <div class="text-center">
    <Modal
      width="928px"
      title=""
      :show-close-button="false"
      :persistent="true"
      :scrollable="true"
      @closeModal="closeDialog"
    >
      <template #content-center>
        <v-card-title
          v-if="platformEnv !== 'development'"
          class="modal-title pt-8"
          >Sta arrivando la
          <span class="orange-journey ml-1">Tech Journey!</span></v-card-title
        >
        <v-card-title v-else class="modal-title pt-8"
          >E' arrivata la
          <span class="orange-journey ml-1">Tech Journey!</span></v-card-title
        >
        <v-img
          src="@assets/platform-home-page/tech-journey-popup.webp"
          height="339px"
          class="mt-3"
          contain
        />
        <v-card-text
          v-if="platformEnv !== 'development'"
          class="modal-text mx-auto my-3"
        >
          Stiamo apportando miglioramenti significativi alla vostra esperienza
          di apprendimento mediante l'introduzione di percorsi specifici che si
          troveranno nella <b class="orange-journey">Tech Journey</b>. Volete
          saperne di più?
        </v-card-text>
        <v-card-text v-else class="modal-text mx-auto my-3">
          Abbiamo apportato miglioramenti significativi alla vostra esperienza
          di apprendimento mediante l'introduzione di percorsi specifici che si
          trovano nella <b class="orange-journey">Tech Journey</b>.
        </v-card-text>
        <v-card-actions v-if="platformEnv !== 'development'">
          <v-btn
            color="#f39200"
            class="white--text mx-3 rounded-lg mx-auto keep-me-updated"
            large
            @click="keepMeUpdated"
          >
            <v-icon class="mx-2">mdi-check</v-icon>
            Tienimi aggiornato
          </v-btn>
        </v-card-actions>
        <v-card-actions v-else>
          <v-btn
            color="#f39200"
            class="white--text mx-3 rounded-lg mx-auto keep-me-updated"
            large
            @click="pushJourneyWelcomeUrl"
          >
            <v-icon class="mx-2">mdi-check</v-icon>
            Inizia il viaggio
          </v-btn>
        </v-card-actions>
        <v-card-actions>
          <v-btn
            text
            class="no-thanks white--text mx-3 rounded-lg mx-auto"
            large
            @click="closeDialog"
          >
            <v-icon class="mx-2">mdi-close</v-icon>
            No grazie
          </v-btn>
        </v-card-actions>
      </template>
    </Modal>
  </div>
</template>

<script>
import Modal from "@cmp-shared/utils/Modal";
import matomoTracker from "@mixins/matomoTracker";
import { pushJourneyUrl } from "@/utils/externalRouter";
import { mapGetters } from "vuex";

export default {
  name: "TechJourneyPopup",
  mixins: [matomoTracker],
  components: { Modal },
  computed: {
    ...mapGetters("config", ["platformEnv"]),
  },
  methods: {
    closeDialog() {
      localStorage.setItem("tech-journey-last-popup", Date.now());
      this.$emit("close-dialog");
    },
    async keepMeUpdated() {
      await this.trackEvent(
        "PlatformHomePage",
        "KeepMeUpdatedAboutTechJourney"
      );
      localStorage.setItem("tech-journey-last-popup", Date.now());
      this.$emit("close-dialog");
      return;
    },
    pushJourneyWelcomeUrl() {
      const token = JSON.parse(localStorage.getItem("user"));
      pushJourneyUrl("/auth/sso", token.access_token);
    },
  },
};
</script>

<style scoped>
.modal-title {
  font-family: "Karasuma Gothic", sans-serif;
  justify-content: center;
  font-size: 1.2em;
  font-family: "outerorbit", "Orbitron" !important;
  font-style: normal !important;
  font-weight: 300 !important;
  font-size: 24px !important;
  line-height: 24px !important;
  text-transform: uppercase !important;
  color: #f4f5f6 !important;
}

.modal-text {
  font-family: Karasuma Gothic;
  font-size: 20px;
  font-weight: 350;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
  color: #f4f5f6;
  max-width: 820px;
}

.orange-journey {
  background: linear-gradient(#f39200, #fcee21);
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  font-weight: 700;
}

.keep-me-updated {
  font-family: Karasuma Gothic;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  max-width: 228px;
  height: 40px;
}

.no-thanks {
  text-transform: unset !important;
  font-family: Karasuma Gothic;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
}
</style>
