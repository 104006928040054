var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-navigation-drawer',{staticClass:"glassify bit-text text-uppercase",attrs:{"absolute":"","temporary":"","width":"75%"},model:{value:(_vm.$store.state.toolbar.drawer),callback:function ($$v) {_vm.$set(_vm.$store.state.toolbar, "drawer", $$v)},expression:"$store.state.toolbar.drawer"}},[_c('v-list-item',{staticClass:"mt-2",on:{"click":_vm.openMyProfile}},[_c('v-list-item-avatar',[_c('v-img',{attrs:{"src":_vm.avatarUrl},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-container',{staticClass:"fill-height ma-0 grey-bg"})]},proxy:true}])})],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"white--text",attrs:{"id":"drawer-title"}},[_vm._v(" "+_vm._s(_vm.user ? _vm.user.name + " " + _vm.user.surname : "...")+" ")]),(_vm.user && _vm.user.access_level !== 1)?_c('v-list-item-subtitle',{staticClass:"text--lighten-1 grey--text"},[_vm._v(" APRI PROFILO ")]):_vm._e()],1)],1),_c('v-divider',{staticClass:"my-2"}),_c('v-list',{attrs:{"dense":""}},[_c('v-list-item-group',{attrs:{"color":"primary"}},[(_vm.$vuetify.breakpoint.mdAndDown)?_c('v-list-item',{on:{"click":function($event){return _vm.$router.push({
            name: `${_vm.currentUserRedirect}-platform-home-page`,
          })}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-home ")])],1),_c('v-list-item-content',{staticClass:"white--text"},[_vm._v(" Home ")])],1):_vm._e(),(_vm.$vuetify.breakpoint.mdAndDown && (_vm.isGuest || _vm.isSorint))?_c('v-list-item',{on:{"click":function($event){return _vm.$router.push({
            name: `${_vm.currentUserRedirect}-ondemand-courses`,
          })}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-school ")])],1),_c('v-list-item-content',{staticClass:"white--text"},[_vm._v(" Corsi on demand ")])],1):_vm._e(),(_vm.$vuetify.breakpoint.mdAndDown && (_vm.isGuest || _vm.isSorint))?_c('v-list-item',{on:{"click":function($event){return _vm.$router.push({
            name: `${_vm.currentUserRedirect}-courses`,
          })}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-play ")])],1),_c('v-list-item-content',{staticClass:"white--text"},[_vm._v(" Corsi live ")])],1):_vm._e(),(_vm.$vuetify.breakpoint.mdAndDown && _vm.isTeacher)?_c('v-list-item',{on:{"click":function($event){return _vm.goToClassManagement()}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-account-group ")])],1),_c('v-list-item-content',{staticClass:"white--text"},[_vm._v(" Classi ")])],1):_vm._e(),(_vm.$vuetify.breakpoint.mdAndDown && _vm.isTeacher)?_c('v-list-item',{on:{"click":function($event){return _vm.$router.push({
            name: `${_vm.currentUserRedirect}-report`,
          })}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-download ")])],1),_c('v-list-item-content',{staticClass:"white--text"},[_vm._v(" Report ")])],1):_vm._e(),(_vm.$vuetify.breakpoint.mdAndDown && (_vm.isStudent || _vm.isTeacher))?_c('v-list-item',{on:{"click":function($event){return _vm.changeRoute()}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-source-branch ")])],1),_c('v-list-item-content',{staticClass:"white--text"},[_vm._v(" Journey ")])],1):_vm._e(),(_vm.$vuetify.breakpoint.mdAndDown)?_c('v-list-item',{on:{"click":function($event){return _vm.$router.push({
            name: `${_vm.currentUserRedirect}-support`,
          })}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-help ")])],1),_c('v-list-item-content',{staticClass:"white--text"},[_vm._v(" Supporto ")])],1):_vm._e(),(_vm.$vuetify.breakpoint.mdAndDown)?_c('v-divider',{staticClass:"my-5 white--text",attrs:{"light":""}}):_vm._e(),_c('v-list-item',{on:{"click":_vm.quit}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-logout ")])],1),_c('v-list-item-content',{staticClass:"white--text"},[_vm._v(" Logout ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }