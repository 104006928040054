<template>
  <div class="quiz-wrapper px-2 mt-2 mb-5">
    <v-row class="mb-5 mt-2">
      <v-col cols="12">
        <h2>{{ quizInfo.title }}</h2>
      </v-col>
      <v-col cols="12">
        <h4>{{ quizInfo.description }}</h4>
      </v-col>
      <v-alert
        v-if="!showQuiz"
        text
        outlined
        dense
        color="#7FBAF4"
        class="rounded-lg mx-3 mb-0 alert-text"
      >
        Attenzione! Una volta superato il quiz non potrai più aprirlo. Hai la
        possibilità di rifarlo qualora non dovessi passarlo.
      </v-alert>
    </v-row>
    <v-row v-if="showQuiz">
      <OndemandQuizTimer
        v-if="isStudent"
        :seconds="quiz.seconds_left"
        class="pr-3"
      />
      <v-col
        v-for="(question, index) in quiz.questions"
        :key="question.id"
        cols="12"
        class="mb-5"
      >
        <p v-dompurify-html="question.text" />
        <v-radio-group
          v-model="pickedAnswers[index]"
          :readonly="saved"
          class="pa-0 ma-0"
        >
          <v-radio
            v-for="answer in question.answers"
            :key="answer.id"
            :label="answer.text"
            :value="answer.id"
            dark
            class="ma-1"
          />
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row v-if="!showQuiz">
      <v-col>
        <v-btn
          color="#F39200"
          class="white--text font-weight-bold btn-size rounded-lg"
          :width="$vuetify.breakpoint.smAndDown ? '100%' : ''"
          @click="startQuiz"
        >
          Inizia quiz
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col v-if="!areSelected" cols="12" md="9">
        <v-btn
          color="#F39200"
          class="white--text font-weight-bold btn-size rounded-lg"
          :width="$vuetify.breakpoint.smAndDown ? '100%' : ''"
          @click="saveAnswers(isStudent)"
        >
          {{ isStudent ? "Salva risposte" : "Termina quiz" }}
        </v-btn>
      </v-col>
      <v-col v-else cols="12" md="9">
        <v-btn
          v-if="isStudent"
          color="#F39200"
          class="white--text font-weight-bold btn-size rounded-lg mr-5"
          :class="{ 'mb-2': $vuetify.breakpoint.smAndDown }"
          :width="$vuetify.breakpoint.smAndDown ? '100%' : ''"
          @click="unsaveAnswers"
        >
          Sblocca risposte
        </v-btn>
        <v-btn
          v-if="isStudent"
          color="#F39200"
          class="white--text font-weight-bold btn-size rounded-lg"
          :width="$vuetify.breakpoint.smAndDown ? '100%' : ''"
          @click="finishQuiz"
        >
          Termina quiz
        </v-btn>
      </v-col>
    </v-row>
    <AssessmentResultDialog v-if="showDialog" :result="result" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import OndemandQuizTimer from "../../components/ondemand/UI/OndemandQuizTimer.vue";
import AssessmentResultDialog from "../../components/ondemand/UI/AssessmentResultDialog.vue";
import userHandler from "@mixins/userHandler";
import trigger from "@mixins/trigger";
export default {
  name: "OndemandQuiz",
  components: { OndemandQuizTimer, AssessmentResultDialog },
  mixins: [userHandler, trigger],
  data: () => ({
    saved: false,
    showDialog: false,
    showQuiz: false,
    quiz: {},
    pickedAnswers: [],
    finishedQuiz: {
      answers: [],
      quizId: "",
    },
    result: false,
    areSelected: false,
    quizInfo: {},
    saveInterval: null,
  }),
  computed: {
    resourceId() {
      return this.$route.params.resourceId;
    },
  },
  async mounted() {
    await this.getQuiz();
  },
  destroyed() {
    clearInterval(this.saveInterval);
  },
  methods: {
    ...mapActions("learningResources", [
      "startOndemandQuiz",
      "selectOndemandQuizAnswers",
      "finishOndemandQuiz",
      "getOndemandQuizzesById",
    ]),
    async startQuiz() {
      this.quiz = await this.startOndemandQuiz({ id: this.resourceId });
      const tmp = this.quiz.questions.map((q) =>
        q.answers.filter((a) => a.selected)
      );
      this.pickedAnswers = tmp
        .map((t) => t[0]?.id)
        .filter((e) => {
          return e !== undefined;
        })
        .slice();
      this.showQuiz = true;
      if (this.isStudent) {
        this.saveInterval = setInterval(this.chacheAnswers, 15000);
      }
    },
    async saveAnswers(isStudent) {
      if (this.isStudent) {
        this.saveInterval = clearInterval(this.saveInterval);
        this.finishedQuiz.answers = [];
      }
      this.saved = true;
      const questionsId = this.quiz.questions.map((q) => q.id);
      for (let i = 0; i < questionsId.length; i++) {
        this.finishedQuiz.answers.push({
          answerId: this.pickedAnswers[i],
          questionId: questionsId[i],
        });
      }
      this.finishedQuiz.quizId = this.resourceId;
      const data = await this.selectOndemandQuizAnswers({
        ondemandQuizAnswers: this.finishedQuiz,
      });
      this.areSelected = true;
      if (!isStudent) {
        this.result = data;
        this.showDialog = true;
      }
    },
    unsaveAnswers() {
      this.saveInterval = setInterval(this.chacheAnswers, 15000);
      this.saved = false;
      this.areSelected = false;
      this.finishedQuiz.answers = [];
    },
    async finishQuiz() {
      const data = await this.finishOndemandQuiz({ id: this.resourceId });
      this.result = data;
      this.showDialog = true;
      this.triggerUpdate();
    },
    async getQuiz() {
      this.quizInfo = await this.getOndemandQuizzesById({
        id: this.resourceId,
      });
    },
    async chacheAnswers() {
      this.finishedQuiz.answers = [];
      const questionsId = this.quiz.questions.map((q) => q.id);
      for (let i = 0; i < questionsId.length; i++) {
        this.finishedQuiz.answers.push({
          answerId: this.pickedAnswers[i],
          questionId: questionsId[i],
        });
      }
      this.finishedQuiz.quizId = this.resourceId;
      await this.selectOndemandQuizAnswers({
        ondemandQuizAnswers: this.finishedQuiz,
      });
    },
  },
};
</script>

<style scoped>
.quiz-wrapper {
  border: 1px #5a6972 solid;
  border-radius: 8px;
}

.alert-text {
  font-style: italic;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
}
</style>
