/**
 *
 * Authentication Views
 */
import Token from "@cmp/auth/Token";
import Login from "@views/auth/Login";
import ConfirmVisitor from "@views-guest/ConfirmVisitor";
import MyProfile from "@views-common/MyProfile";
import PendingClassroom from "@views-common/PendingClassroom";

/**
 *
 * Utils
 */
import ErrorUnauthorized from "@views/utils/ErrorUnauthorized";
import ErrorPage from "@views/utils/ErrorPage";

/**
 * Commons
 */
const Support = () =>
  import(/*webpackChunkName: "common"*/ "@views/utils/Support");

const QuizList = () =>
  import(/*webpackChunkName: "common"*/ "@views-common/QuizList");
const PlatformHomePage = () =>
  import(/*webpackChunkName: "common"*/ "@views-common/PlatformHomePage");
const OndemandCourses = () =>
  import(/*webpackChunkName: "common"*/ "@views-common/OndemandCourses");
const ListOfCourses = () =>
  import(/*webpackChunkName: "student"*/ "@views-common/Courses");
/**
 *
 * Student Views
 */
const StudentDashboard = () =>
  import(/*webpackChunkName: "student"*/ "@views-student/Dashboard");
const StudentQuizExam = () =>
  import(/*webpackChunkName: "student"*/ "@views-student/QuizExam");
const StudentQuizRoom = () =>
  import(/*webpackChunkName: "student"*/ "@views-student/QuizRoom");
const StudentReport = () =>
  import(/*webpackChunkName: "student"*/ "@views-student/Report");
const UpdateSecEmail = () =>
  import(/*webpackChunkName: "student"*/ "@views-student/UpdateSecEmail");
const VideoPage = () =>
  import(/*webpackChunkName: "student"*/ "@views-common/VideoPage");
const OndemandVideoPage = () =>
  import(/*webpackChunkName: "student"*/ "@views-common/OndemandVideoPage");
const OndemandVideoPlayer = () =>
  import(/*webpackChunkName: "student"*/ "@views-common/OndemandVideoPlayer");
const OndemandProgExercise = () =>
  import(/*webpackChunkName: "student"*/ "@views-common/OndemandProgExercise");
const OndemandQuiz = () =>
  import(/*webpackChunkName: "student"*/ "@views-common/OndemandQuiz");

/**
 *
 * Teacher Views
 */
const WaitingApproval = () =>
  import(/*webpackChunkName: "teacher" */ "@views-teacher/WaitingApproval");
const TeacherDashboard = () =>
  import(/*webpackChunkName: "teacher" */ "@views-teacher/Dashboard");
const TeacherReport = () =>
  import(/*webpackChunkName: "teacher" */ "@views-teacher/Report");
const TeacherClasses = () =>
  import(/*webpackChunkName: "teacher" */ "@views-teacher/Classes");
const SchoolChoice = () =>
  import(/*webpackChunkName: "teacher" */ "@views-teacher/SchoolChoice");

/**
 *
 * Guest Views
 */
const GuestDashboard = () =>
  import(/*webpackChunkName: "guest" */ "@views-guest/Dashboard");
/**
 *
 * Sorint Views
 */
const SorintDashboard = () =>
  import(/*webpackChunkName: "sorint" */ "@views-sorint/Dashboard");
const routes = [
  {
    path: "/",
    name: "root",
    component: Login,
  },
  {
    path: "/",
    children: [
      {
        path: "/auth/sso",
        name: "token",
        component: Token,
        meta: {
          accessible: true,
        },
      },
    ],
    component: Login,
  },
  {
    path: "/visitor/confirm-address/:visitorID",
    name: "confirm-visitor",
    meta: {
      accessible: true,
    },
    component: ConfirmVisitor,
  },
  {
    path: "/student",
    children: [
      {
        path: "home",
        name: "student-platform-home-page",
        component: PlatformHomePage,
      },
      {
        path: "update-sec-email",
        name: "student-update-sec-email",
        component: UpdateSecEmail,
      },
      {
        path: "courses",
        name: "student-courses",
        component: ListOfCourses,
      },
      {
        path: "ondemand-courses",
        name: "student-ondemand-courses",
        component: OndemandCourses,
      },

      {
        path: "report",
        name: "student-report",
        component: StudentReport,
      },
      {
        path: "test",
        name: "student-test",
        component: QuizList,
      },
      {
        path: "support",
        name: "student-support",
        component: Support,
      },
      {
        path: "quiz/:id",
        name: "student-quiz-room",
        component: StudentQuizRoom,
      },
      {
        path: "quiz/:id/exam",
        name: "student-quiz-exam",
        component: StudentQuizExam,
      },
    ],
    component: StudentDashboard,
  },
  {
    path: "/teacher",
    children: [
      {
        path: "home",
        name: "teacher-platform-home-page",
        component: PlatformHomePage,
      },
      {
        path: "school-choice",
        name: "teacher-school-choice",
        component: SchoolChoice,
      },
      {
        path: "courses",
        name: "teacher-courses",
        component: ListOfCourses,
      },
      {
        path: "ondemand-courses",
        name: "teacher-ondemand-courses",
        component: OndemandCourses,
      },

      {
        path: "report",
        name: "teacher-report",
        component: TeacherReport,
      },
      {
        path: "test",
        name: "teacher-test",
        component: QuizList,
      },
      {
        path: "support",
        name: "teacher-support",
        component: Support,
      },
      {
        path: "classes/:classId?",
        name: "teacher-classes",
        component: TeacherClasses,
      },
    ],
    component: TeacherDashboard,
  },
  {
    path: "/sorint",
    children: [
      {
        path: "home",
        name: "sorint-platform-home-page",
        component: PlatformHomePage,
      },
      {
        path: "/sorint/courses",
        name: "sorint-courses",
        component: ListOfCourses,
      },
      {
        path: "ondemand-courses",
        name: "sorint-ondemand-courses",
        component: OndemandCourses,
      },

      {
        path: "/sorint/support",
        name: "sorint-support",
        component: Support,
      },
    ],
    component: SorintDashboard,
  },
  {
    path: "/guest",
    children: [
      {
        path: "home",
        name: "guest-platform-home-page",
        component: PlatformHomePage,
      },
      {
        path: "courses",
        name: "guest-courses",
        component: ListOfCourses,
      },
      {
        path: "ondemand-courses",
        name: "guest-ondemand-courses",
        component: OndemandCourses,
      },
      {
        path: "test",
        name: "guest-test",
        component: QuizList,
      },
      {
        path: "support",
        name: "guest-support",
        component: Support,
      },
    ],
    component: GuestDashboard,
  },
  {
    path: "/user/my-profile",
    name: "my-profile",
    meta: {
      requiresAuth: true,
    },
    component: MyProfile,
  },
  {
    path: "/course/:id/video/:videoId",
    name: "live-video-page",
    props: true,
    meta: {
      requiresAuth: true,
    },
    component: VideoPage,
  },
  {
    path: "/ondemand-courses/:id",
    name: "ondemand-video-page",
    props: true,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "video_lesson/:resourceId",
        name: "ondemand-video-lesson",
        component: OndemandVideoPlayer,
      },
      {
        path: "prog_exercise/:resourceId",
        name: "ondemand-prog-exercise",
        component: OndemandProgExercise,
      },
      {
        path: "assessment/:resourceId",
        name: "ondemand-assessment",
        component: OndemandQuiz,
      },
    ],
    component: OndemandVideoPage,
  },
  {
    path: "/waiting",
    component: WaitingApproval,
    name: "waiting-approval",
    meta: {
      accessible: true,
    },
  },

  {
    path: "/pending-classroom",
    name: "pending-classroom",
    component: PendingClassroom,
  },
  {
    path: "/error",
    name: "error",
    component: ErrorPage,
    meta: {
      accessible: true,
    },
  },
  {
    path: "/401",
    name: "401",
    component: ErrorUnauthorized,
    meta: {
      accessible: true,
    },
  },
  {
    path: "*",
    name: "NotFound",
    meta: {
      accessible: true,
    },
    component: ErrorPage,
  },
];

export default routes;
