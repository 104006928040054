<template>
  <v-container>
    <v-row>
      <v-col cols="12" :class="$vuetify.breakpoint.mdAndUp ? 'px-0' : ''">
        <h2 class="support-title pl-0">Supporto</h2>
      </v-col>
      <v-col
        cols="12"
        class="mb-5"
        :class="$vuetify.breakpoint.mdAndUp ? 'px-0' : ''"
      >
        <h4 class="font-weight-light white--text">
          Hai bisogno di aiuto? Sei nel posto giusto! I nostri tecnici ti
          risponderanno il prima possibile.
        </h4>
        <h4 class="font-weight-light white--text">
          Se non l'hai già fatto, ricordati di inserire una e-mail personale
          nella tua area profilo in modo da ricevere una risposta anche lì.
        </h4>
      </v-col>
    </v-row>
    <v-row cols="12" lg="10" class="mt-2">
      <v-container
        class="support-card"
        :class="$vuetify.breakpoint.mdAndUp ? '' : 'mx-2'"
      >
        <h4 class="container-title pt-2 mb-2">Mail di supporto</h4>
        <v-row v-if="!sent">
          <v-col>
            <v-form v-model="form">
              <span class="white--text">Testo</span>
              <textarea
                v-model="text"
                class="mail-input mt-1"
                maxlength="2000"
                placeholder="Scrivi qui il messaggio"
                rows="10"
              />
              <span class="d-flex justify-end text-counter"
                >{{ text.length }}/2000</span
              >
              <span class="privacy-link">
                <a
                  href="https://www.sorint.com/privacy-policy/"
                  target="_blank"
                  rel="noopener"
                  class="mt-3"
                >
                  Clicca qui per leggere la privacy policy di Sorint.</a
                >
              </span>
              <v-checkbox
                :rules="[(v) => v || 'Campo obbligatorio']"
                dark
                color="#F39200"
              >
                <template #label>
                  <span class="privacy-checkbox-label"
                    >Ho letto e accetto la privacy policy</span
                  >
                </template>
              </v-checkbox>
              <v-row class="justify-end pa-2">
                <v-btn
                  :disabled="!form || text.length === 0"
                  :loading="loading"
                  color="#F39200"
                  dark
                  class="send-btn mb-2 mr-1"
                  min-height="45px"
                  @click="submitNow"
                >
                  <img
                    src="@assets/landingPage/send.svg"
                    alt="send"
                    class="mr-3"
                  />
                  <span class="send-text">
                    {{ sent ? "Messaggio inviato!" : "Invia Messaggio" }}
                  </span>
                </v-btn>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col>
            <v-alert outlined type="success" text>
              Messaggio inoltrato con successo
            </v-alert>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
  </v-container>
</template>

<script>
import services from "@services/contactUs";
import userHandler from "@mixins/userHandler";
import { handleAxiosError } from "@utils/notifications";

export default {
  name: "Support",
  mixins: [userHandler],
  data: () => ({
    text: "",
    form: false,
    sent: false,
    loading: false,
    rules: {
      required: (value) => Boolean(value) || "Valore richiesto",
      min: (v) => v.length >= 4 || "Minimo 4 caratteri" /* have to change */,
      email: (v) =>
        !v ||
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          v
        ) ||
        "E-mail must be valid",
    },
    socials: [
      {
        icon: "mdi-facebook",
        color: "indigo",
        url: "https://www.facebook.com/sorintlab/",
      },
      {
        icon: "mdi-linkedin",
        color: "cyan darken-1",
        url: "https://www.linkedin.com/company/283815?trk=tyah&amp;trkInfo=clickedVertical%3Acompany%2CclickedEntityId%3A283815%2Cidx%3A3-1-3%2CtarId%3A1474846954328%2Ctas%3Asorint",
      },
      {
        icon: "mdi-instagram",
        color: "red lighten-3",
        url: "",
      },
    ],
  }),

  methods: {
    async submitNow() {
      this.loading = true;
      try {
        await services.contactUs(this.userEmail, this.text);
        this.sent = true;
      } catch (err) {
        await handleAxiosError(`Errore invio messaggio`, err);
        this.$logger(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}
.camingo {
  font-family: "camingo", sans-serif;
}
.support-title {
  font-weight: 700;
  font-size: 48.83px;
  line-height: 50px;
  letter-spacing: 1px;
  color: #ffffff;
}
.container-title {
  font-size: 24px;
  line-height: 28px;
  color: #ffffff;
}
.support-card {
  background: linear-gradient(
      109.46deg,
      rgba(1, 0, 46, 0.3) 1.57%,
      rgba(1, 0, 46, 0.3) 100%
    ),
    linear-gradient(
      109.46deg,
      rgba(201, 201, 201, 0.3) 1.57%,
      rgba(196, 196, 196, 0.05) 100%
    ) !important;
  backdrop-filter: blur(30px);
  border-radius: 12px;
}
.mail-input {
  padding: 12px 16px;
  border: 1px solid #e8ebed;
  border-radius: 13px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.75px;
  color: #5a6972;
  width: 100%;
}
.privacy-link a,
.privacy-link a:visited,
.privacy-link a:active,
.privacy-link a:focus-within,
.privacy-link a:focus,
.privacy-link a:focus-visible {
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 500;
  display: inline-block;
  font-size: 12px;
  line-height: 28px;
  letter-spacing: 0.75px;
  text-decoration-line: underline;
  color: #f4f5f6 !important;
}
.privacy-checkbox-label {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.75px;
  color: #e0e0e0;
}
.send-btn {
  background: #f39200;
  border-radius: 12px;
}
.send-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #f2f2f2;
  text-transform: capitalize;
}
.text-counter {
  color: #5a6972;
}
</style>
