export const useValidator = () => {
    const validatePassword = (password) => {
        const template = "La password deve ";
        let errors = [];
        let isValid = true;
        let numUppercaseChars = 0;
        let haveNumbers = false;
        let haveSpecialChars = false;
        let haveWhiteSpace = false;
    
        password.split("").map((char) => {
          if (char.match(/[A-Z]/)) numUppercaseChars++;
          if (char.match(/[0-9]/)) haveNumbers = true;
          if (!char.match(/[a-zA-Z0-9]/)) haveSpecialChars = true;
          if (char.match(" ")) haveWhiteSpace = true;
        });
    
        isValid =
          numUppercaseChars >= 2 &&
          haveNumbers &&
          haveSpecialChars &&
          password.length >= 10 &&
          !haveWhiteSpace;
    
        if (!isValid) {
          if (password.length < 10) {
            errors.push("essere lunga minimo 10 caratteri");
          }
          if (numUppercaseChars < 2) {
            errors.push("contenere minimo 2 lettere maiuscole");
          }
          if (!haveNumbers) {
            errors.push("contenere minimo 1 numero");
          }
          if (!haveSpecialChars) {
            errors.push("contenere minimo 1 carattere speciale");
          }
          if (haveWhiteSpace) {
            errors.push("non deve contenere spazi vuoti");
          }
        }
    
        return {
          isValid,
          message: isValid
            ? ""
            : errors.length === 1 && haveWhiteSpace
              ? "La password " + errors.join()
              : template + errors.join(", \n"),
        };
    };

    return { validatePassword }
}