<template>
  <div
    class="secondary-menu-card"
    :class="{ isHoverable: isOpen }"
    @click="isOpen ? navigateTo() : emitId()"
  >
    <img
      src="@assets/platform-home-page/secondary-menu-icon.webp"
      alt="secondary menu card img"
      class="card-img"
    />
    <div class="inner-secondary-menu-card pl-5 pt-8 pr-15">
      <h4 class="secondary-content">
        {{ text }}
      </h4>
      <h2 class="secondary-title">
        {{ title }}
      </h2>
    </div>
  </div>
</template>

<script>
import matomoTracker from "@mixins/matomoTracker";
import { pushJourneyUrl } from "@/utils/externalRouter";

export default {
  name: "PlatformHomePageSecondaryMenuCard",
  mixins: [matomoTracker],
  props: {
    id: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    routeName: {
      type: String,
      default: "",
    },
    isOpen: {
      type: Boolean,
    },
  },
  computed: {
    urlPrefix() {
      if (this.isTeacher) {
        return "teacher";
      } else if (this.isStudent) {
        return "student";
      } else if (this.isAdmin) {
        return "admin";
      } else if (this.isGuest) {
        return "guest";
      } else if (this.isSorint) {
        return "sorint";
      }
      return "";
    },
  },
  methods: {
    async navigateTo() {
      await this.trackEvent(
        "PlatformHomePage",
        `ClickOnSecondaryMenuCard${this.title.toUpperCase()}`
      );

      if (this.id == "classes") {
        const token = JSON.parse(localStorage.getItem("user"));
        pushJourneyUrl("/classroom-management");
        return;
      }

      this.$router.push({ name: `${this.urlPrefix}-${this.routeName}` });
    },
    emitId() {
      this.$emit("onSectionClosed", this.id);
    },
  },
};
</script>

<style scoped>
.secondary-menu-card {
  position: relative;
  width: 343px;
  height: 223px;
  cursor: pointer;
}
.card-img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: contain;
}
.secondary-title {
  font-family: "outerorbit", "Orbitron";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;

  /* Grey/100 */

  color: #f4f5f6;
  text-transform: uppercase;
  position: absolute;
  bottom: 45px;
  left: 25px;
}
.secondary-content {
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  /* Grey/300 */

  color: #c6ced2;
  padding-right: 10%;
}
.inner-secondary-menu-card {
  position: absolute;
  height: 100%;
}
.isHoverable {
  transition: all 0.5s;
}
.isHoverable:hover {
  -webkit-filter: drop-shadow(0 0 300px #f3920090);
  filter: drop-shadow(0 0 300px #f3920090);
}
</style>
