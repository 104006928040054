<template>
  <Modal
    :glassify="true"
    :title="examResult.passed ? 'CONGRATULAZIONI!' : 'PECCATO!'"
    @closeModal="close"
  >
    <template #content>
      <v-container>
        <v-row
          class="pt-2"
          justify="center"
        >
          <v-col
            align="center"
            cols="12"
            md="4"
          >
            <v-img
              v-if="examResult.passed"
              max-height="150"
              width="fit-content"
              contain
              src="@/assets/exam/success.webp"
              alt="astronaut"
            />
            <v-img
              v-if="!examResult.passed"
              max-height="150"
              width="fit-content"
              contain
              src="@/assets/exam/failure.webp"
              alt="crash"
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
            align="center"
            class="mt-10"
          >
            <div
              class="result-wrapper"
              :class="examResultBackground"
            >
              <span class="result-content">{{ examResult.result }}/{{ examResult.max_points }}</span>
            </div>
          </v-col>
        </v-row>
        <v-row
          id="result_message"
          justify="center"
          :class="{
            'pt-2 text-h5 font-weight-bold': true,
            'green--text': examResult.passed,
            'red--text': !examResult.passed,
          }"
        >
          {{
            examResult.passed
              ? "HAI SUPERATO IL TEST!"
              : "NON HAI RAGGIUNTO IL PUNTEGGIO NECESSARIO PER SUPERARE IL TEST"
          }}
        </v-row>
        <v-row
          id="result_action"
          justify="center"
          class="pt-2"
        >
          {{
            examResult.passed
              ? "Nell'area REPORT puoi scaricare il tuo attestato di superamento della prova e il badge Credly."
              : "Ritorna alla lista test per vedere se ci sono altre sessioni in programma nei prossimi giorni per questo test."
          }}
        </v-row>
        <v-row
          justify="center"
          class="pt-6"
          no-gutters
        >
          <v-btn
            id="close_button"
            large
            dark
            color="green accent-4"
            class="bit-text ma-4"
            @click="close"
          >
            {{
              examResult.passed
                ? "VAI AI REPORT"
                : "RITORNA ALLA LISTA DEI TEST"
            }}
          </v-btn>
        </v-row>
      </v-container>
    </template>
  </Modal>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import Modal from "@cmp-shared/utils/Modal";

export default {
  name: "QuizResultModal",
  components: {
    Modal,
  },
  computed: {
    ...mapGetters("exam", {
      examResult: "result",
    }),
    examResultBackground() {
      if(this.examResult.passed) {
        return "green-background"
      }
      return "red-background"
    }
  },
  methods: {
    ...mapMutations("toolbar", ["changeTab"]),
    close() {
      this.changeTab(this.examResult.passed ? 2 : 3);
      this.examResult.passed ? this.$router.push("/student/report") : this.$router.push("/student/test");
    },
  },
};
</script>

<style scoped>
#result_message {
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  /* identical to box height, or 160% */

  text-align: center;

  /* Success/500 */

  color: #59d657;
}

.result-wrapper{
  width: 113px;
  height: 79px;
  display: flex;
  border-radius: 40px;
  justify-content: center;
  align-items: center;
}

.result-content{
  font-weight: 500;
  font-size: 32px;
  line-height: 39px;
  color: #FFFFFF;
}

.green-background{
  background: #59D657;
}

.red-background{
  background: #FC4955;
}
</style>
