import api from "@services/axiosInstance";

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/swagger/index.html#/Classroom/post_classroom
 */
function addNewClassroom(name) {
  return api.post("/classroom", {
    Name: name,
  });
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/swagger/index.html#/Classroom/get_classroom_prof
 */
function getClassroomsProf() {
  return api.get("/classroom/prof");
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/swagger/index.html#/Classroom/get_classroom_students__classroomid_
 */
function getClassroomStudents(id) {
  return api.get(`/classroom/students/${id}`);
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/swagger/index.html#/Classroom/delete_classroom
 */
function deleteClassroom(id) {
  return api.delete(`/classroom?classroomid=${id}`);
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/Classroom/put_classroom_rename
 */
function renameClassroom(classroomid, name) {
  return api.put("/classroom/rename", {
    classroomid,
    name,
  });
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/swagger/index.html#/Classroom/patch_classroom_remove
 */
function removeStudent(classroomId, studentId) {
  return api.patch(
    `/classroom/remove?classroomid=${classroomId}&student=${studentId}`
  );
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/swagger/index.html#/Classroom/delete_classroom_pending
 */
function removePendingStudent(classroomId, studentEmail) {
  return api.delete(
    `/classroom/pending?classroomid=${classroomId}&email=${studentEmail}`
  );
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/swagger/index.html#/Classroom/post_classroom_student
 */
function addStudent(email, classroomId) {
  return api.post("/classroom/student", {
    Classroomid: classroomId,
    Email: email,
  });
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/swagger/index.html#/Classroom/post_classroom__classroom_id__upload_student
 */
function uploadStudents(file, classroomId) {
  let formData = new FormData();
  formData.append("file", file);

  return api.post(`/classroom/${classroomId}/upload-student`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/swagger/index.html#/Classroom/post_classroom_confirm_invite
 */
function confirmInvite() {
  return api.post(`/classroom/confirm-invite`);
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/swagger/index.html#/Classroom/post_classroom_reject_invite
 */
function rejectInvite() {
  return api.post(`/classroom/reject-invite`);
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/swagger/index.html#/Classroom/get_classroom_pending
 */
function getPendingClassroom() {
  return api.get("/classroom/pending");
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/swagger/index.html#/Classroom/get_classroom_check_student
 */
function checkStudent(email) {
  return api.get(`/classroom/check-student?email=${email}`);
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/swagger/index.html#/Courses/put_quiz_subscribe
 */
function subscribeQuiz(classroomID, quizID) {
  return api.put("/quiz/subscribe", {
    classroomID,
    quizID,
  });
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/swagger/index.html#/Courses/put_quiz_unsubscribe
 */
function unsubscribeQuiz(classroomID, quizID) {
  return api.put("/quiz/unsubscribe", {
    classroomID,
    quizID,
  });
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/Learning%20path/get_learning_paths__id__classroom
 */
function getClassroomsByLpId(learningPathId) {
  return api.get(`/learning-paths/${learningPathId}/classrooms`);
}

export default {
  addNewClassroom,
  deleteClassroom,
  renameClassroom,
  removeStudent,
  removePendingStudent,
  addStudent,
  uploadStudents,
  getClassroomStudents,
  getClassroomsProf,
  confirmInvite,
  rejectInvite,
  getPendingClassroom,
  checkStudent,
  subscribeQuiz,
  unsubscribeQuiz,
  getClassroomsByLpId,
};
