<template>
  <v-row :id="`msg-list-${channelId}`" class="chat-msg mx-1" @scroll="onScroll">
    <v-col v-if="messages.length > 0">
      <transition-group name="opacity">
        <div
          v-for="(message, i) in messages"
          :key="`message-${i}`"
          class="my-3"
        >
          <div
            v-if="
              i === 0 ||
              message.ts.getDate() !== messages[i - 1].ts.getDate() ||
              message.ts.getMonth() !== messages[i - 1].ts.getMonth()
            "
            class="d-flex justify-center"
          >
            <span
              class="my-5 text-subtitle-2 text-center justify-center px-4 py-1 rounded-pill white--text chat-date"
            >
              {{
                `${message.ts.getDate()} ${message.ts.toLocaleString(
                  "default",
                  { month: "long" }
                )}`
              }}
            </span>
          </div>
          <span
            v-if="
              message.u._id !== userId &&
              (i === 0 || message.u.name !== messages[i - 1].u.name)
            "
            class="d-block text-caption"
            :class="message.u._id === userId ? 'text-right' : 'text-left'"
          >
            {{ message.u.name }}
          </span>
          <div
            v-if="message.u._id === userId"
            class="d-flex flex-column msg-text-wrapper align-end"
          >
            <v-tooltip v-model="message.show_time" left>
              <template #activator="{ on, attrs }">
                <div
                  class="text-body-2 d-block pa-3 chat-personal-message"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ message.msg }}
                </div>
              </template>
              <span>
                {{
                  `${pad(message.ts.getHours())}:${pad(
                    message.ts.getMinutes()
                  )}`
                }}
              </span>
            </v-tooltip>
          </div>
          <div v-else class="d-flex flex-column msg-text-wrapper align-start">
            <v-tooltip v-model="message.show_time" right>
              <template #activator="{ on, attrs }">
                <div
                  class="text-body-2 d-block pa-3 msg"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ message.msg }}
                </div>
              </template>
              <span>{{
                `${pad(message.ts.getHours())}:${pad(message.ts.getMinutes())}`
              }}</span>
            </v-tooltip>
          </div>
        </div>
      </transition-group>
    </v-col>
    <!-- NESSUN MESSAGGIO -->

    <v-col v-else>
      <span class="text-caption d-block text-center">Nessun messaggio</span>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "MessageList",
  props: {
    messages: {
      type: Array,
      required: true,
    },
    channelId: {
      type: String,
      required: true,
    },
    userId: {
      type: String,
      required: true,
    },
    wantsToReadMessages: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["onWantsToReadMessages"],
  data() {
    return {
      preScrollTop: 0,
    };
  },
  watch: {
    messages() {
      this.$logger(this.messages.at(-1));
      if (!this.wantsToReadMessages) {
        this.scrollMsg("smooth");
      }
    },
    wantsToReadMessages(newVal, oldVal) {
      if (oldVal !== newVal && oldVal) {
        this.scrollMsg("smooth");
      }
    },
  },
  methods: {
    pad(n) {
      return `0${n}`.slice(-2);
    },
    scrollMsg(scrollMode) {
      setTimeout(() => {
        const elmnt = document.getElementById(`msg-list-${this.channelId}`);
        if (scrollMode === "smooth") {
          elmnt.scrollTo({
            top: elmnt.scrollHeight,
            behavior: "smooth",
          });
        } else {
          elmnt.scrollTop = elmnt.scrollHeight;
        }
      }, 0);
    },
    onScroll({ target: { scrollTop } }) {
      if (scrollTop < this.preScrollTop && !this.wantsToReadMessages) {
        this.$emit("onWantsToReadMessages", true);
      }
      this.preScrollTop = scrollTop;
    },
  },
};
</script>

<style>
.chat-date {
  background: linear-gradient(
    109.46deg,
    rgba(201, 201, 201, 0.4) 1.57%,
    rgba(196, 196, 196, 0.1) 100%
  );
}
.chat-msg {
  overflow-y: auto;
  height: calc(100% - 150px);
  width: 100%;
}

.msg {
  background: #7fbaf4 !important;
  color: white !important;
  border-radius: 10px;
  padding: 5px;
  max-width: 70%;
}

.chat-personal-message {
  background: linear-gradient(
    109.46deg,
    rgba(201, 201, 201, 0.4) 1.57%,
    rgba(196, 196, 196, 0.1) 100%
  );
  color: white;
  border-radius: 12px;
  padding: 5px;
  max-width: 70%;
}

.msg-text-wrapper {
  width: 100%;
}
</style>
