<template>
  <v-col
    cols="12"
    class="pa-0 d-flex justify-end"
  >
    <v-icon
      left
      color="#98A6AE"
    >
      mdi-clock-outline
    </v-icon>
    <span id="countdown" />
  </v-col>
</template>

<script>
export default {
  name: "OndemandQuizTimer",
  props: {
    seconds: {
      type: Number,
      required: true,
    }
  },
  data: () => ({
    time: 0,
    countdownEl: {},
  }),
  mounted() {
    this.time = this.seconds;
    this.countdownEl = document.getElementById("countdown");
    setInterval(this.updateCountdown, 1000);
  },
  methods: {
    updateCountdown() {
      const minutes = Math.floor(this.time / 60);
      const secs = this.time % 60;
      this.countdownEl.innerHTML = `${minutes} : ${this.currentSeconds(secs)}`;
      this.time--;
    },
    currentSeconds(value){
      if(value < 10) {
        return `0${value}`;
      }
      return value;
    }
  },
}
</script>

<style scoped>
#countdown{
  margin-top: 2px !important;
}
</style>