<template>
  <v-card
    class="glassify rounded-lg"
    :class="{'fill-height': expanded}"
  >
    <v-col
      cols="12"
      align="center"
      class="my-8"
    >
      <h2 class="mt-4 mb-8 card-title">
        {{ title }}
      </h2>
      <img
        class="introduction-img mb-5"
        :alt="imgName"
        :src="img"
      >
      <slot name="content" />
    </v-col>
  </v-card>
</template>

<script>
export default {
  name: "IntroductionCard",
  props: {
    title: {
      type: String,
      required: true,
    },
    imgName: {
      type: String,
      required: true,
    },
    expanded:{
      type: Boolean,
    }
  },
  computed: {
    img() {
      return require(`@/assets/exam/introduction/${this.imgName}`);
    },
  },
};
</script>

<style>
.introduction-img {
  width: 270px;
  object-fit: contain;
}
.card-title{
  font-family: 'outerorbit';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  color: #F4F5F6;
}
</style>