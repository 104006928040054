<template>
  <v-container class="pt-0">
    <h1
      class="section-title mb-2"
      :class="$vuetify.breakpoint.mdAndDown ? 'text-center' : 'text-left'"
    >
      Ondemand
    </h1>
    <v-card
      v-for="ondemand in ondemandCourses"
      :key="ondemand.id"
      class="course-wrapper"
    >
      <v-card-text class="py-3 pl-3 mb-3">
        <v-row no-gutters>
          <v-col cols="12" sm="5" md="3" lg="2" align="center">
            <v-img
              :src="ondemand.thumbnail_url"
              class="course-img"
              alt="course image"
              :class="{
                'course-img-mobile': $vuetify.breakpoint.smAndDown,
              }"
            />
          </v-col>
          <v-col
            cols="12"
            sm="7"
            md="9"
            lg="10"
            :class="$vuetify.breakpoint.smAndUp ? 'pl-5' : 'pt-2'"
            class="info-container"
          >
            <v-row v-if="isStudent" no-gutters align="start">
              <v-icon left small color="#98A6AE">
                mdi-layers-triple-outline </v-icon
              ><span class="course-top-info mr-4"
                >{{ ondemand.progress_info.num_resources }} risorse</span
              >
              <v-icon left small color="#98A6AE"> mdi-clock-outline </v-icon
              ><span class="course-top-info mr-4"
                >{{ ondemand.internship_minutes / 60 }}h di PCTO</span
              >
            </v-row>
            <h4 class="course-title py-1">
              {{ ondemand.title }}
            </h4>
            <p class="course-paragraph mb-0">
              {{ ondemand.description }}
            </p>
            <v-row no-gutters align="end">
              <v-col
                v-for="(author, index) in ondemand.authors"
                :key="index"
                cols="12"
                md="4"
              >
                <p class="author-name mb-0">
                  {{ getAuthor(author) }}
                </p>
                <p class="author-role mb-2">
                  {{ getRole(author) }}
                </p>
              </v-col>
              <v-col class="d-flex justify-end mb-2">
                <v-btn
                  color="#F39200"
                  class="white--text course-button rounded-lg"
                  :width="$vuetify.breakpoint.smAndDown ? '100%' : ''"
                  @click="() => openCourse(ondemand)"
                >
                  <v-icon left size="24"> mdi-arrow-top-right </v-icon>
                  Apri corso
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog max-width="800px" v-model="showTJDialog">
      <v-card class="tj-modal-card d-flex flex-column align-center">
        <v-card-title class="tj-modal-title d-flex justify-center"
          >Reindirizzamento alla Tech Journey</v-card-title
        >
        <v-card-text class="tj-modal-text d-flex flex-column align-center">
          <v-img
            max-height="275"
            contain
            src="@assets/rocket_goto_tj.webp"
          ></v-img>
          Cliccando su “VAI ALLA TJ” verrai rimandato nello stesso corso
          all’interno della Tech Journey
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            class="tj-modal-btn rounded-lg"
            @click="() => redirectToTechJourney()"
          >
            Vai alla TJ
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog width="974px" v-model="showNewLookDialog">
      <v-card class="tj-modal-card d-flex flex-column align-center">
        <v-card-title class="tj-modal-title d-flex justify-center mb-8"
          >un<span class="item-clicked px-1">nuovo look</span>per un futuro
          ancora più spaziale!</v-card-title
        >
        <v-card-text
          class="tj-modal-text d-flex flex-column align-center mb-8"
          :style="
            $vuetify.breakpoint.smAndUp ? 'font-size: 20px' : 'font-size: 16px'
          "
        >
          <v-img width="725" contain src="@assets/tj_sponsor.webp"></v-img>

          <p class="mt-8 mb-10">
            Preparati a un'esperienza di apprendimento tutta nuova!
          </p>
          <p class="mb-10">
            Da settembre HackersGen avrà un
            <span class="font-weight-bold"> nuovo look</span>, con un design
            rinnovato e tante funzionalità dedicate al tuo futuro.
          </p>
          <div class="mb-10">
            <p class="mb-0">Cosa ti aspetta?</p>
            <p class="mb-0">
              <span class="item-clicked font-weight-bold"
                >Percorsi formativi</span
              >
              personalizzati per raggiungere i tuoi obiettivi.
            </p>
            <p class="mb-0">
              <span class="item-clicked font-weight-bold">Career path</span> per
              delineare il tuo percorso professionale nel mondo
              dell'informatica.
            </p>
            <p class="mb-0">
              <span class="item-clicked font-weight-bold">Risorse live</span>
              per interagire con esperti e rimanere aggiornato sulle ultime
              novità del settore.
            </p>
          </div>
          <p class="mb-0">
            Non perdere le novità: resta connesso per scoprire di più!
          </p>
        </v-card-text>

        <v-card-actions class="d-flex justify-center">
          <v-btn
            class="tj-modal-btn rounded-lg text-uppercase"
            @click="() => (showNewLookDialog = false)"
          >
            <v-icon class="mr-1"> mdi-check </v-icon>
            ho capito!
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import matomoTracker from "@mixins/matomoTracker";
import userHandler from "@mixins/userHandler";
import { sendNotification } from "@/utils/notifications";
import { pushJourneyUrl } from "@/utils/externalRouter";

export default {
  name: "OndemandCourse",
  mixins: [matomoTracker, userHandler],
  data: () => ({
    ondemandCourses: [],
    showTJDialog: false,
    selectedTechJourneyId: "",
    showNewLookDialog: false,
  }),
  async mounted() {
    this.ondemandCourses = await this.getOndemandCourses();
    /* const newLookTimeStamp = localStorage.getItem("new-look-last-popup");

    if (newLookTimeStamp) {
      const THIRTY_DAYS = 2592000000;
      if (Date.now() - newLookTimeStamp > THIRTY_DAYS) {
        localStorage.removeItem("new-look-last-popup");
        this.showNewLookDialog = true;
      }
    } else if (!newLookTimeStamp) {
      localStorage.setItem("new-look-last-popup", Date.now());
      this.showNewLookDialog = true;
    } */
  },
  methods: {
    ...mapActions("learningPaths", ["getOndemandCourses", "getOndemandById"]),
    getAuthor(item) {
      return item.split("%?%")[0];
    },
    getRole(item) {
      return item.split("%?%")[1];
    },
    async navigateTo(routeName, OnDemandId) {
      await this.trackEvent(
        "PlatformOnDemandPage",
        "ClickOnOpenOnDemandCourse"
      );
      const lastResource = await this.getPathInfo(OnDemandId);

      if (lastResource) {
        this.$router.push(
          `/ondemand-courses/${OnDemandId}/${lastResource.type}/${lastResource.id}`
        );
      }
    },
    async getPathInfo(ondemandId) {
      const data = await this.getOndemandById({ id: ondemandId });
      if (data.sections) {
        if (!this.isStudent) {
          this.$logger("non sono uno studente");
          return data.sections[0].resources[0];
        }

        const isLastResourceCompleted = data.sections
          .at(-1)
          .resources.at(-1).completed;
        if (isLastResourceCompleted) {
          this.$logger(
            "ultima delle risorse completata - redirect alla prima risorsa"
          );
          return data.sections[0].resources[0];
        }
        const currentResource = data.sections
          .map((s) => s.resources.filter((r) => r.completed))
          .flat()
          .at(-1);
        if (!currentResource) {
          this.$logger("prima volta nel corso - redirect alla prima risorsa");
          return data.sections[0].resources[0];
        }
        this.$logger("ultima risorsa che ho completato");
        return currentResource;
      } else {
        sendNotification(`Questo corso non contiene risorse`, "error");
      }
    },
    async openCourse(courseData) {
      /* if (courseData?.tj_entity_id) {
        this.selectedTechJourneyId = courseData?.tj_entity_id;
        this.showTJDialog = true;
        return;
      } */

      this.navigateTo("ondemand-video-page", courseData.id);
    },
    redirectToTechJourney() {
      pushJourneyUrl(`/module-detail/${this.selectedTechJourneyId}`);
    },
  },
};
</script>

<style scoped>
.section-title {
  font-weight: 700;
  font-size: 48.83px;
  line-height: 50px;
  letter-spacing: 1px;
  color: #ffffff;
}

.item-clicked {
  color: #f39200;
}

.course-wrapper {
  background: linear-gradient(
      109.46deg,
      rgba(1, 0, 46, 0.3) 1.57%,
      rgba(1, 0, 46, 0.3) 100%
    ),
    linear-gradient(
      109.46deg,
      rgba(201, 201, 201, 0.3) 1.57%,
      rgba(196, 196, 196, 0.05) 100%
    ) !important;
  backdrop-filter: blur(30px);
  border-radius: 12px;
  color: #ffffff;
}

.course-img {
  border-radius: 12px;
  width: 197px;
  height: 157;
  object-fit: contain;
  object-position: center;
}

.course-img-mobile {
  width: 100%;
}

.info-container {
  display: flex;
  flex-direction: column;
}

.course-top-info {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #98a6ae;
}

.course-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #ffffff;
}

.course-paragraph {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #98a6ae;
}

.author-name {
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  color: #f4f5f6;
}

.author-role {
  font-style: italic;
  font-weight: 400;
  font-size: 10px;
  color: #98a6ae;
}

.course-button {
  text-transform: none;
  font-size: 16px;
  font-weight: 400;
}

.tj-modal-card {
  padding: 32px;
  background: var(
    --Glass-Morphism,
    linear-gradient(109deg, rgba(201, 201, 201, 0.4), rgba(196, 196, 196, 0.1))
  );
  backdrop-filter: blur(4px);
}

.tj-modal-title {
  color: var(--grey-100, #f4f5f6);
  text-align: center;
  font-family: outerorbit;
  font-style: normal;
  line-height: normal;
  text-transform: uppercase;
}

.tj-modal-text {
  color: var(--grey-100, #f4f5f6) !important;
  text-align: center;
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}

.tj-modal-btn {
  color: var(--Gray-6, #f2f2f2);
  text-align: center;
  font-family: Karasuma Gothic;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background-color: #f3900e !important;
}

.v-dialog > .v-card > .v-card__text {
  padding: 0;
}

.v-dialog > .v-card > .v-card__title {
  font-size: 24px;
}
</style>
