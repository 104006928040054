var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-col',{staticClass:"px-4 pb-4",attrs:{"cols":"12"}},[_c('h2',{staticClass:"quiz-title mb-5"},[_vm._v(" Regolamento test: "+_vm._s(_vm.quizTitle)+" ")]),_c('div',{staticClass:"pa-3 orange--text orange-border"},[_c('v-icon',{attrs:{"color":"#F39200"}},[_vm._v(" mdi-information-outline ")]),_vm._v(" Prima di procedere assicurati di avere il tempo sufficiente per tentare il test. Una volta aperto non sarà più possibile tornare indietro. ")],1),_c('v-row',{attrs:{"no-gutters":"","justify":"space-between"}},[_c('v-col',{staticClass:"pa-0 mb-5",class:{
        'pr-5': _vm.$vuetify.breakpoint.lgAndUp,
      },attrs:{"lg":"4","cols":"12"}},[_c('IntroductionCard',{attrs:{"title":'SVOLGIMENTO',"img-name":'rules.webp',"expanded":true},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('v-col',{staticClass:"white--text pa-0 px-10 card-paragraph",attrs:{"cols":"12"}},[_c('div',{staticClass:"mb-5"},[_c('div',{staticClass:"mb-2"},[_c('strong',[_vm._v("Quanti tentativi ho?")])]),_c('span',[_vm._v(" Puoi tentare il test 1 volta per sessione. ")])]),_c('div',{staticClass:"mb-5"},[_c('div',{staticClass:"mb-2"},[_c('strong',[_vm._v("Quanto tempo ho a disposizione per fare il test?")])]),_c('span',[_vm._v(" Varia per ogni test, controlla il tempo a disposizione all'interno del box specifico di ogni test. ")])]),_c('div',{staticClass:"mb-5"},[_c('div',{staticClass:"mb-2"},[_c('strong',[_vm._v("Cosa succede se il tempo a disposizione scade?")])]),_c('span',[_vm._v(" Se il tempo scade e non hai completato tutte le domande, verranno considerate le risposte date fino all'ultimo salvataggio automatico del sistema. Le risposte non date sono calcolate con 0 punti. ")])]),_c('div',[_c('div',{staticClass:"mb-2"},[_c('strong',[_vm._v("Cosa succede se va in crash il browser o il mio dispositivo?")])]),_c('span',[_vm._v(" Puoi riprendere il test dall'ultimo salvataggio automatico fatto dal sistema, se avrai ancora tempo a disposizione. Una volta avviato il test, il tempo continuerà a scalare anche in caso di disconnessione. ")])])])]},proxy:true}])})],1),_c('v-col',{staticClass:"pa-0 mb-5",class:{
        'px-5': _vm.$vuetify.breakpoint.lgAndUp,
      },attrs:{"lg":"4","cols":"12"}},[_c('IntroductionCard',{attrs:{"title":'PUNTEGGIO',"img-name":'score.webp',"expanded":true},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('v-col',{staticClass:"white--text pa-0 px-10 card-paragraph",attrs:{"cols":"12"}},[_c('div',{staticClass:"mb-5"},[_c('div',{staticClass:"mb-2"},[_c('strong',[_vm._v("Come funziona il calcolo del punteggio?")])]),_c('ul',[_c('li',[_c('div',{staticClass:"mb-2"},[_vm._v(" Risposta/e corretta/e = assegnati tutti i punti associati a quella domanda ")])]),_c('li',[_c('div',{staticClass:"mb-2"},[_vm._v(" Risposta/e errata / almeno una risposta errata in una domanda a risposta multipla = -1 punto ")])]),_c('li',[_c('div',[_vm._v(" Risposta "),_c('strong',[_vm._v("non data")]),_vm._v(" = 0 punti ")])])])]),_c('div',{staticClass:"mb-5"},[_c('div',{staticClass:"mb-2"},[_c('strong',[_vm._v("Se supero il test posso rifarlo per ottenere un punteggio superiore?")])]),_c('span',[_vm._v(" No, non potrai rifarlo ")])]),_c('div',[_c('div',{staticClass:"mb-2"},[_c('strong',[_vm._v("Se non supero il test, posso rifarlo?")])]),_c('span',[_c('strong',[_vm._v("Si,")]),_vm._v(" puoi ritentarlo "),_c('strong',[_vm._v("solo se ci sono altre sessioni")]),_vm._v(" in programma. ")])])])]},proxy:true}])})],1),_c('v-col',{staticClass:"pa-0 mb-5",class:{
        'pl-5': _vm.$vuetify.breakpoint.lgAndUp,
      },attrs:{"lg":"4","cols":"12"}},[_c('IntroductionCard',{attrs:{"title":'CERTIFICAZIONI E BADGE',"img-name":'certificate.webp'},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('v-col',{staticClass:"white--text pa-0 px-10 card-paragraph",attrs:{"cols":"12"}},[_c('div',{staticClass:"mb-5"},[_c('div',{staticClass:"mb-2"},[_c('strong',[_vm._v("Cosa ottengo se supero il test?")])]),_c('div',{staticClass:"mb-2"},[_vm._v(" Nella sezione Report puoi scaricare la certificazione e richiedere il badge Credly da condividere sui tuoi profili social. ")])]),_c('div',[_c('div',{staticClass:"mb-2"},[_c('strong',[_vm._v("Ci sono certificazioni speciali?")])]),_c('span',[_vm._v(" Si, ottieni una certificazione e un badge Credly speciali se al tuo primo tentativo totalizzi un punteggio che rientra nella soglia di eccellenza. ")])])])]},proxy:true}])}),_c('div',{staticClass:"px-3 my-5 mt-5"},[_c('v-row',{staticClass:"mt-8",attrs:{"justify":"center"}},[_vm._v(" Per ogni dubbio rivolgiti a:  "),_c('a',{staticClass:"orange--text text-decoration-underline",attrs:{"href":"\n                    mailto:'hackersgen@sorint.com'"}},[_vm._v(" hackersgen@sorint.com")])])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }