<template>
  <div
    class="primary-menu-card"
    :class="{ isHoverable: isOpen }"
    @click="isOpen ? navigateTo() : emitKey()"
  >
    <img
      v-if="title === 'Journey'"
      src="@assets/platform-home-page/tech-journey-icon.webp"
      alt="primary menu card img"
      class="card-img"
    />
    <img
      v-else
      src="@assets/platform-home-page/primary-menu-icon.webp"
      alt="primary menu card img"
      class="card-img"
    />
    <div class="inner-primary-menu-card pa-6">
      <h4 class="primary-content">
        {{ text }}
      </h4>
      <h2 :class="title === 'Journey' ? 'tech-title' : 'primary-title'">
        {{ title }}
      </h2>
    </div>
  </div>
</template>

<script>
import matomoTracker from "@mixins/matomoTracker";
import { pushJourneyUrl, pushProjectsUrl } from "@/utils/externalRouter";

export default {
  name: "PlatformHomePagePrimaryMenuCard",
  mixins: [matomoTracker],
  props: {
    id: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    routeName: {
      type: String,
      default: "",
    },
    isOpen: {
      type: Boolean,
    },
  },
  computed: {
    urlPrefix() {
      if (this.isTeacher) {
        return "teacher";
      } else if (this.isStudent) {
        return "student";
      } else if (this.isAdmin) {
        return "admin";
      } else if (this.isGuest) {
        return "guest";
      } else if (this.isSorint) {
        return "sorint";
      }
      return "";
    },
  },
  methods: {
    async navigateTo() {
      await this.trackEvent(
        "PlatformHomePage",
        `ClickOnPrimaryMenuCard${this.title.toUpperCase()}`
      );
      if (this.id == "journey") {
        const token = JSON.parse(localStorage.getItem("user"));
        pushJourneyUrl("/auth/sso", token.access_token);
        return;
      }
      if (this.id == "projects") {
        pushProjectsUrl("/");
        return;
      }

      this.$router.push({ name: `${this.urlPrefix}-${this.routeName}` });
    },
    emitKey() {
      this.$emit("onSectionClosed", this.id);
    },
  },
};
</script>

<style scoped>
.primary-menu-card {
  position: relative;
  width: 343px;
  height: 213px;
  cursor: pointer;
}

.card-img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: contain;
}

.primary-title {
  font-family: "outerorbit", "Orbitron";
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 37px;
  text-transform: uppercase;
  color: #7fbaf4;
  position: absolute;
  bottom: 25px;
  left: 25px;
}

.tech-title {
  font-family: "outerorbit", "Orbitron";
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 37px;
  text-transform: uppercase;
  color: #f39200;
  position: absolute;
  bottom: 25px;
  left: 25px;
}
.primary-content {
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #c6ced2;
  padding-right: 20%;
}
.inner-primary-menu-card {
  position: absolute;
  height: 100%;
}
.isHoverable {
  transition: all 0.5s;
}
.isHoverable:hover {
  -webkit-filter: drop-shadow(0 0 300px #f3920090);
  filter: drop-shadow(0 0 300px #f3920090);
}
</style>
