import reportService from "@services/reports";
import { handleAxiosError } from "@utils/notifications";
import { sendNotification } from "@/utils/notifications";

const actions = {
  async getReportByCourse({ commit }, { classroomId, courseId, fileName }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      const report = await reportService.getReportByCourse(
        classroomId,
        courseId,
      );
      const blob = new Blob([report.data]);
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = (e) => {
        const a = document.createElement("a");
        a.download = `${fileName}.xlsx`;
        a.href = e.target.result;
        a.click();
      };
    } catch (err) {
      await handleAxiosError(`Errore nello scaricamento del report`, err);
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
};

export default {
  namespaced: true,
  actions,
};
