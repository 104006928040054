import axios from "axios";
import store from "../store";
import { AUTH_ERROR } from "@/store/helpers";

/* eslint-disable */
const REFRESH_ENDPOINT = '/refresh'
const refreshStatusCode = 401 /* if BE uses another refresh Status Code change here */

const instance = axios.create({
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

instance.interceptors.request.use((config) => {
  const user = JSON.parse(localStorage.getItem('user'))
  if (user) {
    config.headers['X-Auth-Token'] = user.access_token
  }
  return config
})

instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    // Reject promise if usual error{
    if (!error.response.request.responseURL.includes(REFRESH_ENDPOINT)) {
      if (error.response.status !== refreshStatusCode) {
        return Promise.reject(error)
      }

      /*
       * When response code is 401, try to refresh the token.
       * Eject the interceptor so it doesn't loop in case
       * token refresh causes the 401 response
       */
      try {
        const token = await store.dispatch('auth/refreshToken')
        error.response.config.headers['X-Auth-Token'] = token
        return instance(error.response.config)
      } catch (err) {
        return Promise.reject(AUTH_ERROR)
      }
    } else {
      return Promise.reject(AUTH_ERROR)
    }
  }
)

export const setupPublicAxiosInstance = () => {
  instance.defaults.baseURL = store.getters['config/publicUrl']
}

export default instance
