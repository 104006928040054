<template>
  <v-container>
    <v-row
      justify="start"
      class="my-1"
    >
      <v-btn
        text
        color="transparent"
        @click="selectCourse('')"
      >
        <v-icon
          color="#ffffff"
          left
          size="22"
        >
          mdi-arrow-left
        </v-icon>
        <span
          class="back-to-courses"
        >Torna alla lista corsi</span>
      </v-btn>
    </v-row>
    <h5 class="card-title mb-2">
      Ondemand: {{ course.title }}
    </h5>
    <h6 class="card-subtitle">
      Visualizza lo stato degli esercizi, dei video e  dell’esito dei test dei tuoi studenti
    </h6>
    <DefaultTable
      v-if="!isLoading"
      :headers="headers"
      :store-name="'learningPaths'"
      :method="'getOndemandReportByClassId'"
      :allow-expand="true"
      :item-type="{ classId, learningPathId: course.id }"
    >
      <template #expanded-item="{item}">
        <td :colspan="12">
          <v-tabs
            v-model="tab"
            class="mt-5 mb-3"
            background-color="transparent"
            color="#F39200"
            hide-slider
            active-class="tab-selected"
            align-with-title
          >
            <v-tab
              v-for="(tab, index) in tabs"
              :key="index"
              :href="`#tab-${index}`"
              class="ma-0 tabs-title"
            >
              {{ tab }}
            </v-tab>
          </v-tabs>
          <v-tabs-items
            v-model="tab"
            class="transparent"
          >
            <v-tab-item
              v-for="(tab, index) in tabs"
              :key="index"
              :value="`tab-${index}`"
            >
              <v-row no-gutters>
                <v-col
                  v-for="resource in realResources(item.resources, tab)"
                  :key="resource.id"
                  cols="12"
                  md="4"
                  class="resource-label mb-2"
                >
                  <v-icon
                    v-if="tab === resource.section_name"
                    left
                    dark
                  >
                    {{ iconSelector(resource.type) }}
                  </v-icon>
                  <span v-if="tab === resource.section_name">
                    {{ resource.title }}:
                  </span>
                  <span
                    v-if="tab === resource.section_name"
                    class="resource-status"
                    :class="resource.completed ? 'green--text' : 'red--text'"
                  >{{ resource.completed ? 'completato' : 'non completato' }}</span>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </td>
      </template>
    </DefaultTable>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import DefaultTable from "@cmp/shared/DefaultTable.vue";
export default {
  name: "ReportResourcesStatus",
  components: {DefaultTable},
  props: {
    selectCourse: {
      type: Function,
      required: true,
    },
    course: {
      type: Object,
      required: true,
    },
    classId: {
      type: String,
      required: true,
    }
  },
  data:() => ({
    isLoading: true,
    headers: [
      {
        text: "Nome",
        value: "name",
      },
      {
        text: "Cognome",
        value: "surname",
      },
    ],
    tabs: [],
    tab: null
  }),
  async mounted() {
    await this.getData();
  },
  methods: {
    ...mapActions("learningPaths", ["getOndemandById"]),
    async getData() {
      this.isLoading = true;
      try {
        const data = await this.getOndemandById({
          id: this.course.id,
        });
        this.tabs = data.sections.map((s) => s.name).slice();
      } finally {
        this.isLoading = false;
      }
    },
    iconSelector(item) {
      switch(item) {
      case "assessment":
        return "mdi-certificate-outline";
      case "video_lesson":
        return "mdi-movie-open-outline";
      case "prog_exercise":
        return "mdi-file-code-outline";
      default: return ""
      }
    },
    realResources(item, tab) {
      const currentSection = item.filter((i) => i.section_name === tab);
      return currentSection;
    }
  }
}
</script>

<style scoped>
.card-title{
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #FFFFFF;
}

.card-subtitle{
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #F4F5F6;
}

.back-to-courses{
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #F4F5F6;
}

.resource-label{
  font-weight: 700;
  font-size: 14px;
  line-height: 28px;
}

.resource-status{
  font-weight: 500;
}

.tab-selected{
  font-weight: 700;
}

.tabs-title{
  font-size: 16px;
  text-transform: none;
}
</style>