import { render, staticRenderFns } from "./QuizExam.vue?vue&type=template&id=743644b7&scoped=true"
import script from "./QuizExam.vue?vue&type=script&lang=js"
export * from "./QuizExam.vue?vue&type=script&lang=js"
import style0 from "./QuizExam.vue?vue&type=style&index=0&id=743644b7&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "743644b7",
  null
  
)

export default component.exports