import livesApi from "@services/livesAxiosInstance";

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/swagger/index.html#/Reports/get_report_download
 */
function getReportByCourse(classroomid, courseid) {
  return livesApi.get(
    `/report/download?courseid=${courseid}&classroomid=${classroomid}`,
    {
      responseType: "blob",
    }
  );
}

export default {
  getReportByCourse,
};
