import Vue from "vue";
import Vuex from "vuex";
import auth from "@store/modules/auth";
import bellMessages from "@store/modules/bellMessages";
import classrooms from "@store/modules/classrooms";
import courses from "@store/modules/courses";
import learningPaths from "./modules/learningPaths";
import learningResources from "./modules/learningResources";
import loading from "@store/modules/loading";
import toolbar from "@store/modules/toolbar";
import chat from "@store/modules/chat";
import config from "@store/modules/config";
import reports from "@store/modules/reports";
import quiz from "@store/modules/quiz";
import exam from "@store/modules/exam";
import liveTracker from "@store/modules/liveTracker";
import ondemandTracker from "@store/modules/ondemandTracker";
import trigger from "@store/modules/trigger";

Vue.use(Vuex);

const actions = {
  resetState({ commit, dispatch }) {
    commit("auth/resetState", {}, { root: true });
    commit("chat/resetState", {}, { root: true });
    commit("classrooms/resetState", {}, { root: true });
    commit("courses/resetState", {}, { root: true });
    dispatch("exam/resetState", {}, { root: true });
    commit("learningPaths/resetState", {}, { root: true });
    commit("toolbar/resetState", {}, { root: true });
    commit("liveTracker/resetState", {}, { root: true });
    commit("ondemandTracker/resetState", {}, { root: true });
    localStorage.removeItem("live-tracker-token");
    localStorage.removeItem("ondemand-tracker-token");
  },
};

export default new Vuex.Store({
  modules: {
    auth,
    bellMessages,
    classrooms,
    courses,
    learningPaths,
    learningResources,
    loading,
    toolbar,
    chat,
    config,
    reports,
    quiz,
    exam,
    liveTracker,
    ondemandTracker,
    trigger,
  },
  actions,
});
