import api from "@services/axiosInstance";
/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/Learning%20path%20quiz/get_learning_paths_quizzes__id_
 */
function getOndemandQuizzes() {
  return api.get(`learning-paths/quizzes`);
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/Learning%20path%20quiz/get_learning_paths_quizzes__id_
 */
function getOndemandQuizzesById(id) {
  return api.get(`learning-paths/quizzes/${id}`);
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/Learning%20resource/get_learning_paths_video_lessons__id_
 */
function getOndemandVideo(videoPathId) {
  return api.get(`learning-paths/video-lessons/${videoPathId}`);
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/Learning%20resource/get_learning_paths_prog_exercises__id_
 */
function getOndemandExerciseDetails(id) {
  return api.get(`learning-paths/prog-exercises/${id}`);
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/Learning%20resource/get_learning_paths_prog_exercises__id__hint
 */
function getOndemandExerciseHint(id) {
  return api.get(`learning-paths/prog-exercises/${id}/hint`);
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/Learning%20resource/post_learning_paths_prog_exercises__id__complete
 */
function completeOndemandExercise(id) {
  return api.post(`learning-paths/prog-exercises/${id}/complete`);
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/Learning%20resource/get_learning_paths_video_lessons__id_
 */
function getStudentVideoInfo(id) {
  return api.get(`/learning-paths/video-lessons/${id}`);
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/Learning%20path%20quiz/post_learning_paths_quizzes_start__id_
 */
function startOndemandQuiz(id) {
  return api.post(`/learning-paths/quizzes/start/${id}`);
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/Learning%20path%20quiz/post_learning_paths_quizzes_select_answers
 */
function selectOndemandQuizAnswers(answers, quizId) {
  return api.post(`learning-paths/quizzes/select-answers`, {
    answers,
    quiz_id: quizId,
  });
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/Learning%20path%20quiz/post_learning_paths_quizzes_terminate__id_
 */
function finishOndemandQuiz(id) {
  return api.post(`learning-paths/quizzes/terminate/${id}`);
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/Learning%20path%20questions/get_learning_paths_questions
 */
function getOndemandComments(id) {
  return api.get(`learning-paths/questions`, {
    params: {
      id,
    },
  });
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/Learning%20path%20questions/get_learning_paths_questions
 */
function postNewQuestion(learningPathId, text) {
  return api.post(`learning-paths/questions`, {
    learning_path_id: learningPathId,
    text,
  });
}
function postNewComment(learningPathId, text, mainQuestion) {
  return api.post(`learning-paths/questions`, {
    learning_path_id: learningPathId,
    text,
    main_question: mainQuestion,
  });
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/Learning%20path%20questions/put_learning_paths_questions_approve
 */
function approveOndemandQuestion(id) {
  return api.put(`learning-paths/questions/approve?id=${id}`);
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/Learning%20path%20questions/delete_learning_paths_questions
 */
function deleteOndemandComment(id) {
  return api.delete(`learning-paths/questions`, {
    params: {
      id,
    },
  });
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/Learning%20resource/get_learning_paths_video_lessons__id__tracker_token
 */
function getVideoTrackerToken(id) {
  return api.get(`learning-paths/video-lessons/${id}/tracker-token`);
}

export default {
  getOndemandQuizzes,
  getOndemandQuizzesById,
  getOndemandVideo,
  getOndemandExerciseDetails,
  getOndemandExerciseHint,
  completeOndemandExercise,
  getStudentVideoInfo,
  startOndemandQuiz,
  selectOndemandQuizAnswers,
  finishOndemandQuiz,
  getOndemandComments,
  postNewQuestion,
  postNewComment,
  approveOndemandQuestion,
  deleteOndemandComment,
  getVideoTrackerToken,
};
