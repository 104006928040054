<template>
  <Modal
    v-if="dialog"
    title="Esito quiz ondemand"
    :show-close-button="false"
    :scrollable="true"
    @closeModal="closeDialog"
  >
    <template #content>
      <v-card-text>
        <p>{{ populateText }}</p>
        <template v-if="!isStudent">
          <h4>Di seguito trovi il resoconto del tuo test</h4>
          <v-col
            v-for="(question, questionIndex) in result.questions"
            :key="question.id"
            class="px-0"
          >
            <span
              ><strong>Domanda {{ questionIndex + 1 }}:</strong></span
            >
            <p v-dompurify-html="question.question_text" />
            <span><strong>La tua risposta:</strong></span>
            <p>{{ populateSelectedAnswer(question.answers) }}</p>
            <span><strong>Risposta giusta:</strong></span>
            <p>{{ populateCorrectAnswer(question.answers) }}</p>
            <v-divider v-if="questionIndex < result.questions.length - 1" />
          </v-col>
        </template>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          v-if="!isStudent || !result.passed"
          color="#F39200"
          class="mx-3 rounded-lg"
          outlined
          @click="closeDialog"
        >
          Chiudi
        </v-btn>
        <v-btn
          v-if="isStudent && result.passed"
          color="#F39200"
          class="white--text mx-3 rounded-lg"
          @click="goToNext"
        >
          vai alla risorsa successiva
        </v-btn>
      </v-card-actions>
    </template>
  </Modal>
</template>

<script>
import Modal from "@cmp-shared/utils/Modal";
import userHandler from "@mixins/userHandler";
import trigger from "@mixins/trigger";
import { mapActions } from "vuex";
export default {
  name: "AssessmentResultDialog",
  components: { Modal },
  mixins: [userHandler, trigger],
  props: {
    result: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    dialog: true,
  }),
  computed: {
    populateText() {
      if (this.result.passed) {
        return "Complimenti, hai superato il quiz! Prosegui il tuo percorso formativo.";
      }
      return "Che peccato, non hai superato il quiz. Nessun problema, puoi ritentarlo in qualsiasi momento.";
    },
  },
  methods: {
    ...mapActions("learningPaths", ["getOndemandById"]),
    closeDialog() {
      this.dialog = false;
      this.$router.go();
    },
    goToNext() {
      this.dialog = false;
      this.getNextResource();
      this.triggerUpdate();
    },
    populateSelectedAnswer(answers) {
      const answer = answers.find((a) => a.selected)?.text;
      if (answer === undefined) {
        return "Risposta non selezionata";
      }
      return answer;
    },
    populateCorrectAnswer(answers) {
      return answers.find((a) => a.correct).text;
    },
    async getNextResource() {
      const data = await this.getOndemandById({ id: this.$route.params.id });
      const [currentResource] = data.sections
        .map((c) =>
          c.resources.find((r) => r.id === this.$route.params.resourceId)
        )
        .filter((final) => final !== undefined);
      const lastResource = data.sections.at(-1).resources.at(-1).order_num;
      if (currentResource.order_num === lastResource) {
        this.$router.push(
          `/ondemand-courses/${this.$route.params.id}/${data.sections[0].resources[0].type}/${data.sections[0].resources[0].id}`
        );
        return;
      }
      const [nextResource] = data.sections
        .map((c) =>
          c.resources.find((r) => r.order_num === currentResource.order_num + 1)
        )
        .filter((final) => final !== undefined);
      this.$router.push(
        `/ondemand-courses/${this.$route.params.id}/${nextResource.type}/${nextResource.id}`
      );
    },
  },
};
</script>
