import { handleAxiosError, sendNotification } from "@utils/notifications";
import examService from "@services/exam";

const initialState = () => ({
  id: null,
  title: null,
  duration: null,
  time_left: null,
  max_points: null,
  questions: [],
  result: null,
});

const state = initialState();

const getters = {
  id(state) {
    return state.id;
  },

  title(state) {
    return state.title;
  },

  duration(state) {
    return state.duration;
  },

  time_left(state) {
    return state.time_left;
  },

  max_points(state) {
    return state.max_points;
  },

  questions(state) {
    return state.questions;
  },

  result(state) {
    return state.result;
  },
};

const mutations = {
  SET_EXAM(state, { exam }) {
    const { id, title, duration, time_left, max_points, questions } = exam;
    state.id = id;
    state.title = title;
    state.duration = duration;
    state.time_left = time_left;
    state.max_points = max_points;
    state.questions = questions;
  },

  SET_RESULT(state, data) {
    state.result = data;
  },

  RESET_STATE(state) {
    Object.assign(state, initialState());
  },
};

const actions = {
  resetState({ commit }) {
    commit("RESET_STATE");
  },

  async startExam({ commit }, quizId) {
    try {
      commit("loading/startLoading", {}, { root: true });
      const { data } = await examService.startExam(quizId);
      commit("SET_EXAM", { exam: data });
    } catch (err) {
      await handleAxiosError(`Errore durante il caricamento dell'esame`, err);
      return Promise.reject(new Error("starExam failed", err));
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
    return Promise.resolve();
  },

  async saveAnswers(_, answers) {
    try {
      await examService.saveAnswers(answers);
      return Promise.resolve();
    } catch (err) {
      await handleAxiosError(
        `Errore durante il salvataggio automatico delle risposte`,
        err
      );
      return Promise.reject(new Error("saveAnswers failed", err));
    }
  },

  async terminateExam({ commit }, examId) {
    try {
      const { data } = await examService.terminateExam(examId);
      commit("SET_RESULT", data);
      sendNotification("Test terminato", "success");
      return Promise.resolve();
    } catch (err) {
      await handleAxiosError(`Errore durante la terminazione del test`, err);
      return Promise.reject(new Error("terminateExam failed", err));
    }
  },

  async resultsExams({ commit }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      const { data } = await examService.resultsExams();
      return data;
    } catch (err) {
      await handleAxiosError(
        `Errore durante il caricamento dei risultati`,
        err
      );
      return null;
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async resultsAggregated({ commit }, { classId }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      const { data } = await examService.resultsAggregated(classId);
      return data;
    } catch (err) {
      await handleAxiosError(`Errore durante il caricamento dei tests`, err);
      return null;
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async resultsOfStudents({ commit }, { classId, quizId }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      const { data } = await examService.resultsOfStudents(classId, quizId);
      return data;
    } catch (err) {
      await handleAxiosError(
        `Errore durante il caricamento dei risultati`,
        err
      );
      return null;
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async examSummary({ commit }, { studentId, quizId, filename }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      const { data } = await examService.examSummary(studentId, quizId);
      const blob = new Blob([data]);
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = (e) => {
        const a = document.createElement("a");
        a.download = `${filename}.zip`;
        a.href = e.target.result;
        a.click();
      };
    } catch (err) {
      await handleAxiosError(
        `Errore durante il caricamento dei risultati`,
        err
      );
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async allExamsSummary({ commit }, { classId, quizId, filename }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      const { data } = await examService.allExamsSummary(classId, quizId);
      const blob = new Blob([data]);
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = (e) => {
        const a = document.createElement("a");
        a.download = `${filename}.zip`;
        a.href = e.target.result;
        a.click();
      };
    } catch (err) {
      await handleAxiosError(
        `Errore durante il caricamento dei risultati`,
        err
      );
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async getCertificate({ commit }, { studentId, quizId, filename }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      const { data } = await examService.getCertificate(studentId, quizId);
      const blob = new Blob([data]);
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = (e) => {
        const a = document.createElement("a");
        a.download = `${filename}.pdf`;
        a.href = e.target.result;
        a.click();
      };
    } catch (err) {
      await handleAxiosError(
        `Errore durante lo scaricamento del certificato`,
        err
      );
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async getAllCertificates({ commit }, { classId, quizId, filename }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      const { data } = await examService.getAllCertificates(classId, quizId);
      const blob = new Blob([data]);
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = (e) => {
        const a = document.createElement("a");
        a.download = `${filename}.zip`;
        a.href = e.target.result;
        a.click();
      };
    } catch (err) {
      await handleAxiosError(
        `Errore durante lo scaricamento dei certificati`,
        err
      );
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async generateBadge({ commit }, { email, quizId }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      await examService.generateBadge({ email, quiz_id: quizId });
      sendNotification("Badge generato con successo", "success");
    } catch (err) {
      await handleAxiosError(`Errore durante la generazione del badge`, err);
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
