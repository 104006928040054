<template>
  <v-app class="app-container">
    <v-main>
      <Toolbar
        ref="toolbar"
        :is-project-area="true"
      />
      <!-- MY-PROFILE -->
      <div class="route-wrapper">
        <MyProfile
          v-if="loaded"
          @reloadToolbarAvatar="$refs.toolbar.reloadAvatar"
        />
      </div>
    </v-main>
  </v-app>
</template>

<script>
import Toolbar from "@cmp-shared/toolbar/Toolbar";
import MyProfile from "@cmp/platform/MyProfile";
import store from "@store";

import { mapState } from "vuex";

export default {
  name: "MyProfileView",
  components: {
    Toolbar,
    MyProfile,
  },
  data() {
    return {
      tabs: ["Il mio profilo"],
      loaded: false,
    };
  },
  computed: {
    ...mapState("toolbar", ["selectedTab"]),
  },
  async created() {
    try {
      await store.dispatch("auth/getMyProfile");
      this.loaded = true;
    } catch (e) {
      await this.$router.push({ name: "error" });
    }
  },
};
</script>

<style scoped>
.theme--light.v-tabs-items {
  background-color: transparent;
}
</style>
