import api from "@services/axiosInstance";
import publicApi from "@services/publicAxiosInstance";
import livesApi from "@services/livesAxiosInstance";

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/swagger/index.html#/Courses/get_course
 */
function getCourses() {
  return livesApi.get("/course");
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/swagger/index.html#/Question/get_episode_question
 */
function getEpisodeQuestions(episodeId) {
  return livesApi.get(`/episode/question?episode_id=${episodeId}`);
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/swagger/index.html#/Classroom/get_classroom_subscribed
 */
function getClassroomsSubscribed(courseId) {
  return api.get(`/classroom/subscribed?courseid=${courseId}`);
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/swagger/index.html#/Courses/put_course_subscribe
 */
function addCourseToClassroom(classroomId, courseId) {
  return livesApi.put("/course/subscribe", {
    Classroomid: classroomId,
    CourseId: courseId,
  });
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/swagger/index.html#/Question/post_episode_question
 */
function createComment(episodeId, text, mainQuestionId) {
  return livesApi.post("/episode/question", {
    episode_id: episodeId,
    main_question_id: mainQuestionId,
    text,
  });
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/swagger/index.html#/Question/post_episode_question
 */
function createQuestion(episodeId, text) {
  return livesApi.post("/episode/question", {
    episode_id: episodeId,
    text,
  });
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/swagger/index.html#/Courses/put_course_unsubscribe
 */
function unsubscribeCourse(classroomId, courseId) {
  return livesApi.put("/course/unsubscribe", {
    Classroomid: classroomId,
    CourseId: courseId,
  });
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/swagger/index.html#/Question/delete_episode_question__question_id_
 */
function deleteQuestion(questionId) {
  return livesApi.delete(`/episode/question/${questionId}`);
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/swagger/index.html#/Ranking/get_rank
 */
function getRanks(courseId) {
  return livesApi.get("/rank", { params: { courseid: courseId } });
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/swagger/index.html#/Courses/post_course_subscription
 */
function requestSubscription(courseId) {
  return livesApi.post(`/course/subscription?course_id=${courseId}`);
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/Courses/get_next_live
 */
function getNextLiveInfo() {
  return publicApi.get(`/next-live`);
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/Episode/get_episode__id__tracker_token
 */
function getTrackerToken(episodeId) {
  return livesApi.get(`/episode/${episodeId}/tracker-token`);
}
export default {
  getCourses,
  getClassroomsSubscribed,
  getEpisodeQuestions,
  addCourseToClassroom,
  unsubscribeCourse,
  getRanks,
  requestSubscription,
  createComment,
  createQuestion,
  deleteQuestion,
  getNextLiveInfo,
  getTrackerToken,
};
