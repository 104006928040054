var render = function render(){var _vm=this,_c=_vm._self._c;return _c('DefaultTable',{key:_vm.classId,attrs:{"headers":_vm.headers,"local-values":_vm.getCoursesByIds(_vm.selectedClass.courses),"custom-slots":[
    'downloadReport'
  ]},scopedSlots:_vm._u([{key:"downloadReport",fn:function({ item }){return [_c('Download',{attrs:{"store-name":'reports',"store-action":'getReportByCourse',"object-to-send":{
        classroomId: _vm.classId,
        courseId: item.id,
        fileName: `report_${item.title}_${_vm.selectedClass.name}`
      }}})]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }