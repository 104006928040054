<template>
  <v-app class="app-container">
    <v-row class="pt-7" justify="center">
      <HeaderS4S />
    </v-row>
    <v-main>
      <v-container>
        <v-row justify="space-around">
          <v-col cols="12" sm="10" md="10" lg="7" xl="5">
            <div class="glassify pa-6 rounded-xl mb-8">
              <v-row>
                <v-col>
                  <h2
                    class="white--text font-weight-light text-center text-sm-left pb-6"
                  >
                    PRIMA DI CONTINUARE AGGIORNA LA TUA MAIL PERSONALE:
                  </h2>
                  <v-form
                    v-model="form"
                    class="form-font"
                    @submit.prevent="submit(secEmail)"
                  >
                    <v-row dense class="mb-2">
                      <v-col cols="12" class="mb-0 pb-0">
                        <v-text-field
                          v-model="secEmail"
                          required
                          label="E-mail personale"
                          :rules="[rules.email, freeEmailRule, rules.required]"
                          dark
                          :loading="
                            emailFree === null &&
                            secEmail !== '' &&
                            rules.email(secEmail) === true
                          "
                          background-color="rgba(255,255,255, .3)"
                          outlined
                          data-cy="input-secondary-email"
                          @keyup="onKeyUpEmail"
                          @keydown="onKeyDownEmail"
                        >
                          <template #append>
                            <v-icon v-if="emailFree === true">
                              mdi-check-decagram
                            </v-icon>
                          </template>
                        </v-text-field>
                        <v-checkbox
                          :disabled="isImpersonated"
                          :style="{ opacity: isImpersonated ? '0.5' : '1' }"
                          v-model="canContact"
                          class="mt-10"
                          color="orange"
                          dark
                        >
                          <template #label>
                            <div class="caption white--text">
                              Ho letto e accetto la
                              <span class="privacy-link">
                                <a
                                  href="https://www.sorint.com/privacy-policy/"
                                  target="_blank"
                                  rel="noopener"
                                  @click.stop
                                >
                                  privacy policy
                                </a>
                              </span>
                              e desidero ricevere comunicazioni da Sorint.Lab
                              per opportunità lavorative e avvisi su iniziative
                              rivolte agli studenti
                            </div>
                          </template>
                        </v-checkbox>

                        <!-- for mobile use this alert instead of tooltip -->
                        <v-alert
                          v-if="
                            $vuetify.breakpoint.xsOnly && emailFree === false
                          "
                          type="error"
                        >
                          Email già in uso
                        </v-alert>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-col>
              </v-row>
              <v-row class="justify-center">
                <v-btn
                  :loading="loading"
                  color="success"
                  :disabled="!form"
                  dark
                  x-large
                  data-cy="secondary-email-ok"
                  class="btn-font ma-4"
                  @click="submit(secEmail)"
                >
                  AGGIORNA
                </v-btn>
              </v-row>

              <v-row class="justify-center">
                <h5 class="secondary-text" @click="submit('')">
                  Procedi senza aggiornare email.
                </h5>
              </v-row>
            </div>
          </v-col>
        </v-row>
        <v-row justify="space-around">
          <v-col cols="12" sm="10" md="10" lg="7" xl="5">
            <Supporters />
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { validationMixin } from "vuelidate";
import { email, required } from "vuelidate/lib/validators";
import { mapActions } from "vuex";
import HeaderS4S from "@cmp/platform/HeaderS4S";
import Supporters from "@cmp/platform/Supporters";
import api from "@services/axiosInstance";
import userHandler from "@mixins/userHandler";

export default {
  name: "UpdateSecEmail",
  components: { HeaderS4S, Supporters },
  mixins: [validationMixin, userHandler],
  validations: {
    email: { email, required },
  },
  data() {
    return {
      secEmail: "",
      form: false,
      loading: false,
      emailFree: null,
      rules: {
        required: (value) => Boolean(value) || "Valore richiesto",
        email: (v) =>
          !v ||
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) ||
          `L'e-mail deve essere valida`,
      },
      canContact: true,
    };
  },

  computed: {
    freeEmailRule() {
      return this.emailFree !== false || "La mail selezionata è già in uso";
    },
  },
  methods: {
    ...mapActions("auth", ["updateStudentProfile"]),

    async submit(newEmail) {
      this.loading = true;

      try {
        if (!this.form && newEmail !== "") {
          throw new Error("Controlla i dati");
        }

        await this.updateStudentProfile({
          payload: {
            secondaryMail: newEmail,
            canContact: this.canContact,
          },
        });

        this.$router.push({ name: "student-platform-home-page" });
      } catch (err) {
        // do nothing
      } finally {
        this.loading = false;
      }
    },
    clear() {
      this.$v.$reset();
      this.secEmail = "";
    },
    onKeyDownEmail() {
      if (this.rules.email(this.secEmail) === true) {
        this.emailFree = null;
      }
      clearTimeout(this.emailTimeout);
    },
    onKeyUpEmail() {
      this.emailTimeout = setTimeout(this.checkEmailFree, 1500);
    },
    async checkEmailFree() {
      if (this.rules.email(this.secEmail) === true) {
        try {
          const { data } = await api.get("user/check-email", {
            params: { email: this.secEmail },
          });
          this.emailFree = data.is_free;
        } catch (err) {
          /* do nothing */
        }
      }
    },
  },
  mounted() {
    this.updateStudentProfile({
      payload: {
        secondaryMail: "",
        canContact: false,
      },
      notification: false,
    });
  },
};
</script>

<style scoped>
.gradient {
  background: rgb(2, 0, 36);
  background: linear-gradient(
    180deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(65, 9, 121, 1) 0%,
    rgba(65, 9, 121, 0) 100%
  );
}
h5,
h6,
.v-card-text {
  font-family: "Karasuma Gothic", sans-serif;
}
.secondary-text {
  color: white;
  opacity: 0.5;
  transition: 0.7s;
  cursor: pointer;
  text-decoration: underline;
}
.secondary-text:hover {
  opacity: 1;
}
p {
  text-align: left;
  font-family: "Karasuma Gothic", sans-serif;
  font-size: 1.5em;
}
.no-margin-row {
  margin-left: 0;
  margin-right: 0;
}
.btn-font {
  font-family: "Karasuma Gothic", sans-serif;
}
.col-md-auto {
  flex: 0 0 auto;
  width: 50%;
  max-width: 100%;
}
a {
  color: white;
  text-decoration: none;
}
a:visited {
  color: #f37021;
  text-decoration: none;
}
a:hover {
  color: white;
  text-decoration: underline;
}
a:active {
  text-decoration: underline;
}
.privacy-link a,
.privacy-link a:visited,
.privacy-link a:active,
.privacy-link a:focus-within,
.privacy-link a:focus,
.privacy-link a:focus-visible {
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 500;
  display: inline-block;
  font-size: 12px;
  letter-spacing: 0.75px;
  text-decoration-line: underline;
  color: #f4f5f6 !important;
}
</style>
