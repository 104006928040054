<template>
  <div>
    <TeacherSubscribeModals ref="card" />
    <v-container class="pa-0">
      <v-row no-gutters>
        <v-col
          align="start"
          class="py-0"
        >
          <h1
            class="section-title mb-2"
            :class="$vuetify.breakpoint.mdAndDown ? 'text-center' : 'text-left'"
          >
            {{ getTitle }}
          </h1>
        </v-col>
        <v-col
          v-if="isTeacher"
          cols="12"
          md="6"
          class="d-flex justify-end"
          :class="
            $vuetify.breakpoint.smAndDown ? 'mb-2' : ''
          "
        >
          <v-btn
            dark
            color="#F39200"
            class="rounded-lg"
            :width="
              $vuetify.breakpoint.smAndDown ? '100%' : ''
            "
            @click="openSubscribeInfoModal"
          >
            <span
              v-if="
                this.$vuetify.breakpoint.sm ||
                  this.$vuetify.breakpoint.xs
              "
            >
              Scopri l'importanza <br>
              di iscrivere le classi!
            </span>
            <span v-else>
              Scopri l'importanza di iscrivere le classi!
            </span>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <div v-if="getCourses.length > 0">
      <CoursePreview
        v-for="(course, index) in getCourses"
        :id="isGuest ? '' : course.id"
        :key="index"
        :course="course"
        :outlined="outlineCourse(index, course)"
        @subscribe="openSubscribeDialog(course.id)"
      />
    </div>
    <div v-else>
      <v-row>
        <v-col>
          <h3 class="bit text-left white--text">
            Nessun dato da mostrare
          </h3>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
/*
    TYPE 1: ON DEMAND
    TYPE 2: LIVE
    TYPE 3: SUBSCRIBED
*/

import CoursePreview from "@cmp/courses/CoursePreview";
import courseExpandedHandler from "@mixins/courseExpandedHandler";
import TeacherSubscribeModals from "../../platform/teacher/TeacherSubscribeModals.vue";
import userHandler from "@mixins/userHandler";
export default {
  name: "CoursesExpanded",
  components: { CoursePreview, TeacherSubscribeModals },
  mixins: [courseExpandedHandler, userHandler],
  methods: {
    openSubscribeDialog(_id) {
      this.$refs.card.openSubscribeDialog(_id);
    },
    openSubscribeInfoModal() {
      this.$refs.card.openSubscribeInfoModal();
    },
  }
};
</script>

<style scoped>
.section-title{
  font-weight: 700;
  font-size: 48.83px;
  line-height: 50px;
  letter-spacing: 1px;
  color: #FFFFFF;
}
</style>
