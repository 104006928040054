export default {
  computed: {
    dashboardBackground() {
      const routeName = this.$route.name;

      if (routeName.includes("projects")) {
        return "projects-container";
      }
      return "courses-container";
    },
  },
};
