<template>
  <v-menu transition="slide-y-transition" bottom :offset-y="true">
    <template #activator="{ on }">
      <v-btn id="user-button" text large data-cy="toolbar-menu" v-on="on">
        <v-img
          v-if="user !== null && user.avatar.encoded !== ''"
          max-height="42"
          max-width="42"
          class="rounded-circle mr-3"
          :src="avatarUrl"
        >
          <template #placeholder>
            <v-container class="fill-height ma-0 grey-bg" />
          </template>
        </v-img>
        <span class="user-info">
          {{ user?.name ? user.name + " " + user.surname : "..." }}
        </span>
        <v-icon class="ml-1 orange--text"> mdi-chevron-down </v-icon>
      </v-btn>
    </template>
    <v-list dense class="glassify" width="100%">
      <v-list-item
        v-if="!isSorint && user && user?.access_level !== 1"
        class="menu-item"
        @click="() => navigateTo('my-profile')"
      >
        <v-list-item-icon>
          <v-icon color="white"> mdi-account-circle </v-icon>
        </v-list-item-icon>
        <v-list-item-content
          data-cy="my-profile-btn"
          class="white--text text-uppercase"
        >
          Il mio profilo
        </v-list-item-content>
      </v-list-item>

      <v-list-item data-cy="logout-btn" class="menu-item" @click="quit">
        <v-list-item-icon>
          <v-icon color="white"> mdi-logout </v-icon>
        </v-list-item-icon>
        <v-list-item-content
          class="white--text text-uppercase"
          data-cy="logout"
        >
          Logout
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import matomoTracker from "@mixins/matomoTracker";
import { pushAuthUrl } from "@/utils/externalRouter";

export default {
  name: "ToolbarMenu",
  mixins: [matomoTracker],
  props: {
    avatarUrl: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  methods: {
    ...mapActions("auth", ["logout"]),

    async quit() {
      try {
        await this.logout();
        pushAuthUrl();
      } catch (err) {
        console.info("Router error");
      }
    },
    async navigateTo(routeName) {
      await this.trackEvent(
        "PlatformToolbar",
        "ClickOnMyProfilePage",
        routeName
      );
      this.$router.push({ name: routeName });
    },
  },
};
</script>

<style scoped>
#user-button,
.menu-item {
  font-family: "Karasuma Gothic", sans-serif;
}

.user-info {
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  color: #ffffff;
  text-transform: none;
}
.grey-bg {
  background-color: rgba(100, 100, 100, 0.5);
}
</style>
