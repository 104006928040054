<template>
  <div>
    <v-expansion-panels dark>
      <v-expansion-panel
        v-for="(course, courseIndex) in courseSections"
        :key="course.id"
        active-class="course-list-wrapper-clicked"
        class="mb-5 transparent course-list-wrapper"
      >
        <v-expansion-panel-header
          class="course-list-header"
          active-class="course-list-clicked"
        >
          <v-row>
            <v-col
              cols="3"
              class="d-flex justify-start align-center"
              :class="$vuetify.breakpoint.mdAndDown ? 'pl-0' : ''"
            >
              <span class="wrapper-number">{{ courseIndex + 1 }}</span>
            </v-col>
            <v-col cols="9">
              <v-row>
                <v-col cols="12" class="d-flex justify-start pb-0 px-0">
                  <span class="course-paragraph number-of-videos"
                    >{{ course.resources.length }} risorse</span
                  >
                </v-col>
                <v-col cols="12" class="pb-0 pt-2 px-0">
                  <h3 class="course-title">
                    {{ course.name }}
                  </h3>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col
              v-for="resource in course.resources"
              :key="resource.id"
              cols="12"
              class="d-flex justify-end px-0"
            >
              <v-switch
                v-if="
                  isImpersonated &&
                  resource.type === 'video_lesson' &&
                  resource.unlocked
                "
                @click="setVideoAsCompleted(resource)"
                v-model="resource.completed"
                :disabled="resource.completed"
              />

              <v-card
                :disabled="
                  isStudent &&
                  (!resource.unlocked ||
                    (resource.type === 'assessment' && resource.completed))
                "
                width="100%"
                height="44"
                class="rounded-lg video-title-wrapper pb-2 px-2 mt-2"
                :class="[
                  { 'resource-clicked': selectedResource === resource.id },
                  { 'completed-resource': resource.completed },
                ]"
                @click="showInfo(resource)"
              >
                <v-row align="center">
                  <v-col cols="1" class="pr-0 py-0 mt-3">
                    <v-checkbox
                      v-model="resource.completed"
                      :ripple="false"
                      class="pb-0"
                      :class="{ 'resource-not-checked': !resource.completed }"
                      readonly
                      dense
                    />
                  </v-col>
                  <v-col cols="9">
                    <span
                      class="course-paragraph"
                      :class="{ 'white--text': resource.completed }"
                      >{{ resource.title }}</span
                    >
                  </v-col>
                  <v-col cols="2">
                    <v-icon
                      left
                      :color="resource.completed ? 'f4f5f6' : '#98A6AE'"
                    >
                      {{ iconSelector(resource.type) }}
                    </v-icon>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import userHandler from "@mixins/userHandler";
import { mapActions } from "vuex";
export default {
  name: "OndemandPath",
  mixins: [userHandler],
  props: {
    courseSections: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    selectedResource: "",
  }),
  computed: {
    currentResource() {
      return this.$route.params.resourceId;
    },
  },
  watch: {
    currentResource(value) {
      if (value) {
        this.selectedResource = value;
      }
    },
  },
  mounted() {
    this.selectedResource = this.$route.params.resourceId;
  },
  methods: {
    ...mapActions("learningPaths", ["completeVideo"]),
    showInfo(item) {
      this.selectedResource = item.id;
      this.$router.push(
        `/ondemand-courses/${this.$route.params.id}/${item.type}/${item.id}`
      );
    },
    iconSelector(type) {
      switch (type) {
        case "video_lesson":
          return "mdi-clock-outline";
        case "prog_exercise":
          return "mdi-laptop";
        default:
          return "mdi-pencil";
      }
    },
    async setVideoAsCompleted(id) {
      try {
        const response = await this.completeVideo(id);
      } catch (err) {
        setTimeout(() => {
          location.reload();
        }, 3000);
      }
    },
  },
};
</script>

<style scoped>
.course-list-header {
  background-clip: border-box;
  background: linear-gradient(
    to right,
    #f39200 0%,
    #f39200 20%,
    transparent 20%,
    transparent 100%
  ) !important;
  border-radius: 12px !important;
}

.course-list-wrapper {
  border: thin solid #555571 !important;
  border-radius: 13px !important;
}

.course-list-wrapper-clicked {
  border-radius: 0px;
  border: 2px solid #f39200 !important;
}
.course-list-wrapper-clicked .course-list-header {
  border-radius: 10px 0px 0px 0px !important;
  transition: border-radius 0.1s;
}

.wrapper-number {
  font-weight: 700;
  font-size: 48px;
  line-height: 28px;
  color: #ffffff;
}

.number-of-videos {
  color: #f4f5f6;
}

.course-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  color: #ffffff;
}

.video-title-wrapper {
  background-color: transparent !important;
  border: 1px #5a6972 solid;
}

.course-paragraph {
  font-size: 16px;
  line-height: 18px;
  color: #98a6ae;
  text-transform: uppercase;
}

.resource-clicked {
  border: solid #f39200;
}

.completed-resource {
  background: #f39200 !important;
}

.resource-checkbox::before {
  background: yellow;
}

.resource-not-checked {
  color: #f39200;
}
</style>
