<template>
  <v-container class="pt-0">
    <h2 class="courses-title">Live</h2>
    <v-card class="mt-10 justify-center course-card">
      <v-row class="pt-5">
        <v-col cols="8">
          <v-btn
            v-show="typeOfUser(user?.access_level) !== 'admin'"
            text
            class="ml-5"
            color="transparent"
            :to="`/${typeOfUser(user?.access_level)}/courses`"
          >
            <v-icon color="#ffffff" left size="22"> mdi-arrow-left </v-icon>
            <span class="back-to-courses"> visualizza lista corsi </span>
          </v-btn>
        </v-col>
        <v-col
          v-if="!($vuetify.breakpoint.sm || $vuetify.breakpoint.xs)"
          class="px-0 pb-0"
        >
          <p class="font-weight-bold mt-3">Chat Live</p>
        </v-col>
      </v-row>
      <!-- VIDEO PLAYER -->
      <v-row justify="center" class="mx-5 mt-1">
        <v-col cols="12" md="8">
          <VideoPlayer :episode="episode" />
          <v-row class="py-0 my-0 mt-2">
            <v-col cols="12">
              <h1 class="course-title d-flex justify-center justify-md-start">
                {{ course.title }} - {{ episode.title }}
              </h1>
            </v-col>
            <v-col cols="12" class="d-flex justify-center justify-md-start">
              <Rating
                :stars-only="true"
                :episode="episode"
                :course-id="course.id"
              />
            </v-col>
          </v-row>
          <!-- CHAT: -->
          <v-row
            v-if="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs"
            cols="12"
            md="4"
            class="mx-n8 mb-4"
          >
            <v-col>
              <LiveChat
                id="chat"
                class="rounded-lg"
                :disabled-text="featureLockedText"
                :course="course"
                :public-channel="true"
                name="Chat Live"
              />
            </v-col>
          </v-row>
          <v-row class="py-0 my-0 mx-n14 mx-md-n3">
            <v-col cols="12" class="px-0">
              <EpisodeLinks :course="course" :episode="episode" />
            </v-col>
          </v-row>
        </v-col>
        <!-- CHAT: -->
        <v-col
          v-if="!($vuetify.breakpoint.sm || $vuetify.breakpoint.xs)"
          cols="12"
          md="4"
        >
          <LiveChat
            id="chat"
            class="rounded-lg"
            :disabled-text="featureLockedText"
            :course="course"
            :public-channel="true"
            name="Chat Live"
          />
        </v-col>
      </v-row>
      <v-row class="py-0 my-0 mx-5">
        <v-col cols="12" md="8">
          <router-view :key="$route.name" class="py-2" />
          <v-row no-gutters>
            <v-col cols="12" md="3" xl="2" class="order-2 order-md-1">
              <span
                class="stepper-span"
                :class="{
                  'stepper-span-clicked': selectedTab === 0,
                }"
                @click="tabSelector(0)"
                >Informazioni del corso</span
              >
              <hr v-if="selectedTab === 0" class="tab-divider-info" />
            </v-col>
            <v-col
              cols="12"
              md="5"
              xl="2"
              class="order-3 order-md-2 pl-md-3 mt-2 mt-md-0"
            >
              <span
                class="stepper-span"
                :class="{
                  'stepper-span-clicked': selectedTab === 1,
                }"
                @click="tabSelector(1)"
                >Domande anonime</span
              >
              <hr v-if="selectedTab === 1" class="tab-divider-questions" />
            </v-col>
            <v-col
              v-if="course.resource && course.resource.length > 0"
              cols="12"
              md="4"
              xl="8"
              justify="end"
              align-self="end"
              class="order-1 order-md-3 d-flex justify-md-end mt-2 mt-md-0"
            >
              <v-btn
                class="resources-button rounded-lg"
                :class="$vuetify.breakpoint.xsOnly ? 'mb-4' : ''"
                :width="$vuetify.breakpoint.xsOnly ? '100%' : ''"
                outlined
                color="#F39200"
                :href="course.resource[0]"
                target="_blank"
              >
                <v-icon left> mdi-layers-triple-outline </v-icon>
                Materiali
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <CourseInfoSection
                v-if="selectedTab === 0"
                :ondemand="false"
                :episode-description="episode.description"
                :course="course"
              />
              <CourseCommentsSection
                v-if="selectedTab === 1"
                :ondemand="false"
                :episode-id="episode.id"
                :course-id="course.id"
                :is-getting-data.sync="isGettingData"
                class="py-2"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import Rating from "@cmp/courses/Rating";
import VideoPlayer from "@cmp/courses/VideoPlayer";
import LiveChat from "@cmp/courses/LiveChat";
import EpisodeLinks from "./EpisodeLinks.vue";
import CourseInfoSection from "@cmp/courses/CourseInfoSection.vue";
import CourseCommentsSection from "@cmp/courses/CourseCommentsSection";
import userHandler from "@mixins/userHandler";
import courseHandler from "@mixins/courseHandler";
import featureLocker from "@mixins/featureLocker";

export default {
  name: "LivePlayer",
  components: {
    LiveChat,
    Rating,
    VideoPlayer,
    EpisodeLinks,
    CourseInfoSection,
    CourseCommentsSection,
  },
  mixins: [userHandler, courseHandler, featureLocker],
  props: {
    errorWhilePinging: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    now: Date.now(),
    selectedTab: 0,
    intervalQuestions: null,
    isGettingData: false,
  }),

  computed: {
    questionsDisabled() {
      return !(this.canViewResources || this.now > this.lastLiveDay);
    },
  },
  watch: {
    async episode() {
      if (!this.questionsDisabled) {
        await this.getEpisodeQuestions({
          episode_id: this.episode.id,
          course_id: this.course.id,
        });
      }
    },
  },
  async created() {
    if (!this.questionsDisabled) {
      await this.getEpisodeQuestions({
        episode_id: this.episode.id,
        course_id: this.course.id,
      });

      this.intervalQuestions = setInterval(async () => {
        await this.getEpisodeQuestions({
          episode_id: this.episode.id,
          course_id: this.course.id,
        });
        this.isGettingData = true;
      }, 60000);
    }
  },
  beforeDestroy() {
    clearInterval(this.intervalQuestions);
  },
  methods: {
    ...mapActions("courses", ["getEpisodeQuestions"]),
    tabSelector(n) {
      this.selectedTab = n;
    },
    typeOfUser(acc_level) {
      switch (acc_level) {
        case 1:
          return "admin";
        case 2:
          return "teacher";
        case 3:
          return "student";
        case 4:
          return "guest";
        default:
          return "sorint";
      }
    },
  },
};
</script>

<style scoped>
.fab-btn {
  top: 50%;
  position: absolute;
  margin: 0 0 16px 16px;
  z-index: 5;
}

.stepper-span {
  font-size: 16px;
  line-height: 28px;
  cursor: pointer;
}

.stepper-span-clicked {
  color: #f39200;
  font-weight: 700;
}

.ondemand-courses-container {
  background-color: #01002e;
}

.courses-title {
  font-weight: 700;
  font-size: 48.83px;
  line-height: 50px;
  color: #ffffff;
}

.course-card {
  background: linear-gradient(
      109.46deg,
      rgba(1, 0, 46, 0.3) 1.57%,
      rgba(1, 0, 46, 0.3) 100%
    ),
    linear-gradient(
      109.46deg,
      rgba(201, 201, 201, 0.3) 1.57%,
      rgba(196, 196, 196, 0.05) 100%
    ) !important;
  backdrop-filter: blur(30px);
  border-radius: 12px;
  font-size: 20px;
  line-height: 18.5px;
  color: #f4f5f6;
}

.back-to-courses {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #f4f5f6;
}

.resources-button {
  font-size: 16px;
  line-height: 28px;
  font-weight: 600;
  color: #f39200;
  text-transform: none;
}

.resource-divider {
  width: 55px;
  position: absolute;
  margin-top: 25px;
  border: 1px solid #f39200;
}

.tab-divider-info {
  width: 175px;
  position: absolute;
  border: 1px solid #f39200;
}

.tab-divider-questions {
  width: 146px;
  position: absolute;
  border: 1px solid #f39200;
}

.course-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #ffffff;
}
</style>
