<template>
  <Modal title="Cambio password" @closeModal="$emit('closeModal')">
    <template #content>
      <v-card-text>
        <v-form ref="form" v-model="form">
          <v-container>
            <v-row justify="center">
              <v-col cols="12" md="8">
                <v-text-field
                  v-model="oldPassword"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.required]"
                  :type="showPassword ? 'text' : 'password'"
                  label="Vecchia password"
                  hint="Inserisci la password attuale"
                  required
                  background-color="rgba(255,255,255, .3)"
                  dark
                  outlined
                  counter
                  @keyup.enter="submit"
                  @click:append="showPassword = !showPassword"
                />
              </v-col>
              <v-col cols="12" md="8">
                <v-text-field
                  v-model="newPassword"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[
                    rules.required,
                    rules.notLikeOld,
                    rules.passwordRules,
                  ]"
                  :type="showPassword ? 'text' : 'password'"
                  label="Nuova password"
                  hint="Una password sicura contiene almeno un numero ed un carattere speciale"
                  required
                  background-color="rgba(255,255,255, .3)"
                  dark
                  outlined
                  counter
                  @keyup.enter="submit"
                  @click:append="showPassword = !showPassword"
                />
              </v-col>
              <v-col cols="12" md="8">
                <v-text-field
                  v-model="confirmNewPassword"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.required, samePassword]"
                  :type="showPassword ? 'text' : 'password'"
                  label="Conferma password"
                  hint="Scrivi la password che hai appena scelto qui sopra"
                  required
                  background-color="rgba(255,255,255, .3)"
                  dark
                  outlined
                  counter
                  @keyup.enter="submit"
                  @click:append="showPassword = !showPassword"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-row justify="center">
          <v-btn
            large
            plain
            class="white--text mx-3"
            @click="$emit('closeModal')"
          >
            <v-icon class="mr-1"> mdi-close </v-icon>
            ANNULLA
          </v-btn>
          <v-btn
            :loading="loading"
            color="success"
            :disabled="!form"
            dark
            large
            class="btn-font"
            @click="submit"
          >
            <v-icon class="mr-1"> mdi-check </v-icon>
            CONFERMA
          </v-btn>
        </v-row>
      </v-card-actions>
    </template>
  </Modal>
</template>

<script>
import Modal from "@cmp-shared/utils/Modal";
import { mapActions } from "vuex";
import { useValidator } from "@/utils/validator";
export default {
  name: "ChangePasswordDialog",
  components: { Modal },
  data() {
    return {
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
      passwordToken: this.$route.params.token,
      loading: false,
      showPassword: false,
      form: false,
      rules: {
        required: (value) => Boolean(value) || "Campo obbligatorio",
        passwordRules: (v) => {
          const { validatePassword } = useValidator();
          const response = validatePassword(v);
          return !response.isValid ? response.message : response.isValid;
        },
        notLikeOld: (v) =>
          this.oldPassword !== v ||
          "La password non può essere uguale a quella precedente",
      },
    };
  },
  computed: {
    samePassword() {
      return (
        this.newPassword === this.confirmNewPassword ||
        "Le password non coincidono"
      );
    },
  },
  methods: {
    ...mapActions("auth", ["changePassword"]),
    async submit() {
      this.loading = true;
      try {
        await this.changePassword({
          oldPassword: this.oldPassword,
          newPassword: this.newPassword,
        });
        this.newPassword = "";
        this.confirmNewPassword = "";
        this.oldPassword = "";
        this.$refs.form.resetValidation();
        this.$emit("closeModal");
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
