<template>
  <v-card class="mt-2 glassify" width="30vw" min-width="250px">
    <v-card-text class="pa-2">
      <span
        class="mb-3 text-center d-block bit-text text-uppercase font-weight-bold white--text"
        >Comunicazioni</span
      >
      <v-alert
        v-for="(notification, index) in notifications"
        :key="index"
        class="mb-1"
        border="left"
        :icon="getIcon(notification)"
        dense
        :color="notification.type === 'journey' ? 'purple' : null"
        :type="notification.type !== 'journey' ? notification.type : 'warning'"
      >
        <v-row align="center" no-gutters>
          <v-col class="grow">
            <span class="text-subtitle-2">{{ notification.text }}</span>
          </v-col>
          <v-col v-if="notification.link" class="shrink ml-1">
            <v-btn outlined :href="notification.link" target="_blank">
              Vai
            </v-btn>
          </v-col>
        </v-row>
      </v-alert>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  name: "ToolbarNotification",
  props: {
    notifications: {
      type: Array,
      required: true,
    },
  },

  destroyed() {
    this.notificationsHide();
  },

  methods: {
    ...mapMutations("toolbar", ["notificationsHide"]),
    getIcon(notification) {
      if (notification.type === "error") return "mdi-alert-octagon";
      else if (notification.type === "info") return "mdi-information";
      else if (notification.type === "journey") return "mdi-rocket-launch";
      return "mdi-alert";
    },
  },
};
</script>
