<template>
  <Modal
    :glassify="true"
    title="HAI CONTROLLATO LE RISPOSTE?"
    @closeModal="$emit('onCloseModal')"
  >
    <template #content>
      <v-container>
        <v-row
          class="pt-2 mb-2"
        >
          <v-col align="center">
            <v-img
              max-height="275"
              width="fit-content"
              contain
              src="@/assets/exam/warning.webp"
              alt="astronaut"
            />
          </v-col>
        </v-row>
        <v-row
          justify="center"
          class="ma-1"
        >
          <span>Una volta terminato il test non potrai più modificare le risposte.</span>
        </v-row>
      </v-container>
      <v-row
        justify="center"
        no-gutters
      >
        <v-btn
          large
          dark
          color="orange darken-3"
          class="bit-text ma-4"
          @click="$emit('onFinishExam')"
        >
          Termina il test
        </v-btn>
      </v-row>
    </template>
  </Modal>
</template>

<script>
import Modal from "@cmp-shared/utils/Modal";
export default {
  name: "ConfirmFinishQuizModal",
  components: {
    Modal,
  },
  props: {
    answers:{
      type: Object,
      required: true,
    }
  },
  emits: ["onCloseModal", "onFinishExam"],
  computed: {
    atLeastOneQuestionIsNotAnswered() {
      for (const key of Object.keys(this.answers)) {
        if (
          this.answers[key] instanceof Array &&
          this.answers[key].length === 0
        ) {
          return true;
        } else if (
          !(this.answers[key] instanceof Array) &&
          !this.answers[key]
        ) {
          return true;
        }
      }
      return false;
    },
  },
};
</script>
