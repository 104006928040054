<template>
  <v-btn
    color="#59D657"
    class="white--text certificate-btn"
    :disabled="disabled"
    :dark="disabled"
    @click="() => $emit('onClick')"
  >
    <img
      src="@assets/exam/award.webp"
      alt="downloads all awards"
      class="mr-1"
    />
    Scarica tutti i certificati
  </v-btn>
</template>

<script>
export default {
  name: "DownloadCertificates",
  props: {
    disabled: {
      type: Boolean,
    },
  },
  emits: ["onClick"],
};
</script>

<style>
.certificate-btn {
  padding: 8px 12px;
  border-radius: 12px;
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
}
</style>
