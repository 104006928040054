import api from "@services/axiosInstance";

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/swagger/index.html#/Schools/get_schools
 */
function getAllSchools() {
  return api.get("/schools");
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/swagger/index.html#/Professor/put_schools_my
 */
function updateTeacherSchool(schoolCode) {
  return api.put(`/schools/my?school_code=${schoolCode}`);
}

export default {
  getAllSchools,
  updateTeacherSchool,
};
