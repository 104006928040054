<template>
  <v-btn
    color="#F39200"
    width="40px"
    height="40px"
    min-width="0"
    class="rounded-lg open-button"
    @click="openReport"
  >
    <v-icon>mdi-login</v-icon>
  </v-btn>
</template>

<script>
import { mapActions } from "vuex"
export default {
  name:"OpenReportFromQuizAndClass",
  props:{
    classId: {
      type:String,
      required:true
    },
    quizId: {
      type:String,
      required:true
    }
  },
  methods:{
    ...mapActions("exam", ["resultsOfStudents"]),
    async openReport(){
      const response = await this.resultsOfStudents({
        classId:this.classId,
        quizId: this.quizId
      })
      if(response){
        this.$emit("onReportDownloaded", response)
      }
    }
  }
}
</script>

<style scoped>
.report-button{
  text-transform: none;
}
</style>