<template>
  <v-col
    cols="12"
    class="px-4 pb-4"
  >
    <h2 class="quiz-title mb-5">
      Regolamento test: {{ quizTitle }}
    </h2>
    <div
      class="
                      pa-3
                      orange--text orange-border
                    "
    >
      <v-icon color="#F39200">
        mdi-information-outline
      </v-icon>
      Prima di procedere assicurati di avere
      il tempo sufficiente per tentare il
      test. Una volta aperto non sarà più
      possibile tornare indietro.
    </div>
    <v-row
      no-gutters
      justify="space-between"
    >
      <v-col
        class="pa-0 mb-5"
        :class="{
          'pr-5': $vuetify.breakpoint.lgAndUp,
        }"
        lg="4"
        cols="12"
      >
        <IntroductionCard
          :title="'SVOLGIMENTO'"
          :img-name="'rules.webp'"
          :expanded="true"
        >
          <template #content>
            <v-col
              class="white--text pa-0 px-10 card-paragraph"
              cols="12"
            >
              <div class="mb-5">
                <div class="mb-2">
                  <strong>Quanti tentativi ho?</strong>
                </div>
                <span>
                  Puoi tentare il test 1 volta per sessione.
                </span>
              </div>
              <div class="mb-5">
                <div class="mb-2">
                  <strong>Quanto tempo ho a
                    disposizione per fare il
                    test?</strong>
                </div>
                <span>
                  Varia per ogni test,
                  controlla il tempo a disposizione
                  all'interno del box specifico di
                  ogni test.
                </span>
              </div>
              <div class="mb-5">
                <div class="mb-2">
                  <strong>Cosa succede se il tempo a
                    disposizione scade?</strong>
                </div>
                <span>
                  Se il tempo scade e non hai
                  completato tutte le domande,
                  verranno
                  considerate le risposte date
                  fino all'ultimo salvataggio
                  automatico
                  del sistema. Le
                  risposte non date sono
                  calcolate con 0 punti.
                </span>
              </div>
              <div>
                <div class="mb-2">
                  <strong>Cosa succede se va in crash
                    il browser o il mio
                    dispositivo?</strong>
                </div>
                <span>
                  Puoi
                  riprendere il test
                  dall'ultimo salvataggio automatico
                  fatto dal sistema,
                  se avrai ancora tempo a
                  disposizione.
                  Una volta avviato il test, il
                  tempo continuerà a scalare anche
                  in caso di disconnessione.
                </span>
              </div>
            </v-col>
          </template>
        </IntroductionCard>
      </v-col>
      <v-col
        class="pa-0 mb-5"
        :class="{
          'px-5': $vuetify.breakpoint.lgAndUp,
        }"
        lg="4"
        cols="12"
      >
        <IntroductionCard
          :title="'PUNTEGGIO'"
          :img-name="'score.webp'"
          :expanded="true"
        >
          <template #content>
            <v-col
              class="white--text pa-0 px-10 card-paragraph"
              cols="12"
            >
              <div class="mb-5">
                <div class="mb-2">
                  <strong>Come funziona il calcolo del
                    punteggio?</strong>
                </div>
                <ul>
                  <li>
                    <div class="mb-2">
                      Risposta/e corretta/e =
                      assegnati
                      tutti i punti associati a
                      quella domanda
                    </div>
                  </li>
                  <li>
                    <div class="mb-2">
                      Risposta/e
                      errata / almeno una risposta
                      errata
                      in una domanda a risposta multipla
                      = -1 punto
                    </div>
                  </li>
                  <li>
                    <div>
                      Risposta <strong>non data</strong> = 0 punti
                    </div>
                  </li>
                </ul>
              </div>
              <div class="mb-5">
                <div class="mb-2">
                  <strong>Se supero il test posso
                    rifarlo per ottenere un
                    punteggio superiore?</strong>
                </div>
                <span>
                  No, non potrai rifarlo
                </span>
              </div>
              <div>
                <div class="mb-2">
                  <strong>Se non supero il test, posso
                    rifarlo?</strong>
                </div>
                <span>
                  <strong>Si,</strong>
                  puoi ritentarlo
                  <strong>solo se ci sono altre
                    sessioni</strong>
                  in programma.
                </span>
              </div>
            </v-col>
          </template>
        </IntroductionCard>
      </v-col>
      <v-col
        class="pa-0 mb-5"
        :class="{
          'pl-5': $vuetify.breakpoint.lgAndUp,
        }"
        lg="4"
        cols="12"
      >
        <IntroductionCard
          :title="'CERTIFICAZIONI E BADGE'"
          :img-name="'certificate.webp'"
        >
          <template #content>
            <v-col
              class="white--text pa-0 px-10 card-paragraph"
              cols="12"
            >
              <div class="mb-5">
                <div class="mb-2">
                  <strong>Cosa ottengo se supero il
                    test?</strong>
                </div>
                <div class="mb-2">
                  Nella sezione Report puoi
                  scaricare la
                  certificazione e richiedere
                  il badge Credly da
                  condividere sui tuoi profili
                  social.
                </div>
              </div>
              <div>
                <div class="mb-2">
                  <strong>Ci sono certificazioni
                    speciali?</strong>
                </div>
                <span>
                  Si, ottieni una
                  certificazione e un badge Credly
                  speciali se al tuo
                  primo tentativo totalizzi un
                  punteggio che rientra nella
                  soglia di eccellenza.
                </span>
              </div>
            </v-col>
          </template>
        </IntroductionCard>
        <div class="px-3 my-5 mt-5">
          <v-row
            class="mt-8"
            justify="center"
          >
            Per ogni dubbio rivolgiti a:&nbsp;
            <a
              class="
                        orange--text
                        text-decoration-underline
                      "
              href="
                      mailto:'hackersgen@sorint.com'"
            >
              hackersgen@sorint.com</a>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import IntroductionCard from "./IntroductionCard.vue";
export default {
  name: "IntroductionSection",
  components: { IntroductionCard },
  props: {
    quizTitle: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
.orange-border {
  border: 1px solid #f07814;
  border-radius: 15px;
  width: 80%;
}
.quiz-title{
  font-weight: 700;
  font-size: 31.25px;
  line-height: 36px;
  letter-spacing: 1px;
  color: #FFFFFF;
}

.card-paragraph{
  font-weight: 400;
  font-size: 14px;
  line-height: 32px;
}
</style>