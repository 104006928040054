<template>
  <DefaultTable
    :headers="headers"
    :local-values="quizReport.student_results"
    :custom-slots="['outcome', 'downloadResults']"
    :custom-top-action-slots="['topBar']"
  >
    <template #topBar>
      <v-row no-gutters justify="space-between" align="center">
        <v-col align="start" cols="12" md="5">
          <h5 class="quiz-title">Test: {{ quizReport.quiz_title }}</h5>
        </v-col>
        <v-col cols="12" md="7">
          <v-row
            :justify="$vuetify.breakpoint.mdAndUp ? 'end' : 'start'"
            no-gutters
          >
            <DownloadCertificates
              class="mr-5 mt-2"
              :disabled="false"
              @onClick="() => downloadAllCertificates()"
            />
            <DownloadTestSummaries
              class="mt-2"
              :disabled="!quizReport.download_enabled"
              @onClick="() => downloadAllQuiz()"
            />
          </v-row>
        </v-col>
      </v-row>
    </template>
    <template #outcome="{ item }">
      <h4 v-if="!item.quiz_taken" class="outcome-text yellow--text">
        NON EFFETTUATO
      </h4>
      <h4 v-else-if="item.quiz_passed" class="outcome-text green--text">
        PROMOSSO
      </h4>
      <h4 v-else class="outcome-text red--text">NON PASSATO</h4>
    </template>
    <template #downloadResults="{ item }">
      <Download
        :disabled="!quizReport.download_enabled"
        :store-name="'exam'"
        :store-action="'examSummary'"
        :object-to-send="{
          studentId: item.student_id,
          quizId: quizReport.quiz_id,
          filename: 'report',
        }"
      />
    </template>
  </DefaultTable>
</template>

<script>
import { mapActions } from "vuex";
import Download from "../../shared/actions/Download.vue";
import DownloadCertificates from "../../shared/actions/DownloadCertificates.vue";
import DownloadTestSummaries from "../../shared/actions/DownloadTestSummaries.vue";
import DefaultTable from "../../shared/DefaultTable.vue";
export default {
  name: "QuizStudentListTable",
  components: {
    DefaultTable,
    Download,
    DownloadCertificates,
    DownloadTestSummaries,
  },
  props: {
    quizReport: {
      type: Object,
      required: true,
    },
    selectedClassName: {
      type: String,
      required: true,
    },
    selectedClassId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        {
          text: "Nome",
          value: "student_name",
        },
        {
          text: "Cognome",
          value: "student_surname",
        },
        {
          text: "Email",
          value: "student_email",
        },
        {
          text: "Esito",
          value: "outcome",
        },
        {
          text: "Download dei risultati",
          value: "downloadResults",
        },
      ],
    };
  },
  methods: {
    ...mapActions("exam", ["allExamsSummary", "getAllCertificates"]),
    async downloadAllQuiz() {
      this.$logger("downloading quiz");
      try {
        this.isLoadingAllQuiz = true;
        await this.allExamsSummary({
          classId: this.selectedClassId,
          quizId: this.quizReport.quiz_id,
          filename: `Elenco_Esami_${this.quizReport.quiz_title}_${this.selectedClassName}`,
        });
      } catch (e) {
        this.$logger(e);
      } finally {
        this.isLoadingAllQuiz = false;
      }
    },
    async downloadAllCertificates() {
      try {
        this.isLoadingAllCertificates = true;
        await this.getAllCertificates({
          classId: this.selectedClassId,
          quizId: this.quizReport.quiz_id,
          filename: `Elenco_Certificati_${this.quizReport.quiz_title}_${this.selectedClassName}`,
        });
      } catch (e) {
        this.$logger(e);
      } finally {
        this.isLoadingAllCertificates = false;
      }
    },
  },
};
</script>

<style>
.quiz-title {
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #ffffff;
}
.outcome-text {
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
}
</style>
