<template>
  <v-app class="app-container">
    <v-main class="route-wrapper">
      <v-col
        cols="12"
        class="px-5 pb-5"
      >
        <v-card
          class="transparent white--text"
          flat
        >
          <IntroductionSection
            v-if="!isLoading"
            :quiz-title="currentQuiz.title"
          />
          <v-row
            no-gutters
            justify="space-between"
          >
            <v-btn
              dark
              color="red"
              class="rounded-lg ma-4"
              @click="goBack"
            >
              Annulla
            </v-btn>
            <div>
              <span
                v-if="currentQuiz"
                class="text-uppercase"
              >
                <span class="font-weight-bold">durata</span>: {{ currentQuiz.duration }} min
              </span>
              <v-btn
                v-if="$vuetify.breakpoint.mdAndUp"
                dark
                color="#F39200"
                class="ma-4 rounded-lg"
                @click="openStartDialog"
              >
                <v-icon left>
                  mdi-certificate-outline
                </v-icon>
                Avvia test
              </v-btn>
            </div>
          </v-row>
          <span
            v-if="$vuetify.breakpoint.smAndDown"
            class="disclaimer"
          >Il test può essere iniziato solo da desktop</span>
          <v-row />
        </v-card>
      </v-col>
      <Modal
        v-if="isQuizDialogOpen"
        :glassify="true"
        :title="'ASSICURATI DI AVER LETTO LE REGOLE'"
        @closeModal="closeModal"
      >
        <template #content>
          Una volta avviato il test non potrai più tornare
          indietro.
          <h3 class="mt-5 mb-1">
            Fai attenzione al calcolo del punteggio
          </h3>
          Risposta/e corretta/e = assegnati tutti i punti
          associati alla domanda<br>
          Risposta errata / Almeno una risposta errata in
          una domanda a risposta multipla = - 1 punto<br>
          Risposta non data = 0 punti<br>
          <v-row
            justify="end"
            no-gutters
          >
            <v-btn
              large
              dark
              color="orange darken-3"
              class="bit-text ma-4"
              @click="startExam"
            >
              Inizia Esame
            </v-btn>
          </v-row>
        </template>
      </Modal>
    </v-main>
  </v-app>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import Modal from "@cmp-shared/utils/Modal";
import IntroductionSection from "@cmp/platform/exam/IntroductionSection.vue";
export default {
  name: "QuizRoom",
  components: {
    Modal,
    IntroductionSection
  },
  data() {
    return {
      isQuizDialogOpen: false,
      currentQuiz: null,
      isLoading: true,
    };
  },
  async mounted() {
    this.isLoading = true;
    const quiz = await this.getQuizById({
      quizId: this.$route.params.id,
      needsStore: false,
    });
    this.currentQuiz = quiz;
    this.isLoading = false;
  },
  methods: {
    ...mapMutations("toolbar", ["changeTab"]),
    ...mapActions("quiz", ["getQuizById"]),
    goBack() {
      this.changeTab(3);
      this.$router.push({ name: "student-test" });
    },
    openStartDialog() {
      // opening start dialog...
      this.isQuizDialogOpen = true;
    },
    closeModal() {
      this.isQuizDialogOpen = false;
    },
    startExam() {
      // starting exam...
      this.$router.push({
        name: "student-quiz-exam",
        params: { id: this.$route.params.id },
      });
    },
  },
};
</script>

<style scoped>
.disclaimer{
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #98A6AE;
}
</style>
