<template>
  <v-dialog
    v-model="isDialogOpen"
    transition="dialog-top-transition"
    max-width="600"
    persistent
  >
    <v-card v-if="type" class="home-dialog pt-4">
      <v-card-text>
        <v-row justify="end">
          <v-btn icon color="grey" @click="closeDialog">
            <v-icon>mdi-close-circle</v-icon>
          </v-btn>
        </v-row>

        <v-row>
          <v-col align="center" cols="12">
            <h2 class="home-dialog_title">
              {{ selectedDialog.title }}
            </h2>
          </v-col>

          <v-col cols="12">
            <v-img
              class="my-5"
              height="250"
              contain
              :src="require(`@/assets/home/${selectedDialog.image}`)"
              :alt="selectedDialog.image"
            />
          </v-col>

          <v-col align="center" cols="12">
            <p
              class="home-dialog_text"
              v-dompurify-html="selectedDialog.description"
            />
          </v-col>

          <v-col v-if="selectedDialog.file" align="center" cols="12">
            <v-btn
              text
              class="home-dialog_btn"
              target="_blank"
              :href="selectedDialog.file"
            >
              <v-icon>mdi-download</v-icon>
              <strong>Download</strong>
            </v-btn>
          </v-col>

          <v-col align="center" cols="12">
            <v-btn outlined color="#FC4955" @click="closeDialog">
              chiudi
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "HomeDialog",
  props: {
    type: {
      type: String,
      required: true,
    },
    isDialogOpen: {
      type: Boolean,
    },
  },
  data() {
    return {
      dialogs: [
        {
          key: "live",
          title: "live coming soon",
          image: "rocket_live.webp",
          description: `Dal 2 Novembre si apriranno le iscrizioni agli eventi live. Ecco una piccola anteprima.`,
          file: "/docs/live_courses_list.pdf",
        },
        {
          key: "ondemand",
          title: "ondemand coming soon",
          image: "rocket_ondemand.webp",
          description: `L'offerta completa di corsi ondemand sarà disponibile a partire dal <strong>2 Novembre</strong>. <br>Ecco una piccola anteprima.`,
          file: "/docs/ondemand_courses_list.pdf",
        },
        {
          key: "test",
          title: "test coming soon",
          image: "rocket_test.webp",
          description:
            "I test per certificarti saranno disponibili nel giorno di disponibilità dei corsi formativi.",
          file: "",
        },
        {
          key: "report",
          title: "report coming soon",
          image: "rocket_report.webp",
          description: "L'area report sarà disponibile prossimamente.",
          file: "",
        },
      ],
    };
  },
  computed: {
    selectedDialog() {
      return this.type
        ? this.dialogs.filter((dialog) => dialog.key === this.type)[0]
        : {};
    },
  },
  methods: {
    closeDialog() {
      this.$emit("closeDialog");
    },
  },
};
</script>

<style scoped>
.home-dialog {
  background: linear-gradient(
    110deg,
    rgba(150, 150, 150, 0.2) 1.57%,
    rgba(100, 100, 100, 0.1) 100%
  );
  backdrop-filter: blur(10px);
  border-radius: 12px;
}
.home-dialog_title {
  font-family: "outerorbit";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  color: #f4f5f6;
}

@media screen and (max-width: 450px) {
  .home-dialog_title {
    font-size: 20px;
  }
}

.home-dialog_text {
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #f4f5f6;
}

@media screen and (max-width: 450px) {
  .home-dialog_text {
    font-size: 16px;
  }
}

.home-dialog_btn {
  height: 45px;
  background: #f39200;
  border-radius: 8px;
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #f2f2f2;
}
</style>
