<template>
  <v-container class="px-0">
    <v-col class="pa-0">
      <v-row
        v-if="menuRoutes.length"
        no-gutters
        class="mt-5 mb-5 px-3 desktop-menu"
      >
        <DesktopMenuTile
          v-for="menuRoute in menuRoutes"
          :key="menuRoute.text"
          :text="menuRoute.text"
          :url-prefix="urlPrefix"
          :route-name="menuRoute.routeName"
          :is-open="menuRoute.isOpen"
        />
      </v-row>
    </v-col>
  </v-container>
</template>

<script>
import userHandler from "@mixins/userHandler";
import DesktopMenuTile from "./DesktopMenuTile.vue";
export default {
  name: "DesktopMenu",
  components: { DesktopMenuTile },
  mixins: [userHandler],
  data() {
    return {
      menuRoutes: [],
    };
  },
  computed: {
    urlPrefix() {
      if (this.isTeacher) {
        return "teacher";
      } else if (this.isStudent) {
        return "student";
      } else if (this.isAdmin) {
        return "admin";
      } else if (this.isGuest) {
        return "guest";
      } else if (this.isSorint) {
        return "sorint";
      }
      return "";
    },
  },
  created() {
    const initialRoutes = [];
    if (this.isGuest || this.isSorint) {
      initialRoutes.push({
        text: "OnDemand",
        routeName: "ondemand-courses",
        isOpen: true,
      });
    }
    if (this.isGuest || this.isSorint) {
      initialRoutes.push({
        text: "Live",
        routeName: "courses",
        isOpen: true,
      });
    }
    if (false) {
      initialRoutes.push({
        text: "Progetti",
        routeName: "projects",
        isOpen: true,
      });
    }
    if (this.isTeacher) {
      initialRoutes.push({
        text: "Classi",
        routeName: "classes",
        isOpen: true,
      });
    }
    if (false) {
      initialRoutes.push({
        text: "Test",
        routeName: "test",
        isOpen: true,
      });
    }
    if (this.isTeacher) {
      initialRoutes.push({
        text: "Report",
        routeName: "report",
        isOpen: true,
      });
    }
    if (this.isStudent || this.isTeacher) {
      initialRoutes.push({
        text: "Journey",
        routeName: "journey",
        isOpen: true,
      });
    }
    this.menuRoutes = initialRoutes;
  },
};
</script>

<style scoped>
.desktop-menu > :not(:last-child) {
  margin-right: 20px;
}
</style>
