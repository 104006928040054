<template>
  <v-container class="glassify rounded-xl px-7 mt-7 pa-6">
    <v-row justify="end">
      <v-col align="left">
        <h1 class="white--text bit text-uppercase">Il mio profilo</h1>
      </v-col>
      <v-col align="right">
        <v-icon
          class="mr-2"
          color="purple darken-1"
          large
          @click="pushDashboardURL"
        >
          mdi-close-circle
        </v-icon>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4" lg="2" align-self="center">
        <v-row>
          <!-- IMMAGINE PROFILO -->
          <v-col cols="12">
            <v-hover v-slot="{ hover }">
              <v-img
                class="rounded-circle"
                height="100%"
                width="100%"
                data-cy="my-profile-img"
                :src="getAvatarRelodedUrl"
                @click="dialog = true"
              >
                <v-fade-transition>
                  <div
                    v-if="hover"
                    class="pointer bg-opacity d-flex justify-center align-center transition-slow-in-slow-out darken-2 v-card--reveal display-3 white--text full-height"
                  >
                    <p class="text-overline">Modifica o elimina</p>
                  </div>
                </v-fade-transition>
                <template #placeholder>
                  <v-row
                    class="fill-height ma-0 grey-bg"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular indeterminate color="grey lighten-5" />
                  </v-row>
                </template>
              </v-img>
            </v-hover>
          </v-col>
        </v-row>
      </v-col>
      <!-- DATI STUDENTE -->
      <v-col cols="12" md="8" lg="10" align-self="center" class="pa-8">
        <v-row>
          <!-- NOME -->
          <v-col cols="12" md="6">
            <v-text-field v-model="user.name" dark disabled label="Nome" />
          </v-col>
          <!-- COGNOME -->
          <v-col cols="12" md="6">
            <v-text-field
              v-model="user.surname"
              dark
              disabled
              label="Cognome"
            />
          </v-col>
          <!-- MAIL PRINCIPALE -->
          <v-col cols="12" md="6">
            <v-text-field
              v-model="user.email"
              disabled
              dark
              label="Mail Principale"
            />
          </v-col>
          <!-- MAIL PERSONALE -->
          <v-col cols="12" md="6">
            <v-form v-model="form" @submit.prevent="updateEmail">
              <v-text-field
                v-model="secondaMail"
                label="Seconda Mail"
                required
                dark
                :rules="[rules.email, rules.required, emailFreeRule]"
                :hint="
                  user?.access_level === 3
                    ? 'Inserisci la tua email personale: questa mail potrebbe essere utilizzata per eventuali opportunità di lavoro'
                    : null
                "
                persistent-hint
                :loading="
                  emailFree === null &&
                  secondaMail !== '' &&
                  rules.email(secondaMail) === true &&
                  secondaMail !== user.sec_email
                "
                @keydown="onKeyDownEmail"
                @keyup="onKeyUpEmail"
              >
                <template #append>
                  <v-icon v-if="emailFree === true">
                    mdi-check-decagram
                  </v-icon>
                </template>
              </v-text-field>
              <v-checkbox
                v-if="isStudent"
                :disabled="isImpersonated"
                :style="{ opacity: isImpersonated ? '0.5' : '1' }"
                v-model="canContact"
                class="mt-10"
                color="orange"
                dark
              >
                <template #label>
                  <div class="caption white--text">
                    Ho letto e accetto la
                    <span class="privacy-link">
                      <a
                        href="https://www.sorint.com/privacy-policy/"
                        target="_blank"
                        rel="noopener"
                        @click.stop
                      >
                        privacy policy
                      </a>
                    </span>
                    e desidero ricevere comunicazioni da Sorint.Lab per
                    opportunità lavorative e avvisi su iniziative rivolte agli
                    studenti
                  </div>
                </template>
              </v-checkbox>
            </v-form>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="py-3">
      <v-col
        class="d-flex"
        :class="
          $vuetify.breakpoint.xsOnly ? 'justify-space-between' : 'justify-end'
        "
        cols="12"
      >
        <v-btn
          color="purple accent-3"
          class="btn-font rounded-lg white--text"
          @click="dialogChangePassword = true"
        >
          CAMBIO PASSWORD
        </v-btn>
        <v-btn
          dark
          color="success"
          :disabled="
            !isCanContactChanged &&
            (secondaMail === user.sec_email || !form || !emailFree)
          "
          class="btn-font ml-2 rounded-lg"
          @click="updateEmail"
        >
          SALVA
        </v-btn>
      </v-col>
    </v-row>

    <ChangePasswordDialog
      v-if="dialogChangePassword"
      @closeModal="dialogChangePassword = false"
    />

    <Modal
      v-if="dialog"
      title="Immagine Profilo"
      width="600px"
      data-cy="update-profile-img-modal"
      @closeModal="dialog = false"
    >
      <template #content>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col v-if="image" cols="12">
                <Cropper
                  ref="cropper"
                  stencil-component="circle-stencil"
                  class="my-profile-size"
                  :src="image"
                />
              </v-col>
              <v-col cols="12">
                <v-file-input
                  v-model="file"
                  accept="image/png, image/jpeg, image/bmp"
                  placeholder="Carica una nuova immagine profilo"
                  prepend-icon="mdi-camera"
                  data-cy="upload-profile-img-input"
                  label="Immagine"
                  @change="loadImage"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-row justify="center">
            <v-btn
              dark
              color="red darken-1"
              class="rounded-lg mr-4"
              large
              :disabled="
                user.avatar ===
                'https://s4s-landing.sorintdev.it/static/avatar_default.jpg'
              "
              @click="deleteProfilePicture"
            >
              Elimina foto
            </v-btn>
            <v-btn
              color="success"
              dark
              large
              :disabled="image === null"
              class="rounded-lg"
              @click="uploadImage"
            >
              <v-icon class="mr-1"> mdi-check </v-icon>
              SALVA
            </v-btn>
          </v-row>
        </v-card-actions>
      </template>
    </Modal>
  </v-container>
</template>

<script>
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import "vue-advanced-cropper/dist/theme.compact.css";
import { mapActions } from "vuex";
import { sendNotification } from "@utils/notifications";
import store from "@store";
import api from "@services/axiosInstance";
import Modal from "@cmp-shared/utils/Modal";
import ChangePasswordDialog from "@cmp/platform/ChangePasswordDialog";
import matomoTracker from "@mixins/matomoTracker";
import { pushAuthUrl } from "@/utils/externalRouter";
import userHandler from "@mixins/userHandler";

export default {
  name: "MyProfile",
  components: {
    Modal,
    Cropper,
    ChangePasswordDialog,
  },
  mixins: [matomoTracker, userHandler],
  data() {
    return {
      secondaMail: "",
      dialog: false,
      dialogChangePassword: false,
      image: null,
      file: null,
      form: false,
      emailFree: null,
      emailTimeout: null,
      rules: {
        required: (value) => Boolean(value) || "Valore richiesto",
        email: (v) =>
          !v ||
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) ||
          `L'e-mail deve essere valida`,
      },
      initialCanContact: false,
      canContact: false,
    };
  },

  computed: {
    isStudent() {
      return this.user && this.user.access_level === 3;
    },
    isCanContactChanged() {
      if (this.user) {
        if (this.user.access_level === 2) {
          return false;
        }
        return this.canContact !== this.initialCanContact;
      }
      return false;
    },
    emailFreeRule() {
      return this.emailFree !== false || "La mail inserita è già in uso";
    },
  },
  watch: {
    dialog() {
      this.image = null;
      this.file = null;
    },
  },

  created() {
    if (this.user) {
      this.secondaMail = this.user.sec_email;
      this.initialCanContact = this.user.can_contact;
      this.canContact = this.user.can_contact;
    }
  },
  methods: {
    ...mapActions("auth", [
      "uploadAvatarImage",
      "updateStudentProfile",
      "updateProfessorProfile",
      "updateGuestProfile",
      "getMyProfile",
      "deleteMyAvatar",
    ]),
    pushDashboardURL() {
      if (this.isAdmin) {
        pushAuthUrl();
        return;
      } else if (this.isTeacher) {
        this.$router.push({ name: "teacher-courses" });
        return;
      } else if (this.isGuest) {
        this.$router.push({ name: "guest-courses" });
        return;
      } else if (this.isSorint) {
        this.$router.push({ name: "sorint-courses" });
        return;
      } else if (this.isStudent) {
        this.$router.push({ name: "student-courses" });
        return;
      }
    },
    loadImage(file) {
      // Ensure that you have a file before attempting to read it
      if (file) {
        // create a new FileReader to read this image and convert to base64 format
        const reader = new FileReader();
        // Define a callback function to run, when FileReader finishes its job
        reader.onload = (e) => {
          // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
          // Read image as base64 and set to imageData
          this.image = e.target.result;
        };
        // Start the reader job - read file as a data url (base64 format)
        reader.readAsDataURL(file);
      } else {
        this.image = null;
      }
    },
    async deleteProfilePicture() {
      store.commit("loading/startLoading");
      await this.deleteMyAvatar();
      await this.getMyProfile();
      this.reloadAvatar();
      this.dialog = false;
      store.commit("loading/stopLoading");
    },
    async uploadImage() {
      await this.trackEvent("PlatformMyProfilePage", "ChangedPersonalPicture");
      store.commit("loading/startLoading");
      const { canvas } = this.$refs.cropper.getResult();
      let image = null;
      if (canvas) {
        image = await new Promise((resolve) =>
          canvas.toBlob(resolve, "image/jpeg", 0.5)
        );
      }
      await this.uploadAvatarImage(image);
      this.dialog = false;
      this.reloadCounter++;
      this.$emit("reloadToolbarAvatar");
      store.commit("loading/stopLoading");
    },
    checkEmail() {
      return (
        this.user.email.trim().toLowerCase() ===
        this.secondaMail.trim().toLowerCase()
      );
    },
    async updateEmail() {
      if (this.checkEmail()) {
        sendNotification(
          `Inserisci una mail diversa da quella principale.`,
          `warning`
        );
      } else {
        store.commit("loading/startLoading");
        try {
          if (this.user.access_level === 3) {
            await this.updateStudentProfile({
              payload: {
                secondaryMail: this.secondaMail,
                canContact: this.canContact,
              },
            });
          } else if (this.user.access_level === 2) {
            await this.updateProfessorProfile(this.secondaMail);
          } else if (this.user.access_level === 4) {
            await this.updateGuestProfile(this.secondaMail);
          }
        } catch (e) {
          this.$logger(e);
        } finally {
          store.commit("loading/stopLoading");
          this.initialCanContact = this.canContact;
        }
      }
    },
    onKeyDownEmail() {
      if (this.rules.email(this.secondaMail) === true) {
        this.emailFree = null;
      }
      clearTimeout(this.emailTimeout);
    },
    onKeyUpEmail() {
      this.emailTimeout = setTimeout(this.checkEmailFree, 1500);
    },
    async checkEmailFree() {
      if (
        this.rules.email(this.secondaMail) === true &&
        this.secondaMail !== this.user.sec_email
      ) {
        try {
          const { data } = await api.get("user/check-email", {
            params: { email: this.secondaMail },
          });
          this.emailFree = data.is_free;
        } catch (err) {
          /* do nothing */
        }
      }
    },
  },
};
</script>

<style scoped>
.pointer:hover {
  cursor: pointer;
}
.bg-opacity {
  background: rgba(18, 6, 39, 0.3);
}
.btn-font {
  font-family: "Karasuma Gothic", sans-serif;
}
.grey-bg {
  background-color: rgba(100, 100, 100, 0.5);
}
.full-height {
  height: 100%;
}
.my-profile-size {
  max-height: 400px;
}
.privacy-link a,
.privacy-link a:visited,
.privacy-link a:active,
.privacy-link a:focus-within,
.privacy-link a:focus,
.privacy-link a:focus-visible {
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 500;
  display: inline-block;
  font-size: 12px;
  letter-spacing: 0.75px;
  text-decoration-line: underline;
  color: #f4f5f6 !important;
}
</style>
