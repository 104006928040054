<template>
  <DefaultTable
    :key="classId"
    :headers="headers"
    :store-name="'exam'"
    :method="'resultsAggregated'"
    :item-type="{ classId }"
    :custom-slots="['openReport', 'num_students_failed', 'average']"
  >
    <template #openReport="{item}">
      <OpenReportFromQuizAndClass
        :class-id="classId"
        :quiz-id="item.quiz_id"
        @onReportDownloaded="(value) => $emit('onReportDownloaded', value)"
      />
    </template>
    <template #num_students_failed="{item}">
      {{ item.num_students_taken - item.num_students_passed }}
    </template>
    <template #average="{item}">
      {{ item.avg_result }}/{{ item.max_points }}
    </template>
  </DefaultTable>
</template>

<script>
import OpenReportFromQuizAndClass from "../../shared/actions/OpenReportFromQuizAndClass.vue"
import DefaultTable from "../../shared/DefaultTable.vue"
export default {
  name:"QuizAggregatedTable",
  components: { DefaultTable, OpenReportFromQuizAndClass },
  props: {
    classId: {
      type: String,
      required: true,
    },
  },
  emits:["onReportDownloaded"],
  data(){
    return {
      headers:[
        {
          text: "Nome Quiz",
          value: "quiz_title",
        },
        {
          text: "Studenti passati",
          value: "num_students_passed",
        },
        {
          text: "Studenti bocciati",
          value: "num_students_failed",
        },

        {
          text: "Studenti senza tentativi",
          value: "num_students_not_taken",
        },
        {
          text: "Tentativi totali",
          value: "num_tries",
        },
        {
          text: "Media",
          value: "average",
        },
        {
          text: "Apri report",
          value: "openReport",
        },
      ]
    }
  }
}
</script>

<style>

</style>