<template>
  <v-app class="app-container">
    <v-row class="gradient pt-7" justify="center">
      <HeaderS4S />
    </v-row>
    <v-main>
      <v-container>
        <v-row justify="space-around">
          <v-col cols="12" sm="10" md="10" lg="7" xl="5">
            <div class="glassify pa-6 rounded-xl mb-8">
              <v-row>
                <v-col>
                  <h2
                    class="white--text font-weight-light text-center text-sm-left pb-6"
                  >
                    PRIMA DI CONTINUARE SELEZIONA LA TUA SCUOLA:
                  </h2>
                  <v-form v-model="form" class="form-font">
                    <v-row dense>
                      <v-col cols="12" sm="4" class="mb-8">
                        <v-select
                          v-model="selectedRegion"
                          :rules="[rules.required]"
                          :items="regions"
                          label="Regione *"
                          hide-details
                          outlined
                          searchable
                          dark
                          color="white"
                          :loading="loadingSchools"
                          :disabled="loadingSchools"
                          background-color="rgba(255,255,255, .3)"
                          data-cy="select-input-region"
                          @change="resetSelectedRegion"
                        />
                      </v-col>
                      <v-col cols="12" sm="4" class="mb-8">
                        <v-select
                          v-model="selectedProvince"
                          :rules="[rules.required]"
                          :items="selectableProvinces"
                          :disabled="selectableProvinces.length === 0"
                          label="Provincia *"
                          hide-details
                          outlined
                          searchable
                          dark
                          background-color="rgba(255,255,255, .3)"
                          data-cy="select-input-province"
                          @change="resetSelectedProvince"
                        />
                      </v-col>
                      <v-col cols="12" sm="4" class="mb-8">
                        <v-select
                          v-model="selectedCity"
                          :rules="[rules.required]"
                          :items="selectableCities"
                          :disabled="selectableCities.length === 0"
                          label="Città *"
                          hide-details
                          outlined
                          searchable
                          dark
                          background-color="rgba(255,255,255, .3)"
                          data-cy="select-input-city"
                          @change="resetSelectedCity"
                        />
                      </v-col>
                    </v-row>

                    <v-row class="mt-0">
                      <v-col cols="12" sm="12">
                        <v-autocomplete
                          v-model="selectedSchool"
                          :rules="[rules.schoolRequired]"
                          :disabled="selectedCity.length === 0"
                          :items="selectableSchools"
                          label="Scuola *"
                          hint="Se non trovi la tua scuola, invia una mail a hackersgen@sorint.com"
                          item-value="code"
                          :item-text="getFieldText"
                          no-data-text="Scuola non trovata. Invia una mail a hackersgen@sorint.com"
                          outlined
                          searchable
                          dark
                          background-color="rgba(255,255,255, .3)"
                          data-cy="select-input-school"
                        >
                          <template #item="data">
                            <template>
                              <v-list-item-content>
                                <!-- eslint-disable -->
                                <v-list-item-title
                                  v-dompurify-html="getFieldTitle(data.item)"
                                />
                                <!-- eslint-enable -->
                                <v-list-item-subtitle>
                                  {{ getFieldSubtitle(data.item) }}
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </template>
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-col>
              </v-row>
              <v-row class="justify-center">
                <v-btn
                  :loading="loading"
                  color="success"
                  :disabled="!form"
                  dark
                  x-large
                  class="btn-font ma-4"
                  @click="submit"
                >
                  PROCEDI
                </v-btn>
              </v-row>
            </div>
          </v-col>
        </v-row>
        <v-row justify="space-around">
          <v-col cols="12" sm="10" md="10" lg="7" xl="5">
            <Supporters />
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import HeaderS4S from "@cmp/platform/HeaderS4S.vue";
import Supporters from "@cmp/platform/Supporters.vue";
import schoolServices from "@services/schools";
import { mapActions } from "vuex";
import { handleAxiosError } from "@utils/notifications";
import orderBy from "lodash/orderBy";

export default {
  name: "SchoolChoice",
  components: { Supporters, HeaderS4S },
  data() {
    return {
      form: false,
      loading: false,
      loadingSchools: true,
      schools: [],
      regions: [],
      selectedRegion: "",
      selectedProvince: "",
      selectedCity: "",
      selectedSchool: "",
      rules: {
        required: (value) => Boolean(value) || "Valore richiesto",
        schoolRequired: (value) =>
          Boolean(value) ||
          "Valore richiesto. Se non trovi la tua scuola, invia una mail a hackersgen@sorint.com",
      },
    };
  },
  computed: {
    filteredSchoolsByRegions() {
      return this.schools.filter(
        (school) => school.region === this.selectedRegion
      );
    },
    selectableProvinces() {
      return [
        ...new Set(
          this.filteredSchoolsByRegions.map((school) => school.province)
        ),
      ].sort();
    },
    filteredSchoolsByProvinces() {
      return this.filteredSchoolsByRegions.filter(
        (school) => school.province === this.selectedProvince
      );
    },
    selectableCities() {
      return [
        ...new Set(
          this.filteredSchoolsByProvinces.map((school) => school.city)
        ),
      ].sort();
    },
    selectableSchools() {
      return this.filteredSchoolsByProvinces.filter(
        (school) => school.city === this.selectedCity
      );
    },
  },
  async created() {
    try {
      const { data } = await schoolServices.getAllSchools();
      this.loadingSchools = false;
      this.schools = orderBy(data, ["type", "name"], ["asc", "asc"]);
      this.regions = [
        ...new Set(this.schools.map((school) => school.region)),
      ].sort();
    } catch (err) {
      handleAxiosError(`Errore nel caricamento delle scuole.`, err);
    }
  },
  methods: {
    ...mapActions("auth", ["updateTeacherSchool"]),

    getFieldText(item) {
      return `${item.name} (${item.type}) - ${item.address}`;
    },
    getFieldTitle(item) {
      return `<strong>${item.name}</strong> - ${item.type}`;
    },
    getFieldSubtitle(item) {
      return `${item.address}`;
    },
    resetSelectedRegion() {
      this.selectedProvince = "";
      this.selectedCity = "";
      this.selectedSchool = "";
    },
    resetSelectedProvince() {
      this.selectedCity = "";
      this.selectedSchool = "";
    },
    resetSelectedCity() {
      this.selectedSchool = "";
    },
    async submit() {
      this.loading = true;

      try {
        if (!this.form) {
          throw new Error("Controlla i dati");
        }
        await this.updateTeacherSchool(this.selectedSchool);
        this.$router.push({ name: "teacher-courses" });
      } catch (err) {
        /* do nothing */
      }

      this.loading = false;
    },
  },
};
</script>

<style scoped>
.gradient {
  background: rgb(2, 0, 36);
  background: linear-gradient(
    180deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(65, 9, 121, 1) 0%,
    rgba(65, 9, 121, 0) 100%
  );
}

h2,
h4 {
  font-family: "Karasuma Gothic", sans-serif;
}

.info-piattaforma {
  background: rgba(55, 46, 73, 0.4);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(11.5px);
  -webkit-backdrop-filter: blur(11.5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  padding: 10px;
  color: white !important;
  height: fit-content;
}
</style>
