<template>
  <v-container fill-height class="pa-4 pl-md-2">
    <v-row :id="`msg-list-${roomId}`" class="chat-msg" @scroll="onScroll">
      <!-- NESSAGGI PRESENTI -->
      <v-col v-if="messages.length > 0">
        <transition-group name="opacity">
          <div
            v-for="(message, i) in messages"
            :key="`message-${i}`"
            class="my-3"
          >
            <div
              v-if="
                i === 0 ||
                message.ts.getDate() !== messages[i - 1].ts.getDate() ||
                message.ts.getMonth() !== messages[i - 1].ts.getMonth()
              "
              class="d-flex justify-center"
            >
              <span
                class="my-5 text-subtitle-2 text-center justify-center px-4 py-1 rounded-pill white--text"
                :class="projects ? 'project-date' : 'dashboard-date'"
              >
                {{
                  `${message.ts.getDate()} ${message.ts.toLocaleString(
                    "default",
                    { month: "long" }
                  )}`
                }}
              </span>
            </div>
            <span
              v-if="
                message.u._id !== userId &&
                (i === 0 || message.u.name !== messages[i - 1].u.name)
              "
              class="d-block text-caption"
              :class="message.u._id === userId ? 'text-right' : 'text-left'"
            >
              {{ message.u.name }}
            </span>
            <div
              v-if="message.u._id === userId"
              class="d-flex flex-column msg-text-wrapper align-end"
            >
              <v-tooltip v-model="message.show_time" left>
                <template #activator="{ on, attrs }">
                  <div
                    class="text-body-2 d-block rounded-xl rounded-tr-sm pa-3"
                    :class="
                      projects
                        ? 'projects-personal-msg'
                        : 'dashboard-personal-msg'
                    "
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ message.msg }}
                  </div>
                </template>
                <span>
                  {{
                    `${pad(message.ts.getHours())}:${pad(
                      message.ts.getMinutes()
                    )}`
                  }}
                </span>
              </v-tooltip>
            </div>
            <div v-else class="d-flex flex-column msg-text-wrapper align-start">
              <v-tooltip v-model="message.show_time" right>
                <template #activator="{ on, attrs }">
                  <div
                    class="text-body-2 d-block rounded-xl rounded-tl-sm pa-3 msg"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ message.msg }}
                  </div>
                </template>
                <span>{{
                  `${pad(message.ts.getHours())}:${pad(
                    message.ts.getMinutes()
                  )}`
                }}</span>
              </v-tooltip>
            </div>
          </div>
        </transition-group>
      </v-col>
      <!-- NESSUN MESSAGGIO -->
      <v-col v-else>
        <span class="text-caption d-block text-center">Nessun messaggio</span>
      </v-col>
    </v-row>
    <v-row class="chat-bottom mx-1">
      <v-col cols="10">
        <v-textarea
          v-model="text"
          solo
          class="rounded-lg"
          no-resize
          rows="2"
          row-height="15"
          label="Scrivi un messaggio"
          @keyup.enter.prevent="sendMsg"
        />
      </v-col>
      <v-col cols="2" align="right">
        <v-btn
          :disabled="text.length === 0"
          :class="projects ? 'deep-purple accent-1' : 'orange darken-2'"
          fab
          @click="sendMsg"
        >
          <v-icon class="white--text"> mdi-send </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import chatService from "@services/chat";

export default {
  name: "Chat",
  props: {
    roomId: {
      type: String,
      required: true,
    },
    publicChannel: {
      type: Boolean,
      required: true,
    },
    projects: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      messages: [],
      text: "",
      show: false,
      atTheBottom: "",
    };
  },
  computed: {
    ...mapState("chat", ["userId", "authToken"]),
    ...mapGetters("config", ["rocketchatWsUrl"]),
  },
  created() {
    this.startWs();
    this.getMessages();
  },
  methods: {
    pad(n) {
      return `0${n}`.slice(-2);
    },

    scrollMsg(scrollMode) {
      setTimeout(() => {
        const elmnt = document.getElementById(`msg-list-${this.roomId}`);
        if (scrollMode === "smooth") {
          elmnt.scrollTo({
            top: elmnt.scrollHeight,
            behavior: "smooth",
          });
        } else {
          elmnt.scrollTop = elmnt.scrollHeight;
        }
      }, 0);
    },
    async getMessages() {
      const { data } = await chatService.loadRoomMessages(
        this.roomId,
        this.publicChannel
      );
      // messages with "t" property are of the kind "user X entered the channel", we don't want to show them
      this.messages = data.messages
        .filter((m) => !m.t)
        .map((m) => ({
          msg: m.msg,
          u: m.u,
          ts: new Date(m.ts),
          show_time: false,
        }))
        .reverse();

      await chatService.setMessagesRead(this.roomId);
      this.scrollMsg("");
    },

    sendMsg() {
      if (this.text.length > 1) {
        const data = {
          msg: "method",
          method: "sendMessage",
          id: "unique-id",
          params: [
            {
              _id: `id${window.performance.now()}`,
              rid: this.roomId,
              msg: this.text,
            },
          ],
        };
        this.connection.send(JSON.stringify(data));

        this.messages = [
          ...this.messages,
          {
            msg: this.text,
            u: { _id: this.userId },
            ts: new Date(),
            show_time: false,
          },
        ];
      }
      this.text = "";
      this.scrollMsg("smooth");
    },
    async startWs() {
      this.connection = new WebSocket(this.rocketchatWsUrl);

      this.connection.onmessage = this.onMessageReceived;

      this.connection.onclose = this.startWs;

      this.connection.onopen = await this.onConnectionOpen;
    },
    onMessageReceived(event) {
      const data = JSON.parse(event.data);
      if (
        data.msg === "changed" &&
        data.collection === "stream-room-messages" &&
        data.fields.args[0].u._id !== this.userId
      ) {
        const [msg] = data.fields.args;
        if (!msg.t) {
          this.messages = [
            ...this.messages,
            {
              msg: msg.msg,
              u: { _id: msg.u._id, name: msg.u.name },
              ts: new Date(msg.ts.$date),
            },
          ];
          if (this.atTheBottom) {
            this.scrollMsg("smooth");
          }
        }
      }
    },
    onConnectionOpen() {
      let data = {
        msg: "connect",
        version: "1",
        support: ["1"],
      };
      this.connection.send(JSON.stringify(data));

      data = {
        msg: "method",
        method: "login",
        id: `id${window.performance.now()}`,
        params: [{ resume: this.authToken }],
      };

      this.connection.send(JSON.stringify(data));

      data = {
        msg: "method",
        method: "joinRoom",
        id: `id${window.performance.now()}`,
        params: [this.roomId, "joinCode"],
      };
      this.connection.send(JSON.stringify(data));

      data = {
        msg: "sub",
        id: `id${window.performance.now()}`,
        name: "stream-room-messages",
        params: [this.roomId, false],
      };

      this.connection.send(JSON.stringify(data));
    },
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      this.atTheBottom = scrollTop + clientHeight >= scrollHeight;
    },
  },
};
</script>

<style scoped lang="scss">
.dashboard-date {
  background-color: #bf360c;
}
.project-date {
  background-color: #12005e;
}
.chat-msg {
  overflow-y: scroll;
  height: calc(100% - 8rem);
  width: 100%;
}

.chat-bottom {
  width: 100%;
  height: 100%;
}

.msg {
  background-color: #cccccc;
  color: black;
  border-radius: 10px;
  padding: 5px;
  max-width: 70%;
}

.projects-personal-msg {
  background-color: rgb(99, 100, 144);
  color: white;
  border-radius: 10px;
  padding: 5px;
  max-width: 70%;
}

.dashboard-personal-msg {
  background-color: #ef6c00;
  color: white;
  border-radius: 10px;
  padding: 5px;
  max-width: 70%;
}

.msg-text-wrapper {
  width: 100%;
}

.opacity-enter {
  opacity: 0;
}

.opacity-enter-active {
  transition: all 0.7s ease-out;
}
</style>
