<template>
  <v-container
    class="px-3"
    fluid
  >
    <v-row>
      <v-col cols="12">
        <v-container class="transparent px-0 py-0">
          <CoursesExpanded :type="type" />
        </v-container>
      </v-col>
      <!-- <v-col
        v-if="$vuetify.breakpoint.mdAndUp"
        cols="12"
        md="4"
      >
        <PreviewList
          v-if="type !== 2"
          :id="2"
          icon="mdi-video"
          :type="2"
          @change="change"
        />
        <PreviewList
          v-if="type !== 1"
          :id="1"
          icon="mdi-filmstrip-box-multiple"
          :type="1"
          @change="change"
        />
        <PreviewList
          v-if="type !== 3"
          :id="3"
          icon="mdi-bookmark-check"
          :type="3"
          @change="change"
        />
      </v-col> -->
    </v-row>
    <v-bottom-navigation
      v-if="$vuetify.breakpoint.smAndDown && isTeacher"
      app
      color="white"
      background-color="orange_sorint"
      :value="0"
    >
      <v-btn
        large
        @click="type = 2"
      >
        <span>Live</span> <v-icon>mdi-video</v-icon>
      </v-btn>
      <v-btn
        large
        @click="type = 3"
      >
        <span>Tutti</span> <v-icon>mdi-bookmark-check</v-icon>
      </v-btn>

      <v-btn
        large
        @click="type = 1"
      >
        <span>On Demand</span>
        <v-icon>mdi-filmstrip-box-multiple</v-icon>
      </v-btn>

      <v-btn
        large
        @click="$emit('openChat')"
      >
        <span>Chat</span> <v-icon>mdi-chat</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </v-container>
</template>

<script>
/**
 * TYPE 1: ON DEMAND
 * TYPE 2: LIVE
 * TYPE 3: SUBSCRIBED
 */

import CoursesExpanded from "../../components/shared/Courses/CoursesExpanded.vue";
import { mapActions } from "vuex";
import userHandler from "@mixins/userHandler";

export default {
  name: "StudentCourses",
  components: {
    CoursesExpanded,
    /* PreviewList, */
  },
  mixins: [userHandler],
  data: () => ({
    type: 2,
  }),
  methods: {
    ...mapActions("courses", ["getAllCourses"]),
    change(type) {
      this.type = type;
    },
  },
};
</script>

<style scoped>
h1 {
  font-size: 2rem;
}

.subtitle {
  font-size: 1rem;
}
</style>
