<template>
  <v-card
    class="rounded-lg glassify pa-0 mb-3 overflow-hidden"
    dark
  >
    <v-container>
      <v-row
        align="start"
        no-gutters
      >
        <v-col class="d-flex justify-space-between">
          <h3 class="text-left quiz-title font-weight-bold">
            {{ quiz.title }}
          </h3>
          <v-btn
            v-if="isStudentWithClassroom && quiz.subscribed && !quiz.already_passed"
            id="subscribed_ribbon"
            outlined
            color="#7FBAF4"
            class="mt-0 px-1 subscribed-btn rounded-lg"
          >
            ISCRITTO
          </v-btn>
          <v-btn
            v-else-if="isStudentWithClassroom && quiz.already_passed"
            id="promoted_ribbon"
            color="success"
            class="mt-0 px-1 subscribed-btn rounded-lg"
          >
            PROMOSSO
          </v-btn>
        </v-col>
      </v-row>
      <v-row
        align="center"
        no-gutters
      >
        <v-col cols="9">
          <h5 class="text-left font-weight-regular my-0 quiz-description">
            {{ quiz.description }}
          </h5>
        </v-col>
      </v-row>
      <v-row
        no-gutters
        class="mt-6"
      >
        <v-col
          cols="12"
          md="3"
          class="px-0"
        >
          <p class="text-left my-0">
            <strong class="quiz-features-title">Tempo a disposizione: </strong>
            <span class="quiz-features-description"> {{ quiz.duration }} min</span>
          </p>

          <p class="text-left my-0">
            <strong class="quiz-features-title">Numero domande: </strong>
            <span class="quiz-features-description"> {{ quiz.questions_number }}</span>
          </p>
          <p class="text-left my-0">
            <strong class="quiz-features-title">Punteggio minimo: </strong>
            <span class="quiz-features-description"> {{ quiz.min_points }} punti su {{ quiz.max_points }}</span>
          </p>
          <p class="text-left my-0">
            <strong class="quiz-features-title">Tentativi per sessione: </strong>
            <span class="quiz-features-description"> 1</span>
          </p>
        </v-col>
        <v-col
          cols="12"
          :md="quiz.sessions.length ? 1 : 3"
        >
          <p class="text-left my-0">
            <strong
              v-if="quiz.sessions.length"
              class="quiz-sessions-title"
            >
              Sessioni di test:
            </strong>
            <strong
              v-else
              class="quiz-sessions-title"
            >
              Nessuna sessione programmata
            </strong>
          </p>
        </v-col>
        <v-col
          cols="12"
          md="6"
          class="pl-6"
          :class="$vuetify.breakpoint.smAndDown && quiz.sessions.length ? 'my-3' : ''"
        >
          <v-row>
            <v-chip-group
              column
            >
              <v-chip
                v-for="(s, key) in quiz.sessions"
                :key="key"
                dark
                disabled
                class="readonly"
                outlined
                color="#7FBAF4"
              >
                Dal {{ formatDate(s.begin_ts, false) }} al {{ formatDate(s.end_ts, true) }}
              </v-chip>
            </v-chip-group>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          align-self="end"
          class="d-flex justify-end"
        >
          <v-row
            v-if="isStudentWithClassroom"
            justify="end"
            no-gutters
          >
            <v-btn
              v-if="!quiz.subscribed"
              :width="$vuetify.breakpoint.smAndDown ? '100%' : ''"
              :class="$vuetify.breakpoint.smAndDown ? 'mt-2' : ''"
              large
              dark
              outlined
              class="quiz-btn"
              @click="requestSubscription(quiz.id)"
            >
              <v-icon
                size="24px"
                left
              >
                mdi-login
              </v-icon>
              Richiedi Iscrizione
            </v-btn>
            <v-tooltip
              v-if="quiz.subscribed && !quiz.already_tried && !quiz.unfinished"
              bottom
              :disabled="canTryQuiz"
            >
              <template #activator="{ on }">
                <div
                  :class="{ 'button-wrapper': $vuetify.breakpoint.smAndDown }"
                  v-on="on"
                >
                  <v-btn
                    id="exec_test"
                    :disabled="!canTryQuiz"
                    :width="$vuetify.breakpoint.smAndDown ? '100%' : ''"
                    :class="$vuetify.breakpoint.smAndDown ? 'mt-2' : ''"
                    large
                    dark
                    class="quiz-btn"
                    @click="goToQuiz"
                  >
                    <v-icon
                      size="24px"
                      left
                    >
                      mdi-certificate-outline
                    </v-icon>
                    Effettua Test
                  </v-btn>
                </div>
              </template>
              <span>
                Non ci sono sessioni attive o hai già sostenuto l'esame in questa
                sessione
              </span>
            </v-tooltip>
            <v-btn
              v-if="canRetryQuiz"
              id="retry_test"
              :width="$vuetify.breakpoint.smAndDown ? '100%' : ''"
              :class="$vuetify.breakpoint.smAndDown ? 'mt-2' : ''"
              large
              dark
              class="quiz-btn"
              @click="goToQuiz"
            >
              <v-icon
                size="24px"
                left
              >
                mdi-certificate-outline
              </v-icon>
              Ritenta Test
            </v-btn>
            <v-btn
              v-if="quiz.subscribed && quiz.unfinished"
              id="continue_test"
              :width="$vuetify.breakpoint.smAndDown ? '100%' : ''"
              :class="$vuetify.breakpoint.smAndDown ? 'mt-2' : ''"
              large
              dark
              class="quiz-btn"
              @click="continueQuiz"
            >
              Prosegui Test
            </v-btn>
            <v-btn
              v-if="quiz.already_passed"
              id="retry_test"
              large
              dark
              color="#59D657"
              class="rounded-lg"
              :to="{name: 'student-report'}"
            >
              <v-icon
                left
                dark
              >
                mdi-trophy-outline
              </v-icon>
              Hai passato il test con successo!
            </v-btn>
          </v-row>

          <v-row
            v-if="isTeacher"
            justify="end"
          >
            <v-btn
              id="subscribe_classes"
              :width="$vuetify.breakpoint.smAndDown ? '100%' : ''"
              :class="$vuetify.breakpoint.smAndDown ? 'mt-4' : 'mr-3'"
              large
              dark
              class="quiz-btn mb-2"
              @click="openSubscribeModal"
            >
              Iscrivi classi
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-divider />
      </v-row>
      <v-row
        v-if="isTeacher"
        id="teacher_section"
        align="center"
        justify="center"
      >
        <v-col>
          <span>Classi iscritte:</span>
        </v-col>
        <v-col
          id="subscribed_classes"
          cols="12"
          md="9"
        >
          <v-chip-group show-arrows>
            <v-chip
              v-for="classroom in quiz.subscribed_classrooms"
              :key="classroom.id"
              outlined
              disabled
              class="readonly"
              color="yellow"
            >
              <span class="text-uppercase">
                {{ classroom.name }}
              </span>
            </v-chip>
          </v-chip-group>
        </v-col>
      </v-row>
    </v-container>
    <QuizSubscribeModal
      v-if="showSubscribeModal"
      :quiz-id="quiz.id"
      :subscribed-classrooms="quiz.subscribed_classrooms"
      @closeModal="closeSubscribeModal"
    />
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import QuizSubscribeModal from "./QuizSubscribeModal.vue";
import userHandler from "@mixins/userHandler";
import dayjs from "dayjs";

export default {
  name: "QuizCard",
  components: { QuizSubscribeModal },
  mixins: [userHandler],
  props: {
    quiz: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    showSubscribeModal: false,
  }),
  computed: {
    isStudentWithClassroom() {
      return this.isStudent && this.hasSchool;
    },
    canTryQuiz() {
      return (
        this.quiz.subscribed &&
        this.quiz.is_session_open &&
        !this.quiz.already_done_in_session &&
        !this.quiz.already_tried
      );
    },
    canRetryQuiz() {
      return (
        this.quiz.subscribed &&
        this.quiz.is_session_open &&
        !this.quiz.already_done_in_session &&
        this.quiz.already_tried &&
        !this.quiz.unfinished &&
        !this.quiz.already_passed
      );
    }
  },

  methods: {
    ...mapActions("quiz", ["requestQuizSubscription"]),
    formatDate(date, isEnd) {
      if (isEnd) {
        return dayjs.unix(date).subtract(1, "day").format("DD-MM-YYYY");
      }
      return dayjs.unix(date).format("DD-MM-YYYY");
    },
    closeSubscribeModal() {
      this.showSubscribeModal = false;
    },
    openSubscribeModal() {
      this.showSubscribeModal = true;
    },
    requestSubscription(quizId) {
      this.requestQuizSubscription(quizId);
    },
    goToQuiz() {
      this.$logger("inizia quiz");
      this.$router.push({
        name: "student-quiz-room",
        params: { id: this.quiz.id },
      });
    },
    continueQuiz() {
      // starting exam...
      this.$router.push({
        name: "student-quiz-exam",
        params: { id: this.quiz.id },
      });
    },
  },
};
</script>

<style scoped>
.corner-ribbon {
  width: 200px;
  position: absolute;
  top: 35px;
  right: -50px;
  text-align: center;
  line-height: 30px;
  letter-spacing: 1px;
  color: #f0f0f0;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.button-wrapper{
  width: 100%;
}

.quiz-title {
  font-size: 20px;
  line-height: 28px;
}

.quiz-description {
  font-size: 16px;
  line-height: 18px;
  color: #98A6AE;
}

.quiz-features-title {
  line-height: 24px;
  color: #F4F5F6;
}

.quiz-sessions-title {
  font-size: 15.5px;
  line-height: 24px;
  color: #F4F5F6;
}

.quiz-features-description {
  color: #98A6AE;
}

.quiz-btn {
  color: #F39200;
  border: 2px solid #F39200;
  border-radius: 12px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.subscribed-btn {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  pointer-events: none;
}

.readonly {
  opacity: 1;
}
</style>
