import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import { setupAxiosInstance } from "@/services/axiosInstance";
import { setupPublicAxiosInstance } from "@/services/publicAxiosInstance";
import { setupLivesAxiosInstance } from "@/services/livesAxiosInstance";
import { setupLiveTrackerAxiosInstance } from "@/services/axiosLiveTrackerInstance";
import { setupOndemandTrackerAxiosInstance } from "@/services/axiosOndemandTrackerInstance";
import { setupRocketchatAxios } from "@services/rocketChatAxios";
import VueMatomo from "vue-matomo";
import Rollbar from "rollbar";
import Carousel3d from "vue-carousel-3d";
import VueCookies from "vue-cookies";
import * as Sentry from "@sentry/vue";
import VueDOMPurifyHTML from "vue-dompurify-html";
import VueHead from "vue-head";

Vue.config.productionTip = false;

Vue.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: true,
});

Vue.use(VueHead, {
  head: {
    meta: [
      {
        name: "X-Frame-Options",
        content: "DENY",
      },
      {
        httpEquiv: "Content-Security-Policy",
        content: "frame-ancestors 'self';",
      },
    ],
  },
})

Vue.use(VueCookies);

Vue.use(VueDOMPurifyHTML, {
  default: {
    USE_PROFILES: { html: false },
  },
});

Vue.use(VueMatomo, {
  host: "https://stats.hackersgen.com/",
  siteId: process.env.NODE_ENV === "production" ? 1 : 2,
  trackerFileName: "matomo",
  router,
  enableLinkTracking: true,
  requireConsent: false,
  trackInitialView: true,
  disableCookies: false,
  enableHeartBeatTimer: false,
  heartBeatTimerInterval: 15,
  debug: false,
  // userId: undefined,
  // cookieDomain: undefined,
  // domains: undefined,
  preInitActions: [],
});

// Carousel in Event Area
Vue.use(Carousel3d);

store.dispatch("config/loadData").then(() => {
  setupAxiosInstance();
  setupPublicAxiosInstance();
  setupLivesAxiosInstance();
  setupLiveTrackerAxiosInstance();
  setupOndemandTrackerAxiosInstance();
  setupRocketchatAxios();

  Vue.prototype.$rollbar = new Rollbar({
    accessToken: store.getters["config/rollbarToken"],
    captureUncaught: true,
    captureUnhandledRejections: true,
  });

  Vue.prototype.$logger = function (msg, additionalParam = null) {
    if (process.env.NODE_ENV !== "production") {
      // eslint-disable-next-line no-unused-expressions
      additionalParam ? console.log(msg, additionalParam) : console.log(msg);
    }
  };

  Vue.config.errorHandler = (err, vm) => {
    vm.$rollbar.error(err);
    throw err; // rethrow
  };

  const cookieDomain = store.getters["config/cookieDomain"] ?? "";

  // added false to avoid using sentry for low limits problem
  const isEnabled =
    cookieDomain !== "localhost" &&
    cookieDomain != ".sorintdev.it" &&
    cookieDomain != ".sorintuat.it" &&
    false;

  Sentry.init({
    Vue,
    dsn: "https://24daee7b49c7f823977297d7d5ef9fdc@o1384609.ingest.sentry.io/4506240941031424",
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.25,
    enabled: isEnabled,
    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });

  const _paq = (window._paq = window._paq || []);
  _paq.push(["trackVisibleContentImpressions"]);

  new Vue({
    name: "HackersGen",
    vuetify,
    router,
    store,
    render: (h) => h(App),
  }).$mount("#app");
});
