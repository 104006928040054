<template>
  <v-app class="app-container">
    <Modal
      v-if="false"
      title="Creazione classi"
      @closeModal="showNoClassroomsModal = false"
    >
      <template #content>
        <span class="text-body-1">
          <br /><span class="font-weight-bold">Non</span> hai ancora classi
          registrate! <br />
          Crea la tua prima classe nella sezione
          <span class="font-weight-bold text-uppercase">gestione classi</span>,
          in basso a sinistra
        </span>
      </template>
    </Modal>
    <v-main :class="dashboardBackground">
      <Toolbar />
      <div v-if="loaded" class="route-wrapper">
        <router-view @openChat="openProfChat" />
      </div>

      <!-- CHAT FOR TEACHERS
          De-comment ChatCard and v-tooltip in order to re-enable it.
          If no changes requested in a reasonable time from now (19 Oct 2022), feel free to delete the following commented code :)
      -->

      <!-- <ChatCard
        v-if="showProfChat"
        class="prof-chat"
        :class="minimizeChat ? 'hidden' : ''"
        :channel-id="profGroupId"
        :public-channel="false"
        :show-minimize="false"
        :projects="false"
        name="Chat Professori"
        @closeChatCard="minimizeChat = true"
      />

      <v-tooltip
        v-if="(!showProfChat || minimizeChat) && $vuetify.breakpoint.lgAndUp"
        top
      >
        <template #activator="{ on, attrs }">
          <v-btn
            fab
            fixed
            bottom
            right
            color="secondary_accent"
            x-large
            class="white--text"
            v-bind="attrs"
            v-on="on"
            @click="openProfChat"
          >
            <v-badge
              class="prof-chat-btn"
              :content="profUnreads"
              :value="profUnreads"
              color="red"
              overlap
            >
              <v-icon>mdi-chat</v-icon>
            </v-badge>
          </v-btn>
        </template>
        <span>Chat professori</span>
      </v-tooltip> -->
    </v-main>
  </v-app>
</template>

<script>
/* components */
import Toolbar from "@cmp-shared/toolbar/Toolbar";
import Modal from "@cmp-shared/utils/Modal";

import store from "@store";

import { mapMutations, mapState } from "vuex";
import ChatCard from "../../components/chat/ChatCard";
import dashboardPage from "@/mixins/dashboardPage";
import userHandler from "@mixins/userHandler";
export default {
  name: "TeacherDashboard",
  components: {
    ChatCard,
    Toolbar,
    Modal,
  },
  mixins: [dashboardPage, userHandler],
  data() {
    return {
      showProfChat: false,
      loaded: false,
      minimizeChat: false,
      showNoClassroomsModal: false,
      isSchoolContestDialogOpened: false,
    };
  },
  computed: {
    ...mapState("chat", ["profUnreads", "profGroupId"]),
  },
  async created() {
    store.dispatch("chat/getProfUnreads"); // do not await

    const promises = [];
    if (!store.state.classrooms.classLoaded) {
      promises.push(store.dispatch("classrooms/getClassroomsProf"));
    }
    // if courses have not been loaded
    if (store.state.courses.courses.length === 0) {
      promises.push(store.dispatch("courses/getAllCourses"));
    }

    if (promises.length > 0) {
      store.commit("loading/startLoading");
      try {
        await Promise.all(promises);
        this.loaded = true;
      } catch (e) {
        // do nothing
      }
    } else {
      this.loaded = true;
    }
    if (store.state.classrooms.classrooms.length === 0) {
      this.showNoClassroomsModal = true;
    }
    store.commit("loading/stopLoading");
  },

  methods: {
    ...mapMutations("chat", ["SET_PROF_UNREADS"]),
    openProfChat() {
      this.showProfChat = true;
      this.minimizeChat = false;
      this.SET_PROF_UNREADS(0);
    },
    closeModal() {
      this.isSchoolContestDialogOpened = false;
    },
  },
};
</script>

<style scoped>
.prof-chat {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 1000;
}

.theme--light.v-tabs-items {
  background-color: transparent;
}

.avenir {
  font-family: Avenir, Helvetica, Arial, sans-serif;
}

.content-chat {
  display: flex-end;
  height: 100%;
}
#absolute {
  position: absolute;
  z-index: 100;
  bottom: 10vh;
  right: 3vw;
}
.card-container-chat {
  height: 500px;
}

.hidden {
  display: none;
}
</style>
