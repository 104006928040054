<template>
  <Modal
    title="ISCRIVI CLASSI"
    @closeModal="$emit('closeModal')"
  >
    <template #content>
      <div
        v-if="
          getClassroomsNotAlreadyRegistered(id).length ===
            0 &&
            getClassroomsAlreadyRegistered(id).length === 0
        "
        class="mt-2 mb-5"
      >
        <span class="text-h6">
          Non hai ancora creato nessuna classe! Prima di
          iscrivere le tue classi, vai nella tab CLASSI
          per crearle ed invitare i tuoi studenti.
        </span>
      </div>
      <v-row>
        <v-col
          v-if="
            getClassroomsNotAlreadyRegistered(id).length > 0
          "
        >
          <h3
            class="
              bit-text
              font-weight-light
              px-4
              mt-3
              mb-10
            "
          >
            Classi da iscrivere:
          </h3>
          <v-row
            v-for="(
              classroom, index
            ) of getClassroomsNotAlreadyRegistered(id)"
            :key="index"
            class="pl-10"
          >
            <v-col>
              <span class="text-body-1 text-uppercase">
                {{ classroom.name }}
              </span>
            </v-col>
            <v-col>
              <v-btn
                outlined
                rounded
                color="yellow"
                small
                @click="
                  addCourseToClassroom({
                    classroomId: classroom.id,
                    courseId: id,
                  })
                "
              >
                ISCRIVI
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          v-if="
            getClassroomsAlreadyRegistered(id).length > 0
          "
        >
          <h3
            class="
              bit-text
              font-weight-light
              px-4
              mt-3
              mb-10
            "
          >
            Classi gia' iscritte:
          </h3>

          <v-row
            v-for="(
              classroom, index
            ) of getClassroomsAlreadyRegistered(id)"
            :key="index"
            class="pl-10"
          >
            <v-col>
              <span class="text-body-1 text-uppercase">
                {{ classroom.name }}
              </span>
            </v-col>
            <v-col>
              <v-btn
                outlined
                rounded
                color="red"
                @click="
                  unsubscribeCourse({
                    classroomId: classroom.id,
                    courseId: id,
                  })
                "
              >
                ANNULLA ISCRIZIONE
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </template>
  </Modal>
</template>

<script>
import Modal from "@cmp-shared/utils/Modal";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "SubscribeClassesModal",
  components: { Modal },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters("classrooms", [
      "getClassroomsAlreadyRegistered",
      "getClassroomsNotAlreadyRegistered",
    ]),
  },
  methods: {
    ...mapActions("courses", [
      "addCourseToClassroom",
      "unsubscribeCourse",
    ]),
  },
};
</script>
