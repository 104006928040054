import learningPathsService from "@services/learningPaths";
import { handleAxiosError, sendNotification } from "@utils/notifications";
import OndemandCourse from "@/classes/ondemandCourse";

const initialState = () => ({
  ondemandCourses: [],
});

const state = initialState();

const mutations = {
  resetState(state) {
    Object.assign(state, initialState());
  },
  /**
   * Mutation that creates courses from BE
   *
   * @param {Array} ondemandCourses
   * @returns {null}
   */
  SET_ONDEMAND_COURSES(state, { ondemandCourses }) {
    state.ondemandCourses = [];
    for (const ondemandCourse of ondemandCourses) {
      const {
        id,
        title,
        description,
        authors,
        thumbnail_url,
        published,
        archived,
        points,
        internship_minutes,
        external_resource_link,
        order_num,
        sections,
      } = ondemandCourse;
      state.ondemandCourses.push(
        new OndemandCourse(
          id,
          title,
          description,
          authors,
          thumbnail_url,
          published,
          archived,
          points,
          internship_minutes,
          external_resource_link,
          order_num,
          sections
        )
      );
    }
  },
  UPDATE_ONDEMAND_COURSE(state, ondemandCourse) {
    const index = state.ondemandCourses.findIndex(
      (c) => c.id === ondemandCourse.id
    );
    state.ondemandCourses[index] = ondemandCourse;
  },
};

const actions = {
  async getOndemandCourses({ commit }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      const { data } = await learningPathsService.getOndemandCourses();
      return data;
    } catch (err) {
      await handleAxiosError(
        `Errore durante il caricamento dei corsi ondemand`,
        err
      );
      return null;
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },

  async getLearningPathsByClassroomId({ commit }, { classId }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      const { data } = await learningPathsService.getLearningPathsByClassId(
        classId
      );
      return data;
    } catch (err) {
      await handleAxiosError(`Errore durante il caricamento dei report`, err);
      return null;
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async updateLearningPathVideo(_context, id) {
    try {
      const response = await learningPathsService.updateLearningPathVideo(id);
      return response;
    } catch (e) {
      return null;
    }
  },
  async getOndemandById(_context, { id }) {
    try {
      const { data } = await learningPathsService.getOndemandById(id);
      const {
        title,
        description,
        authors,
        thumbnail_url,
        published,
        archived,
        points,
        internship_minutes,
        external_resource_link,
        order_num,
        sections,
        tj_entity_id,
      } = data;
      const names = authors.map((author) => author.split("%?%")[0]);
      const roles = authors.map((author) => author.split("%?%")[1]);
      const speakers = [{ name: "", role: "" }];
      [speakers[0].name] = names;
      [speakers[0].role] = roles;
      for (let i = 1; i < authors.length; i++) {
        speakers.push({ name: names[i], role: roles[i] });
      }
      const course = {
        id,
        title,
        description,
        authors: speakers,
        thumbnailUrl: thumbnail_url,
        published,
        archived,
        points,
        internshipMinutes: internship_minutes / 60,
        externalResourceLink: external_resource_link,
        orderNum: order_num,
        sections,
        tj_entity_id,
      };
      return course;
    } catch (err) {
      await handleAxiosError(`Errore nel caricamento del corso ondemand`, err);
      return null;
    }
  },
  async deleteOndemandCourse({ commit }, { id }) {
    try {
      commit("loading/startloading", {}, { root: true });
      await learningPathsService.deleteOndemandCourse(id);
      sendNotification("Corso ondemand eliminato con successo!", "success");
    } catch (err) {
      await handleAxiosError(
        `Problema durante l'eliminazione del corso ondemand`,
        err
      );
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async getOndemandReportByClassId({ commit }, { classId, learningPathId }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      const { data } = await learningPathsService.getOndemandReportByClassId({
        classId,
        learningPathId,
      });
      return data;
    } catch (err) {
      await handleAxiosError(`Errore nel recupero del report`, err);
      return null;
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },

  async downloadOndemandOverviewReportExcel(
    { commit },
    { classId, learningPathId, learningPathName }
  ) {
    try {
      commit("loading/startLoading", {}, { root: true });
      const report =
        await learningPathsService.downloadOndemandOverviewReportExcel({
          classId,
          learningPathId,
        });
      const blob = new Blob([report.data]);
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = (e) => {
        const a = document.createElement("a");
        a.download = `${learningPathName
          .split(" ")
          .join("_")}_onDemandReport.xlsx`;
        a.href = e.target.result;
        a.click();
      };
    } catch (err) {
      await handleAxiosError(`Errore nello scaricamento del report`, err);
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },

  async completeVideo({ commit }, { id }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      const data = await learningPathsService.completeVideo(id);
      return data;
    } catch (err) {
      await handleAxiosError("Errore nel completamento del video");
      return (data = "error");
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
