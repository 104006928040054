var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-expansion-panels',{attrs:{"dark":""}},_vm._l((_vm.courseSections),function(course,courseIndex){return _c('v-expansion-panel',{key:course.id,staticClass:"mb-5 transparent course-list-wrapper",attrs:{"active-class":"course-list-wrapper-clicked"}},[_c('v-expansion-panel-header',{staticClass:"course-list-header",attrs:{"active-class":"course-list-clicked"}},[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-start align-center",class:_vm.$vuetify.breakpoint.mdAndDown ? 'pl-0' : '',attrs:{"cols":"3"}},[_c('span',{staticClass:"wrapper-number"},[_vm._v(_vm._s(courseIndex + 1))])]),_c('v-col',{attrs:{"cols":"9"}},[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-start pb-0 px-0",attrs:{"cols":"12"}},[_c('span',{staticClass:"course-paragraph number-of-videos"},[_vm._v(_vm._s(course.resources.length)+" risorse")])]),_c('v-col',{staticClass:"pb-0 pt-2 px-0",attrs:{"cols":"12"}},[_c('h3',{staticClass:"course-title"},[_vm._v(" "+_vm._s(course.name)+" ")])])],1)],1)],1)],1),_c('v-expansion-panel-content',[_c('v-row',_vm._l((course.resources),function(resource){return _c('v-col',{key:resource.id,staticClass:"d-flex justify-end px-0",attrs:{"cols":"12"}},[(
                _vm.isImpersonated &&
                resource.type === 'video_lesson' &&
                resource.unlocked
              )?_c('v-switch',{attrs:{"disabled":resource.completed},on:{"click":function($event){return _vm.setVideoAsCompleted(resource)}},model:{value:(resource.completed),callback:function ($$v) {_vm.$set(resource, "completed", $$v)},expression:"resource.completed"}}):_vm._e(),_c('v-card',{staticClass:"rounded-lg video-title-wrapper pb-2 px-2 mt-2",class:[
                { 'resource-clicked': _vm.selectedResource === resource.id },
                { 'completed-resource': resource.completed },
              ],attrs:{"disabled":_vm.isStudent &&
                (!resource.unlocked ||
                  (resource.type === 'assessment' && resource.completed)),"width":"100%","height":"44"},on:{"click":function($event){return _vm.showInfo(resource)}}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"pr-0 py-0 mt-3",attrs:{"cols":"1"}},[_c('v-checkbox',{staticClass:"pb-0",class:{ 'resource-not-checked': !resource.completed },attrs:{"ripple":false,"readonly":"","dense":""},model:{value:(resource.completed),callback:function ($$v) {_vm.$set(resource, "completed", $$v)},expression:"resource.completed"}})],1),_c('v-col',{attrs:{"cols":"9"}},[_c('span',{staticClass:"course-paragraph",class:{ 'white--text': resource.completed }},[_vm._v(_vm._s(resource.title))])]),_c('v-col',{attrs:{"cols":"2"}},[_c('v-icon',{attrs:{"left":"","color":resource.completed ? 'f4f5f6' : '#98A6AE'}},[_vm._v(" "+_vm._s(_vm.iconSelector(resource.type))+" ")])],1)],1)],1)],1)}),1)],1)],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }