<template>
  <div class="time-wrapper" :class="timerColor">
    <span class="exam-timer">{{ timeLeft }}</span>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";

export default {
  name: "QuizExamTimer",
  props: {
    bus: {
      type: Vue,
      required: true,
    },
  },
  data() {
    return {
      time: 0,
      timerInterval: null,
      expireWarning: false,
      expired: false,
      now: 0,
    };
  },
  computed: {
    ...mapGetters("exam", {
      examDuration: "duration",
      examTimeLeft: "time_left",
    }),

    timeLeft() {
      const minutes = Math.max(0, Math.floor(this.time / 60));
      const seconds = Math.max(0, Math.floor(this.time % 60))
        .toString()
        .padStart(2, "0");

      return `${minutes}:${seconds}`;
    },

    timerColor() {
      if (this.time > 60 * 5) {
        return "green-background";
      } else if (this.time <= 60 * 5 && this.time > 60) {
        return "yellow-background";
      }
      return "red-background";
    },
  },
  watch: {
    time() {
      if (this.time <= 0 && !this.expired) {
        this.expired = true;
        this.timerInterval = clearInterval(this.timerInterval);
        this.$emit("expired");
      } else if (this.time <= 120 && !this.expireWarning) {
        this.expireWarning = true;
        this.$emit("expireWarning");
      }
    },
  },

  created() {
    if (!this.examDuration) {
      this.$destroy();
    }

    this.$logger("created QuizExamTimer");
    this.timerInterval = this.setupTimerInterval();
    this.bus.$on("stopTimer", () => {
      this.timerInterval = clearInterval(this.timerInterval);
    });
  },

  beforeDestroy() {
    this.$logger("destroy QuizExamTimer");
    this.timerInterval = clearInterval(this.timerInterval);
  },

  methods: {
    setupTimerInterval() {
      this.time = this.examTimeLeft;
      this.now = Date.now();

      return setInterval(this.updateTimer, 250);
    },
    updateTimer() {
      const currentTime = Date.now();
      const timeDifference = currentTime - this.now;
      this.now = currentTime;
      this.time = this.time - timeDifference / 1000;
    },
  },
};
</script>

<style scoped>
.time-wrapper {
  width: 203px;
  height: 76px;
  text-align: center;
  border-radius: 12px;
}

.green-background {
  background: #59d657;
}

.yellow-background {
  background: yellow;
}

.red-background {
  background: red;
}

.exam-timer {
  font-weight: 500;
  font-size: 55px;
  line-height: 28px;
  color: #ffffff;
  display: inline-block;
  padding-top: 22px;
}
</style>
