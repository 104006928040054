<template>
  <v-app class="app-container">
    <v-main>
      <Toolbar />
      <!-- COURSES MAIN -->
      <div
        v-if="loaded"
        class="route-wrapper"
      >
        <router-view />
      </div>
    </v-main>
  </v-app>
</template>

<script>
/* components */
import Toolbar from "@cmp-shared/toolbar/Toolbar";
import store from "@store";
import dashboardPage from "@/mixins/dashboardPage";
export default {
  name: "DashboardSorint",
  components: {
    Toolbar,
  },
  mixins: [dashboardPage],
  data() {
    return {
      loaded: false,
    };
  },
  async created() {
    if (store.state.courses.courses.length === 0) {
      try {
        await store.dispatch("courses/getAllCourses");
        this.loaded = true;
      } catch (e) {
        // do nothing
      }
    } else {
      this.loaded = true;
    }
  },
};
</script>

<style scoped>
.theme--light.v-tabs-items {
  background-color: transparent;
}

.avenir {
  font-family: Avenir, Helvetica, Arial, sans-serif;
}
.content-chat {
  display: flex-end;
  height: 100%;
}
.card-container-chat {
  height: 500px;
}

.v-toolbar__extension {
  display: block !important;
}

</style>
