<template>
  <v-btn
    color="#F39200"
    class="white--text certificate-btn"
    :disabled="disabled"
    :dark="disabled"
    @click="() => $emit('onClick')"
  >
    <img src="@assets/exam/tests.webp" alt="downloads all tests" class="mr-1" />
    Scarica tutti i test
  </v-btn>
</template>

<script>
export default {
  name: "DownloadTestSummaries",
  props: {
    disabled: {
      type: Boolean,
    },
  },
  emits: ["onClick"],
};
</script>

<style></style>
