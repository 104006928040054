<template>
  <v-container 
    class="pt-0"
  >
    <v-row
      no-gutters
    >
      <v-col
        order="2"
        order-lg="1"
        cols="12"
        lg="12"
        class="pa-0"
      >
        <v-row :class="isStudent ? 'mb-5' : ''">
          <v-col
            cols="12"
            md="9"
          >
            <h1
              class="test-title"
              :class="$vuetify.breakpoint.smAndDown ? 'text-center' : 'text-left'"
            >
              Lista test
            </h1>
          </v-col>
          <v-col
            cols="12"
            md="3"
            align-self="end"
            class="d-flex justify-end"
          >
            <v-btn
              id="quiz-rules"
              color="#F39200"
              class="rounded-lg"
              :width="$vuetify.breakpoint.smAndDown ? '100%' : ''"
              dark
              data-cy="quiz-rules-btn"
              target="_blank"
              href="/docs/hackersgen_regolamento_quiz.pdf"
              @click="
                () =>
                  trackEvent(
                    'PlatformTestPage',
                    'DownloadRuleBook'
                  )
              "
            >
              <img
                src="@assets/landingPage/download.svg"
                alt="download"
                class="mr-2"
              >
              REGOLAMENTO
            </v-btn>
          </v-col>
        </v-row>

        <v-col
          cols="12"
          class="pa-0 mt-2"
        >
          <v-row justify="center">
            <v-col v-if="isTeacher">
              <v-alert
                text
                outlined
                dense
                color="orange"
                icon="mdi-alert-circle-outline"
                class="alert-header"
              >
                Al superamento del quiz lo studente potrà
                ottenere la certificazione; in caso di esito
                negativo lo studente che ha effettuato il
                test della prima sessione potrà effettuare
                un nuovo tentativo nel corso della seconda
                sessione. Per gli studenti che hanno
                effettuato il quiz esclusivamente nella
                seconda sessione non è previsto un secondo
                tentativo.
              </v-alert>
            </v-col>
            <v-col v-if="isGuest">
              <v-alert
                text
                outlined
                dense
                border="left"
                color="orange"
                width=""
                icon="mdi-alert-circle-outline"
              >
                <p class="alert-text">
                  Per poter accedere ai test è necessario
                  avere un profilo studente associato ad una
                  classe. Se non riesci ad accedere scrivi
                  a: hackersgen@sorint.com
                </p>
              </v-alert>
            </v-col>
          </v-row>
        </v-col>

        <QuizCard
          v-for="quiz in quizzes"
          :key="quiz.id"
          :quiz="quiz"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import QuizCard from "@cmp-quiz/QuizCard.vue";
import store from "@store";
import matomoTracker from "@mixins/matomoTracker";

export default {
  name: "QuizList",
  components: {
    QuizCard,
  },
  mixins: [matomoTracker],
  computed: {
    ...mapGetters("quiz", ["quizzes"]),
  },
  async created() {
    await store.dispatch("quiz/getQuizzes");
  },
};
</script>

<style scoped>
.test-title {
  font-weight: 700;
  font-size: 48.83px;
  line-height: 50px;
  letter-spacing: 1px;
  color: #ffffff;
}

.alert-text {
  align-self: center;
  padding-left: 3px;
  padding-top: 6px;
}
.alert-header {
  border-radius: 12px !important;
}
</style>
