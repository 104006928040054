import api from "@services/axiosInstance";

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/swagger/index.html#/Quiz/get_quiz
 */
function getQuizzes() {
  return api.get("/quiz");
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/swagger/index.html#/Quiz/get_quiz__id_
 */
function getQuiz(quizId) {
  return api.get(`/quiz/${quizId}`);
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/index.html#/Quiz/get_quiz_session
 */
function getAllSessions() {
  return api.get("/quiz/session");
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/swagger/index.html#/Quiz/post_quiz_subscription__id_
 */
function requestQuizSubscription(quizID) {
  return api.post(`/quiz/subscription/${quizID}`);
}

export default {
  getQuizzes,
  getQuiz,
  getAllSessions,
  requestQuizSubscription,
};
