<template>
  <v-app class="app-container">
    <v-main>
      <div class="toolbox">
        <v-tooltip left>
          <template #activator="{ on, attrs }">
            <v-btn
              fab
              dark
              color="green accent-4"
              v-bind="attrs"
              @click="readonly = !readonly"
              v-on="on"
            >
              <v-icon>
                {{ readonly ? "mdi-pencil-off" : "mdi-pencil" }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{
            readonly
              ? `Disattiva modalità "sola lettura"`
              : `Attiva modalità "sola lettura"`
          }}</span>
        </v-tooltip>

        <QuizExamTimer
          v-if="examId"
          :bus="bus"
          class="mr-4"
          @expireWarning="boostSaving"
          @expired="finishExam(true)"
        />

        <div class="last-save">
          {{ lastSave }}
        </div>
      </div>

      <v-container class="pt-10 mb-10">
        <v-row no-gutters justify="start" class="mb-5">
          <h1 class="test-title">TEST: {{ examTitle }}</h1>
        </v-row>

        <v-col
          v-for="question in examQuestions"
          :key="question.id"
          class="pa-0 mb-5"
        >
          <v-card class="quiz-card white--text pa-2">
            <v-col class="pa-2 question-number grey--text">
              <span>Domanda {{ question.number + 1 }}</span>
              <span
                v-if="question.type === 'multiple_answers'"
                class="white--text"
              >
                * per questa domanda è possibile selezionare più risposte
              </span>
              <span class="float-right mr-2"
                ><strong> ({{ question.points }} punti)</strong></span
              >
            </v-col>
            <v-col
              class="pa-2 question-title"
              v-dompurify-html="question.text"
            />

            <v-col class="pa-2">
              <template v-if="question.type === 'multiple_answers'">
                <v-col
                  v-for="answer in question.answers"
                  :key="answer.id"
                  class="pa-0 ma-0"
                >
                  <v-checkbox
                    :value="answer.id"
                    :label="answer.text"
                    :readonly="readonly"
                    :disabled="readonly"
                    :input-value="isChecked(question.id, answer.id)"
                    dark
                    class="pa-0 ma-0"
                    @change="onChangeCheckbox($event, question.id, answer.id)"
                  />
                </v-col>
              </template>

              <template v-if="question.type === 'single_answer'">
                <v-radio-group
                  v-model="answers[question.id]"
                  :readonly="readonly"
                  :disabled="readonly"
                  class="pa-0 ma-0"
                >
                  <v-radio
                    v-for="answer in question.answers"
                    :key="answer.id"
                    :label="answer.text"
                    :value="answer.id"
                    active-class="answer-selected"
                    color="#F39200"
                    dark
                    class="ma-1"
                  />
                </v-radio-group>
                <a
                  v-if="!readonly"
                  class="white--text text-decoration-underline"
                  @click="clearAnswer(question.id)"
                  >Cancella risposta</a
                >
              </template>
            </v-col>
          </v-card>
        </v-col>

        <v-col align="center">
          <v-btn
            v-if="!readonly"
            color="green accent-4 white--text bit-text"
            class="ma-2"
            @click="enterReadOnlyMode"
          >
            Vai al riepilogo
          </v-btn>
          <v-btn
            v-if="readonly"
            color="green accent-4 white--text bit-text"
            class="ma-2"
            @click="readonly = false"
          >
            Modifica risposte
          </v-btn>
          <v-btn
            v-if="readonly"
            :disabled="!atLeastOneAnswer"
            :dark="!atLeastOneAnswer"
            color="orange darken-3 white--text bit-text"
            class="ma-2"
            @click="showFinishModal"
          >
            Termina il test
          </v-btn>
        </v-col>
      </v-container>
      <ConfirmFinishQuizModal
        v-if="finishModalOpen"
        :answers="answers"
        @onCloseModal="finishModalOpen = false"
        @onFinishExam="finishExam(false)"
      />
      <QuizResultModal v-if="resultsModalOpen && examResult" />
    </v-main>
  </v-app>
</template>

<script>
import Vue from "vue";
import { mapGetters, mapActions } from "vuex";
import { sendNotification } from "@utils/notifications";
import QuizExamTimer from "@cmp/platform/exam/QuizExamTimer";
import QuizResultModal from "@cmp/platform/exam/QuizResultModal.vue";
import ConfirmFinishQuizModal from "@cmp/platform//exam/ConfirmFinishQuizModal.vue";

export default {
  name: "QuizExam",
  components: {
    QuizExamTimer,
    QuizResultModal,
    ConfirmFinishQuizModal,
  },
  data() {
    return {
      answers: {},
      saveInterval: null,
      boostInterval: null,
      readonly: false,
      bus: new Vue(),
      lastSaveTime: Date.now(),
      finishModalOpen: false,
      resultsModalOpen: false,
    };
  },
  computed: {
    ...mapGetters("exam", {
      examId: "id",
      examTitle: "title",
      examQuestions: "questions",
      examResult: "result",
    }),

    atLeastOneAnswer() {
      for (const key of Object.keys(this.answers)) {
        if (
          this.answers[key] instanceof Array &&
          this.answers[key].length > 0
        ) {
          return true;
        } else if (!(this.answers[key] instanceof Array) && this.answers[key]) {
          return true;
        }
      }
      return false;
    },

    lastSave() {
      const time = Intl.DateTimeFormat("it", {
        timeStyle: "short",
      }).format(this.lastSaveTime);
      return `Last save ${time}`;
    },
  },

  async beforeMount() {
    this.$logger("beforeMount QuizExam");
    await this.beginExam();
  },

  beforeDestroy() {
    this.$logger("destroy QuizExam");
    this.resetInterval(this.saveInterval);
    this.resetInterval(this.boostInterval);
    this.resetState();
  },
  methods: {
    ...mapActions("exam", [
      "startExam",
      "saveAnswers",
      "terminateExam",
      "resetState",
    ]),

    async beginExam() {
      const quizId = this.$route.params.id;
      try {
        await this.startExam(quizId);
        await this.populateAnswers();
        this.saveInterval = setInterval(() => this.saveAnswersFn(), 30000);
        return Promise.resolve();
      } catch (e) {
        this.$router.push("/student/courses");
        return Promise.reject(new Error(e));
      }
    },

    populateAnswers() {
      try {
        const tmp = {};
        this.examQuestions.forEach((question) => {
          if (question.type === "multiple_answers") {
            tmp[question.id] = question.answers
              .filter((answer) => answer.selected)
              .map((answer) => answer.id);
          } else if (question.type === "single_answer") {
            const result = question.answers
              .filter((answer) => answer.selected)
              .map((answer) => answer.id);
            tmp[question.id] = result[0] || null;
          }
        });
        this.answers = tmp;
        return Promise.resolve();
      } catch (e) {
        return Promise.reject(new Error("populateAnswers failed", e));
      }
    },

    async saveAnswersFn() {
      const prepareData = () => {
        const result = {
          exam_id: this.examId,
          answers: [],
        };
        for (const questionId of Object.keys(this.answers)) {
          if (this.answers[questionId] && this.answers[questionId].length > 0) {
            if (typeof this.answers[questionId] === "string") {
              result.answers.push({
                question_id: questionId,
                selected: [this.answers[questionId]],
              });
            } else {
              result.answers.push({
                question_id: questionId,
                selected: this.answers[questionId],
              });
            }
          }
        }
        return result;
      };

      if (this.atLeastOneAnswer) {
        try {
          await this.saveAnswers(prepareData());
          this.lastSaveTime = Date.now();
        } catch (e) {
          this.$logger(`saveAnswersFn failed ${e}`);
        }
      }
    },

    isChecked(questionId, answerId) {
      if (Object.keys(this.answers).length > 0) {
        const result = this.answers[questionId].includes(answerId);
        if (result) {
          return answerId;
        }
      }
      return null;
    },

    onChangeCheckbox(value, questionId, answerId) {
      const tmp = { ...this.answers };
      if (value) {
        tmp[questionId].push(answerId);
      } else {
        tmp[questionId] = this.answers[questionId].filter(
          (answer) => answer !== answerId
        );
      }
      this.answers = tmp;
    },

    clearAnswer(questionId) {
      const tmp = { ...this.answers };
      tmp[questionId] = null;
      this.answers = tmp;
    },

    enterReadOnlyMode() {
      this.readonly = true;
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },

    boostSaving() {
      this.$logger("expire warning emitted");
      this.resetInterval(this.saveInterval);
      this.boostInterval = setInterval(() => this.saveAnswersFn(), 10000);
    },

    async showFinishModal() {
      await this.saveAnswersFn();
      this.finishModalOpen = true;
    },

    async finishExam(timeExpired) {
      this.resetInterval(this.saveInterval);
      this.resetInterval(this.boostInterval);

      try {
        if (!this.timeExpired) {
          await this.saveAnswersFn();
        }
        await this.terminateExam(this.examId);
        if (timeExpired) {
          sendNotification(
            "Tempo scaduto, le tue risposte sono state salvate automaticamente",
            "success"
          );
        }
        this.bus.$emit("stopTimer");
        this.finishModalOpen = false;
        this.resultsModalOpen = true;
      } catch (e) {
        this.$logger(`finishExam failed${e}`);
      }
    },

    resetInterval(intervalId) {
      try {
        clearInterval(intervalId);
      } catch (e) {
        this.$logger(`Failed to clearInterval ${e}`);
      }
    },
  },
};
</script>

<style scoped>
.test-title {
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 700;
  font-size: 31.25px;
  line-height: 36px;
  /* identical to box height, or 115% */

  letter-spacing: 1px;

  color: #ffffff;
}
.quiz-card {
  background: linear-gradient(
      109.46deg,
      rgba(1, 0, 46, 0.3) 1.57%,
      rgba(1, 0, 46, 0.3) 100%
    ),
    linear-gradient(
      109.46deg,
      rgba(201, 201, 201, 0.4) 1.57%,
      rgba(196, 196, 196, 0.1) 100%
    );
  border-radius: 12px;
}
.toolbox {
  /* position and layout */
  position: fixed;
  z-index: 1000;
  right: 20px;
  top: 200px;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;

  /* display */
  display: flex;

  /* typography */
  font-weight: bold;
  color: white;
}
.question-number {
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  /* identical to box height, or 116% */

  /* Grey/400 */

  color: #98a6ae;
}
.last-save {
  /* typography */
  text-decoration: underline;
}
.question-title {
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 28px;
  /* identical to box height, or 98% */

  color: #ffffff;
}

.answer-selected {
  font-weight: 700;
}
</style>
