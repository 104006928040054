<template>
  <v-container>
    <v-row justify="center" no-gutters>
      <v-col class="pa-0">
        <h2 class="home-title mb-10">Home</h2>
        <h4 class="home-subtitle mb-15">
          Ti diamo il benvenuto nel pannello Home della community HackersGen!
          <br />Da qui potrai scegliere quali operazioni svolgere sulla nostra
          piattaforma!
        </h4>
        <h3 class="section-title mb-10">Corsi e Progetti {{ dialogType }}</h3>
        <v-row
          no-gutters
          :justify="$vuetify.breakpoint.mdAndUp ? 'start' : 'center'"
          class="mb-15"
          :class="{ 'menu-wrapper': $vuetify.breakpoint.mdAndUp }"
        >
          <PlatformHomePagePrimaryMenuCard
            v-for="primaryMenuRoute in primaryMenuRoutes"
            :id="primaryMenuRoute.id"
            :key="primaryMenuRoute.id"
            :class="{ 'ma-2': $vuetify.breakpoint.smAndDown }"
            :title="primaryMenuRoute.title"
            :text="primaryMenuRoute.text"
            :route-name="primaryMenuRoute.routeName"
            :is-open="primaryMenuRoute.isOpen"
            @onSectionClosed="showDialog"
          />
        </v-row>
        <h3 v-if="isTeacher" class="section-title mb-10">Gestione e Report</h3>
        <v-row
          no-gutters
          :justify="$vuetify.breakpoint.mdAndUp ? 'start' : 'center'"
          :class="{ 'menu-wrapper': $vuetify.breakpoint.mdAndUp }"
        >
          <PlatformHomePageSecondaryMenuCard
            v-for="secondaryMenuRoute in secondaryMenuRoutes"
            :id="secondaryMenuRoute.id"
            :key="secondaryMenuRoute.key"
            :class="{ 'ma-2': $vuetify.breakpoint.smAndDown }"
            :title="secondaryMenuRoute.title"
            :text="secondaryMenuRoute.text"
            :route-name="secondaryMenuRoute.routeName"
            :is-open="secondaryMenuRoute.isOpen"
            @onSectionClosed="showDialog"
          />
        </v-row>
      </v-col>
    </v-row>

    <TechJourneyPopup
      v-if="showTechJourney && !tjUnlocked"
      @close-dialog="
        () => {
          showTechJourney = false;
          tjUnlocked = true;
        }
      "
    />
    <ReportPopup
      v-if="showReportPopup && isTeacher"
      @close-dialog="
        () => {
          showReportPopup = false;
        }
      "
    />

    <HomeDialog
      :is-dialog-open="isDialogOpen"
      :type="dialogType"
      @closeDialog="closeDialog"
    />
  </v-container>
</template>

<script>
import userHandler from "@mixins/userHandler";
import PlatformHomePagePrimaryMenuCard from "./PlatformHomePagePrimaryMenuCard.vue";
import PlatformHomePageSecondaryMenuCard from "./PlatformHomePageSecondaryMenuCard.vue";
import TechJourneyPopup from "@cmp/platform/TechJourneyPopup.vue";
import ReportPopup from "@cmp/platform/ReportPopup.vue";
import HomeDialog from "@cmp/platform/HomeDialog.vue";
import api from "@services/axiosInstance";

export default {
  name: "PlatformHomePage",
  components: {
    PlatformHomePagePrimaryMenuCard,
    PlatformHomePageSecondaryMenuCard,
    HomeDialog,
    TechJourneyPopup,
    ReportPopup,
  },
  mixins: [userHandler],
  data() {
    return {
      primaryMenuRoutes: [],
      secondaryMenuRoutes: [],
      dialogType: "",
      isDialogOpen: false,
      showTechJourney: true,
      showReportPopup: true,
      tjUnlocked: true,
    };
  },
  mounted() {
    const techJourneyTimeStamp = localStorage.getItem(
      "tech-journey-last-popup"
    );
    if (techJourneyTimeStamp) {
      const THIRTY_DAYS = 2592000000;
      if (Date.now() - techJourneyTimeStamp > THIRTY_DAYS) {
        localStorage.removeItem("tech-journey-last-popup");
        this.showTechJourney = true;
      }
    } else {
      this.getTJStatus();
    }
  },
  computed: {
    urlPrefix() {
      if (this.isTeacher) {
        return "teacher";
      } else if (this.isStudent) {
        return "student";
      } else if (this.isAdmin) {
        return "admin";
      } else if (this.isGuest) {
        return "guest";
      } else if (this.isSorint) {
        return "sorint";
      }
      return "";
    },
  },
  created() {
    const initialPrimaryRoutes = [];
    if (this.isSorint || this.isGuest) {
      initialPrimaryRoutes.push({
        id: "ondemand",
        text: "Abbiamo raccolto e reso disponibile ondemand tutti i corsi HackersGen del passato! Seguili quando vuoi e certificati con noi!",
        title: "OnDemand",
        routeName: "ondemand-courses",
        isOpen: true,
      });
    }

    if (this.isGuest || this.isSorint) {
      initialPrimaryRoutes.push({
        id: "live",
        text: "Scopri la nostra lista corsi, segna le date delle live streaming che ti interessano di più e impara in aule interattive con i professionisti Sorint.Lab!",
        title: "Live",
        routeName: "courses",
        isOpen: true,
      });
    }

    if (this.isStudent || this.isTeacher) {
      initialPrimaryRoutes.push({
        id: "journey",
        text: "Abbiamo creato un percorso formativo che ti permetterà di acquisire conoscenze specializzate e competenze pratiche, aumentando la tua professionalità!",
        title: "Journey",
        routeName: "/auth/:token",
        isOpen: true,
      });
    }

    if (false) {
      initialPrimaryRoutes.push({
        id: "projects",
        text: "Scopri i progetti che hanno partecipato all’edizione 2022 dello school contest “Premio GFMarilli”! Per la prossima edizione aspettiamo anche te!",
        title: "Progetti",
        routeName: "projects",
        isOpen: true,
      });
    }
    const initialSecondaryRoutes = [];
    if (this.isTeacher) {
      initialSecondaryRoutes.push({
        id: "classes",
        text: "Crea e gestisci le tue classi: in quest’area potrai rimanere aggiornato in tempo reale su tutte le attività formative svolte dai tuoi studenti!",
        title: "Gestione Classi",
        routeName: "/auth/:token",
        isOpen: true,
      });
    }
    if (false) {
      initialSecondaryRoutes.push({
        id: "test",
        text: "Vuoi certificarti con noi? Per ogni corso in pagina saranno rese disponibili sessioni di test aperte a tutti gli studenti. Mostra a tutti le tue competenze!",
        title: "Test",
        routeName: "test",
        isOpen: true,
      });
    }

    if (this.isTeacher) {
      initialSecondaryRoutes.push({
        id: "report",
        text: "Rimani aggiornato sulle ore di corso seguite e certificate in pagina, sui tuoi risultati nei test e sulle varie attività svolte con noi.",
        title: "Report",
        routeName: "report",
        isOpen: true,
      });
    }
    this.primaryMenuRoutes = initialPrimaryRoutes;
    this.secondaryMenuRoutes = initialSecondaryRoutes;
  },
  methods: {
    showDialog(key) {
      this.dialogType = key;
      this.isDialogOpen = true;
    },
    closeDialog() {
      this.isDialogOpen = false;
    },
    // checks whether the student has already logged in to tech journey
    async getTJStatus() {
      const response = await api.get("/tech-journey/get-status");
      if (!response.data.is_ok) {
        this.tjUnlocked = false;
        return;
      }
      this.tjUnlocked = true;
    },
  },
};
</script>

<style scoped>
.home-title {
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 700;
  font-size: 48.83px;
  line-height: 50px;
  letter-spacing: 1px;
  color: #ffffff;
}

.home-subtitle {
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 22px;
  color: #98a6ae;
}

.section-title {
  font-family: "outerorbit", "Orbitron";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  text-transform: uppercase;
  color: #f4f5f6;
}

.menu-wrapper > :not(:last-child) {
  margin-right: 60px;
  margin-bottom: 30px;
}
</style>
