import Quiz from "@classes/quiz";
import TeacherQuiz from "@classes/teacherQuiz";
import StudentQuiz from "@classes/studentQuiz";
import GuestQuiz from "@classes/guestQuiz";
import quizService from "@/services/quiz";
import { handleAxiosError, sendNotification } from "@utils/notifications";
import Session from "../../classes/session";

const initialState = () => ({
  quizzes: [],
  selectedQuiz: {},
  sessions: [],
});

const state = initialState();

const getters = {
  quizzes(state) {
    return state.quizzes;
  },
  /**
   * Getter that returns quizzes by id
   *
   * @param  {string} id
   * @returns {Object} quiz
   */
  getQuizById: (state) => (id) => state.quizzes.find((quiz) => quiz.id === id),
  /**
   * Getter that returns selected quiz
   *
   * @returns {Object} quiz
   */
  selectedQuiz: (state) => state.selectedQuiz,
  /**
   * Getter that returns sessions
   *
   * @returns {Object} session
   */
  sessions: (state) => state.sessions,
};

const mutations = {
  resetState(state) {
    Object.assign(state, initialState());
  },
  /**
   * Mutation that creates quizzes from BE
   *
   * @param {Array} quizzes
   * @returns {null}
   */
  SET_ALL_QUIZZES(state, { quizzes, isAdmin }) {
    state.quizzes = [];
    for (const quiz of quizzes) {
      const { id, title, duration, description, published, someone_passed } =
        quiz;

      // Check if we can remove this if since it should always be true
      if (quizzes.length >= 0 || isAdmin) {
        state.quizzes.push(
          new Quiz(id, description, duration, title, published, someone_passed)
        );
      }
    }
  },
  SET_TEACHER_QUIZZES(state, { quizzes }) {
    state.quizzes = [];

    for (const quiz of quizzes) {
      const {
        id,
        title,
        description,
        subscribed_classrooms,
        questions_number,
        duration,
        min_points,
        max_points,
        excellence_points,
        sessions,
      } = quiz;

      state.quizzes.push(
        new TeacherQuiz(
          id,
          title,
          description,
          subscribed_classrooms,
          questions_number,
          duration,
          min_points,
          max_points,
          excellence_points,
          sessions.filter((s) => s.end_ts * 1000 > Date.now())
        )
      );
    }
  },
  SET_STUDENT_QUIZZES(state, { quizzes }) {
    state.quizzes = [];

    for (const quiz of quizzes) {
      const {
        id,
        title,
        description,
        sessions,
        questions_number,
        duration,
        min_points,
        excellence_points,
        max_points,
        already_passed,
        already_done_in_session,
        unfinished,
        subscribed,
        is_session_open,
        already_tried,
      } = quiz;

      state.quizzes.push(
        new StudentQuiz(
          id,
          title,
          description,
          sessions.filter((s) => s.end_ts * 1000 > Date.now()),
          questions_number,
          duration,
          min_points,
          excellence_points,
          max_points,
          already_passed,
          already_done_in_session,
          unfinished,
          subscribed,
          is_session_open,
          already_tried
        )
      );
    }
  },
  SET_GUEST_QUIZZES(state, { quizzes }) {
    state.quizzes = [];
    for (const quiz of quizzes) {
      const {
        id,
        title,
        description,
        sessions,
        questions_number,
        duration,
        min_points,
        excellence_points,
      } = quiz;

      state.quizzes.push(
        new GuestQuiz(
          id,
          title,
          description,
          sessions.filter((s) => s.end_ts * 1000 > Date.now()),
          questions_number,
          duration,
          min_points,
          excellence_points
        )
      );
    }
  },
  /**
   * Mutation that set the current Quiz from BE
   *
   * @param {Object} quiz
   * @returns {null}
   */
  SET_QUIZ(state, { quiz }) {
    const {
      id,
      title,
      duration,
      description,
      published,
      someone_passed,
      questions,
      min_points,
      min_points_excellence,
      sessions,
      structure,
      download_enabled,
      credly_template_id,
      credly_excellence_template_id,
    } = quiz;
    state.selectedQuiz = new Quiz(
      id,
      description,
      duration,
      title,
      published,
      someone_passed,
      min_points,
      min_points_excellence,
      sessions.filter((s) => s.end_ts * 1000 > Date.now()),
      structure,
      download_enabled,
      credly_template_id,
      credly_excellence_template_id
    );
    for (const question of questions) {
      state.selectedQuiz.addQuestion(
        question.id,
        question.text,
        question.shuffle_answers,
        question.type,
        question.points,
        question.answers
      );
    }
  },
  /**
   * Mutation that reset the current Quiz to {}
   *
   * @returns {null}
   */
  RESET_QUIZ(state) {
    state.selectedQuiz = new Quiz(
      null,
      "",
      90,
      "",
      false,
      [],
      null,
      null,
      [],
      null,
      null,
      null
    );
  },
  CREATE_QUIZ(state, quiz) {
    state.quizzes.push(
      new Quiz(quiz.id, quiz.description, quiz.duration, quiz.title)
    );
  },
  CREATE_SESSION(state, session) {
    state.selectedQuiz.sessions.push(new Session(session));
  },
  UPDATE_SESSION(state, session) {
    const sessionIndex = state.selectedQuiz.sessions.findIndex(
      (sessionToFind) => sessionToFind.id === session.id
    );
    if (sessionIndex !== -1) {
      const newSessions = [...state.selectedQuiz.sessions];
      newSessions[sessionIndex] = {
        begin_ts: session.start,
        end_ts: session.end,
        ...session,
      };
      state.selectedQuiz.sessions = newSessions;
    }

    const allSessionsIndex = state.sessions.findIndex(
      (sessionToFind) => sessionToFind.id === session.id
    );
    if (allSessionsIndex !== -1) {
      const newSessions = [...state.sessions];
      newSessions[sessionIndex] = {
        begin_ts: session.start,
        end_ts: session.end,
        ...session,
      };
      state.sessions = newSessions;
    }
  },
  DELETE_SESSION(state, session_id) {
    const sessionIndex = state.selectedQuiz.sessions.findIndex(
      (sessionToFind) => sessionToFind.id === session_id
    );
    if (sessionIndex !== -1) {
      state.selectedQuiz.sessions.splice(sessionIndex, 1);
    }

    const allSessionsIndex = state.sessions.findIndex(
      (sessionToFind) => sessionToFind.id === session_id
    );
    if (allSessionsIndex !== -1) {
      state.sessions.splice(allSessionsIndex, 1);
    }
  },
  CREATE_UPDATE_STRUCTURE(state, structure) {
    state.selectedQuiz = {
      ...state.selectedQuiz,
      ...structure,
    };
  },
  REMOVE_QUIZ(state, quizId) {
    state.quizzes = state.quizzes.filter((quiz) => quiz.id !== quizId);
  },
  ADD_ALL_SESSIONS(state, sessions) {
    state.sessions = sessions.map((session) => new Session(session));
  },
  PUBLISH_SELECTED_QUIZ(state) {
    state.selectedQuiz = {
      ...state.selectedQuiz,
      published: true,
    };
  },
  SET_QUIZ_DOWNLOAD_ENABLED(state, value) {
    state.selectedQuiz = {
      ...state.selectedQuiz,
      download_enabled: value,
    };
  },
};

const actions = {
  /**
   * Actions that gets all quizzes from BE
   *
   * @returns {Array} quizzes
   * @throws error if there is a problem with the BE call
   */
  async getAllQuizzes({ commit, rootState }) {
    const isAdmin = rootState.auth.user.access_level === 1;
    try {
      commit("loading/startLoading", {}, { root: true });
      const { data } = await quizService.getQuizzes();
      commit("SET_ALL_QUIZZES", { quizzes: data, isAdmin });
      return data;
    } catch (err) {
      await handleAxiosError(`Errore durante il caricamento dei quiz`, err);
      return null;
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },

  /**
   * Action that gets a specificy Quiz from BE
   *
   * @returns {Object} Quiz
   * @throws error if there is a problem with the BE call
   */
  async getQuizById({ commit }, { quizId, needsStore = true }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      const { data } = await quizService.getQuiz(quizId);
      if (needsStore) {
        commit("SET_QUIZ", { quiz: data });
      }
      return data;
    } catch (err) {
      await handleAxiosError(`Errore durante il caricamento del quiz`, err);
      return null;
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async getAllSessions({ commit }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      const { data } = await quizService.getAllSessions();
      commit("ADD_ALL_SESSIONS", data);
      return data;
    } catch (err) {
      await handleAxiosError(`Errore nel scaricare le altre sessioni`, err);
      return null;
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async getQuizzes({ commit, rootGetters }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      const { data } = await quizService.getQuizzes();
      if (rootGetters["auth/isTeacher"]) {
        commit("SET_TEACHER_QUIZZES", { quizzes: data });
      } else if (rootGetters["auth/isStudent"]) {
        commit("SET_STUDENT_QUIZZES", { quizzes: data });
      } else if (rootGetters["auth/isGuest"]) {
        commit("SET_GUEST_QUIZZES", { quizzes: data });
      }
      return data;
    } catch (err) {
      await handleAxiosError(`Errore durante il caricamento dei quiz`, err);
      return null;
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async requestQuizSubscription({ commit }, quizId) {
    try {
      commit("loading/startLoading", {}, { root: true });
      await quizService.requestQuizSubscription(quizId);
      sendNotification(
        `Abbiamo inviato al tuo docente la richiesta di iscrizione`,
        `success`
      );
    } catch (err) {
      await handleAxiosError(`Errore nell'invio richiesta`, err);
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
