var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Modal',{attrs:{"title":"ISCRIVI CLASSI"},on:{"closeModal":function($event){return _vm.$emit('closeModal')}},scopedSlots:_vm._u([{key:"content",fn:function(){return [(
        _vm.getClassroomsNotAlreadyRegistered(_vm.id).length ===
          0 &&
          _vm.getClassroomsAlreadyRegistered(_vm.id).length === 0
      )?_c('div',{staticClass:"mt-2 mb-5"},[_c('span',{staticClass:"text-h6"},[_vm._v(" Non hai ancora creato nessuna classe! Prima di iscrivere le tue classi, vai nella tab CLASSI per crearle ed invitare i tuoi studenti. ")])]):_vm._e(),_c('v-row',[(
          _vm.getClassroomsNotAlreadyRegistered(_vm.id).length > 0
        )?_c('v-col',[_c('h3',{staticClass:"bit-text font-weight-light px-4 mt-3 mb-10"},[_vm._v(" Classi da iscrivere: ")]),_vm._l((_vm.getClassroomsNotAlreadyRegistered(_vm.id)),function(classroom,index){return _c('v-row',{key:index,staticClass:"pl-10"},[_c('v-col',[_c('span',{staticClass:"text-body-1 text-uppercase"},[_vm._v(" "+_vm._s(classroom.name)+" ")])]),_c('v-col',[_c('v-btn',{attrs:{"outlined":"","rounded":"","color":"yellow","small":""},on:{"click":function($event){return _vm.addCourseToClassroom({
                  classroomId: classroom.id,
                  courseId: _vm.id,
                })}}},[_vm._v(" ISCRIVI ")])],1)],1)})],2):_vm._e(),(
          _vm.getClassroomsAlreadyRegistered(_vm.id).length > 0
        )?_c('v-col',[_c('h3',{staticClass:"bit-text font-weight-light px-4 mt-3 mb-10"},[_vm._v(" Classi gia' iscritte: ")]),_vm._l((_vm.getClassroomsAlreadyRegistered(_vm.id)),function(classroom,index){return _c('v-row',{key:index,staticClass:"pl-10"},[_c('v-col',[_c('span',{staticClass:"text-body-1 text-uppercase"},[_vm._v(" "+_vm._s(classroom.name)+" ")])]),_c('v-col',[_c('v-btn',{attrs:{"outlined":"","rounded":"","color":"red"},on:{"click":function($event){return _vm.unsubscribeCourse({
                  classroomId: classroom.id,
                  courseId: _vm.id,
                })}}},[_vm._v(" ANNULLA ISCRIZIONE ")])],1)],1)})],2):_vm._e()],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }