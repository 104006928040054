<template>
  <v-container>
    <v-row justify="center">
      <v-col
        cols="9"
        md="8"
        lg="6"
        class="logo"
      />
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "HeaderS4S",
};
</script>

<style lang="scss" scoped>
.logo {
  background: url("~@/assets/demo/logo_wide.webp") no-repeat;
  background-size: contain;
  min-height: 100px;
}
</style>
