import axios from "axios";
import store from "../store";
import { AUTH_ERROR } from "@/store/helpers";

/* eslint-disable */
const refreshStatusCode = 401; /* if BE uses another refresh Status Code change here */

const instance = axios.create({
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use((config) => {
  const token = localStorage.getItem("live-tracker-token");
  if (token) {
    config.headers["X-Auth-Token"] = token;
  }
  return config;
});

instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    // Reject promise if usual error{
    if (error.response.status !== refreshStatusCode) {
      return Promise.reject(error);
    }

    /*
     * When response code is 401, try to refresh the token.
     * Eject the interceptor so it doesn't loop in case
     * token refresh causes the 401 response
     */
    try {
      const token = await store.dispatch(
        "liveTracker/refreshToken"
      );
      error.response.config.headers["X-Auth-Token"] = token;
      return instance(error.response.config);
    } catch (err) {
      return Promise.reject(AUTH_ERROR);
    }
  }
);

export const setupLiveTrackerAxiosInstance = () => {
  instance.defaults.baseURL =
    store.getters["config/trackerBackendUrl"];
};

export default instance;
