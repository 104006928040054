<template>
  <div class="courses-expanded">
    <SubscribeClassesModal
      v-if="showSubscribeDialog"
      :id="subscribingCourseId"
      @closeModal="closeSubscribeDialog"
    />
    <Modal
      v-if="showSubscribeInfoModal"
      title="Iscrizione classi"
      @closeModal="showSubscribeInfoModal = false"
    >
      <template #content>
        <v-col>
          <v-row>
            <span
              class="text-body-1"
            >Iscrivendo le tue classi al corso darai loro
              la possibilità di
              <span
                class="font-weight-bold"
              >interagire con il trainer</span>
              durante i corsi, partecipare alle discussioni
              via chat e
              <span
                class="font-weight-bold"
              >scaricare le risorse formative.</span><br>
              Come professore avrai invece il
              <span
                class="font-weight-bold"
              >dettaglio delle loro presenze</span>
              (attivo solo per gli studenti iscritti al
              corso) e la possibilità di vedere i
              <span
                class="font-weight-bold"
              >risultati dei test</span>
              completati dalla tua classe.<br>
              <span
                class="font-weight-bold"
              >Non far perdere ai tuoi studenti queste
                opportunità!</span></span>
          </v-row>
          <v-row
            align="center"
            justify="center"
          >
            <v-btn
              id="download-teacher-guide"
              color="orange darken-3"
              dark
              large
              class="rounded-lg mt-5"
              data-cy="download-teacher-guide-btn"
              target="_blank"
              href="/docs/guida_docenti_2022.pdf"
              @click="
                () =>
                  trackEvent(
                    'PlatformLivePage',
                    'DownloadTeachersGuide'
                  )
              "
            >
              GUIDA INSEGNANTI
              <v-icon
                size="18px"
                color="#fff"
                class="ml-4"
              >
                mdi-download
              </v-icon>
            </v-btn>
          </v-row>
        </v-col>
      </template>
    </Modal>
  </div>
</template>

<script>
import SubscribeClassesModal from "@cmp-teacher/SubscribeClassesModal";
import Modal from "@cmp-shared/utils/Modal";
import matomoTracker from "@mixins/matomoTracker";
export default {
  name: "TeacherSubscribeModals",
  components: {
    SubscribeClassesModal,
    Modal,
  },
  mixins: [matomoTracker],
  data() {
    return {
      subscribingCourseId: null,
      showSubscribeDialog: false,
      showSubscribeInfoModal: false,
    };
  },
  created() {
    this.$emit("openSubscribeDialog", "openSubscribeDialog");
  },
  methods: {
    openSubscribeDialog(courseId) {
      this.subscribingCourseId = courseId;
      this.showSubscribeDialog = true;
    },
    closeSubscribeDialog() {
      this.subscribingCourseId = null;
      this.showSubscribeDialog = false;
    },
    async openSubscribeInfoModal() {
      await this.trackEvent(
        "PlatformLivePage",
        "OpenImportanceOfSubscribingClassesModal"
      );
      this.showSubscribeInfoModal = true;
    },
  },
};
</script>

<style scoped>
.section-title {
  font-weight: 700;
  font-size: 48.83px;
  line-height: 50px;
  letter-spacing: 1px;
  color: #ffffff;
}
</style>
