<template>
  <Modal
    title="ISCRIVI CLASSI"
    @closeModal="$emit('closeModal')"
  >
    <template #content>
      <div
        v-if="
          getClassrooms.length === 0
        "
        class="mt-2 mb-5"
      >
        <span class="text-h6">
          Non hai ancora creato nessuna classe! Prima di
          iscrivere le tue classi, vai nella tab CLASSI
          per crearle ed invitare i tuoi studenti.
        </span>
      </div>
      <v-row>
        <v-col
          v-if="
            notSubscribedClassrooms.length
          "
        >
          <h3
            class="
              bit-text
              font-weight-light
              px-4
              mt-3
              mb-10
            "
          >
            Classi da iscrivere:
          </h3>
          <v-row
            v-for="
              classroom
                of notSubscribedClassrooms"
            :key="classroom.id"
            class="pl-10"
          >
            <v-col>
              <span class="text-body-1 text-uppercase">
                {{ classroom.name }}
              </span>
            </v-col>
            <v-col>
              <v-btn
                outlined
                rounded
                color="yellow"
                small
                @click="subscribeClassroomToQuiz({classroomID: classroom.id, quizID: quizId})"
              >
                ISCRIVI
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          v-if="
            subscribedClassrooms.length
          "
        >
          <h3
            class="
              bit-text
              font-weight-light
              px-4
              mt-3
              mb-10
            "
          >
            Classi gia' iscritte:
          </h3>

          <v-row
            v-for="classroom of subscribedClassrooms"
            :key="classroom.id"
            class="pl-10"
          >
            <v-col>
              <span class="text-body-1 text-uppercase">
                {{ classroom.name }}
              </span>
            </v-col>
            <v-col>
              <v-btn
                outlined
                rounded
                color="red"
                @click="unsubscribeClassroomToQuiz({classroomID: classroom.id, quizID: quizId})"
              >
                ANNULLA ISCRIZIONE
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </template>
  </Modal>
</template>

<script>
import Modal from "@cmp-shared/utils/Modal";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "QuizSubscribeModal",
  components: { Modal },
  props: {
    quizId: {
      type: String,
      required: true,
    },
    subscribedClassrooms: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters("classrooms", [
      "getClassrooms"
    ]),
    notSubscribedClassrooms() {
      return this.getClassrooms.filter((classroom) => !this.subscribedClassrooms.map((subClassroom) => subClassroom.id).includes(classroom.id))
    }
  },
  async created() {
    await this.getClassroomsProf()
  },
  methods: {
    ...mapActions("classrooms", [
      "getClassroomsProf", "subscribeClassroomToQuiz", "unsubscribeClassroomToQuiz"
    ]),
  },

};
</script>
