<template>
  <v-app>
    <v-main class="ondemand-courses-container">
      <Toolbar reverse-bg />
      <v-container class="pt-0">
        <h2 class="courses-title">
          Corsi
        </h2>
        <v-card class="mt-5 justify-center course-card">
          <v-col class="pb-0 pl-2">
            <v-btn
              text
              color="transparent"
              :to="`/${typeOfUser(user.access_level)}/ondemand-courses`"
            >
              <v-icon
                color="#ffffff"
                left
                size="22"
              >
                mdi-arrow-left
              </v-icon>
              <span class="back-to-courses">visualizza lista corsi</span>
            </v-btn>
          </v-col>
          <v-row
            v-if="!isLoading"
            class="px-5"
          >
            <v-col
              cols="12"
              md="8"
            >
              <router-view
                :key="$route.name"
                class="py-2"
              />
              <v-row no-gutters>
                <v-col
                  cols="7"
                  md="3"
                  xl="2"
                  sm="5"
                >
                  <span
                    class="stepper-span py-2"
                    :class="{
                      'stepper-span-clicked': selectedTab === 0,
                    }"
                    @click="tabSelector(0)"
                  >Informazioni del corso</span>
                  <hr
                    v-if="selectedTab === 0"
                    class="tab-divider-info"
                  >
                </v-col>
                <v-col
                  cols="5"
                  md="6"
                  sm="5"
                  xs="6"
                >
                  <span
                    class="stepper-span py-2"
                    :class="{
                      'stepper-span-clicked': selectedTab === 1,
                    }"
                    @click="tabSelector(1)"
                  >Domande anonime</span>
                  <hr
                    v-if="selectedTab === 1"
                    class="tab-divider-questions"
                  >
                </v-col>
                <v-col
                  v-if="resourceLink"
                  cols="12"
                  md="3"
                  xl="4"
                  sm="2"
                  class="d-flex justify-end"
                >
                  <v-btn
                    class="resources-button rounded-lg"
                    :class="$vuetify.breakpoint.xsOnly ? 'mt-2' : ''"
                    outlined
                    color="#F39200"
                    :href="resourceLink"
                    target="_blank"
                  >
                    <v-icon left>
                      mdi-layers-triple-outline
                    </v-icon>
                    Materiali
                  </v-btn>
                </v-col>
              </v-row>
              <CourseInfoSection
                v-if="selectedTab === 0"
                :ondemand="true"
                class="py-2"
                :course="course"
              />
              <CourseCommentsSection
                v-if="selectedTab === 1"
                :ondemand="true"
                class="py-2"
              />
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <OndemandPath
                class="py-2"
                :course-sections="courseSections"
              />
            </v-col>
          </v-row>
          <div v-else>
            <h4 class="loading-text mt-5">
              Caricamento in corso
            </h4>
            <v-progress-linear
              indeterminate
              color="cyan"
              class="mt-5"
            />
          </div>
        </v-card>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Toolbar from "@cmp-shared/toolbar/Toolbar";
import OndemandPath from "@cmp/ondemand/OndemandPath";
import CourseInfoSection from "../../components/courses/CourseInfoSection.vue";
import CourseCommentsSection from "@cmp/courses/CourseCommentsSection";
import { mapActions } from "vuex";
import userHandler from "@mixins/userHandler";
import trigger from "@mixins/trigger";
export default {
  name: "OndemandVideoPage",
  components: {
    Toolbar,
    OndemandPath,
    CourseInfoSection,
    CourseCommentsSection,
  },
  mixins: [userHandler, trigger],
  data: () => ({
    isLoading: true,
    courseSections: [],
    course: {},
    selectedTab: 0,
    resourceLink: "",
  }),
  computed: {
    currentId() {
      return this.$route.params.id;
    },
  },
  watch: {
    needsUpdate(newVal) {
      if (newVal) {
        this.updateStatusVideo();
        this.resetTrigger();
      }
    },
  },
  async mounted() {
    await this.getData();
  },
  methods: {
    ...mapActions("learningPaths", [
      "getOndemandById",
      "updateLearningPathVideo",
    ]),
    async updateStatusVideo() {
      await this.updateLearningPathVideo(this.$route.params.resourceId);
      await this.getData();
    },
    async getData() {
      try {
        const data = await this.getOndemandById({
          id: this.currentId,
        });
        this.course = data;
        this.courseSections = data.sections.slice();
        this.resourceLink = data.externalResourceLink;
      } finally {
        this.isLoading = false;
      }
    },
    tabSelector(n) {
      this.selectedTab = n;
    },
    typeOfUser(acc_level) {
      switch (acc_level) {
      case 2:
        return "teacher";
      case 3:
        return "student";
      case 4:
        return "guest";
      default:
        return "sorint";
      }
    },
  },
};
</script>

<style scoped>
.stepper-span {
  font-size: 16px;
  line-height: 28px;
  cursor: pointer;
}

.stepper-span-clicked {
  color: #f39200;
  font-weight: 700;
}

.ondemand-courses-container {
  background-color: #01002e;
}

.courses-title {
  font-weight: 700;
  font-size: 48.83px;
  line-height: 50px;
  color: #ffffff;
}

.course-card {
  background: linear-gradient(
      109.46deg,
      rgba(1, 0, 46, 0.3) 1.57%,
      rgba(1, 0, 46, 0.3) 100%
    ),
    linear-gradient(
      109.46deg,
      rgba(201, 201, 201, 0.3) 1.57%,
      rgba(196, 196, 196, 0.05) 100%
    ) !important;
  backdrop-filter: blur(30px);
  border-radius: 12px;
  font-size: 20px;
  line-height: 18.5px;
  color: #f4f5f6;
}

.back-to-courses {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #f4f5f6;
}

.loading-text {
  text-align: center;
  font-weight: 500;
}

.resources-button {
  font-size: 16px;
  line-height: 28px;
  font-weight: 600;
  color: #f39200;
  text-transform: none;
}

.resource-divider {
  width: 55px;
  position: absolute;
  margin-top: 25px;
  border: 1px solid #f39200;
}

.tab-divider-info {
  width: 159px;
  position: absolute;
  border: 1px solid #f39200;
}

.tab-divider-questions {
  width: 134.5px;
  position: absolute;
  border: 1px solid #f39200;
}
</style>
