<template>
  <v-container>
    <v-row class="mt-2">
      <v-col cols="12" md="5">
        <v-form ref="email" v-model="form" @submit.prevent="addItem(email)">
          <span class="input-label">E-mail istituzionale</span>
          <input
            v-model.trim="email"
            type="text"
            class="input-field"
            required
            :rules="[rules.email, rules.required]"
            counter="100"
            placeholder="Inserisci qui la mail dello studente da aggiungere"
            @keydown="onKeyDownEmail"
            @keyup="onKeyUpEmail"
          />
          <v-row>
            <v-col class="d-flex justify-end">
              <span>{{ email.length }}/100</span>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
      <v-col cols="12" md="3" align-self="center" class="mt-2">
        <v-btn
          dark
          strong
          color="#F39200"
          class="add-btn rounded-lg"
          width="100%"
          :loading="loading"
          large
          @click="addItem(email)"
        >
          <v-icon left dark> mdi-account-plus-outline </v-icon>
          Aggiungi Studente
        </v-btn>
      </v-col>
      <v-col cols="12" md="4" align-self="center" class="mt-2">
        <v-btn
          dark
          outlined
          color="orange"
          class="add-btn rounded-lg"
          width="100%"
          large
          @click="studentsGroupModal = true"
        >
          <v-icon left dark> mdi-account-plus-outline </v-icon>
          Aggiungi gruppo di studenti
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <span class="sub-title">Lista studenti</span>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-data-table
          class="transparent"
          :headers="studentHeader"
          :items="getFilteredStudents"
          sort-by="stato_iscrizione"
        >
          <template #header.stato_iscrizione="{}">
            <th>
              <v-menu offset-y>
                <template #activator="{ on }">
                  <span v-on="on" class="cursor-pointer"
                    >Stato Iscrizione <v-icon>mdi-filter-outline</v-icon></span
                  >
                </template>

                <v-container class="content-card">
                  <v-radio-group v-model="currentFilter">
                    <v-radio
                      v-for="filter in registrationFilters"
                      :key="filter.value"
                      :label="filter.text"
                      :value="filter.value"
                      color="#f39200"
                      dark
                    ></v-radio>
                  </v-radio-group>
                </v-container>
              </v-menu>
            </th>
          </template>

          <template #[`item.actions`]="{ item }">
            <v-icon
              data-cy="remove-student-action"
              @click.stop="openDeleteModal(item)"
            >
              mdi-delete-outline
            </v-icon>
          </template>
          <template #[`item.stato_iscrizione`]="{ item }">
            <span
              class="subscribed_label"
              :class="
                item.stato_iscrizione === 'ISCRITTO'
                  ? 'success--text'
                  : 'warning--text'
              "
              >{{ item.stato_iscrizione }}</span
            >
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <AddStudentsModal
      v-if="studentsGroupModal"
      @onUploadFile="uploadFile"
      @onDownloadExample="downloadExample"
      @onCloseModal="studentsGroupModal = false"
    >
    </AddStudentsModal>

    <Modal
      v-if="dialogCopyLink"
      title="Copia Link di Invito"
      @closeModal="closeCopyDialog"
    >
      <template #content-center>
        <v-row>
          <v-col>
            <v-card-text class="modal-text">
              <p class="font-weight-light">
                <v-icon large left color="red"> mdi-alert </v-icon
                ><span class="red--text">ATTENZIONE!</span>

                Il link e' da utilizzare per gli studenti che non avessero
                ricevuto la mail di invito.
                <br />
                <br />
                Prima di inviarglielo
                <span class="orange--text">assicurati di aver aggiunto</span>
                le loro email all'interno del box "Email istituzionale",
                altrimenti il link non sara' valido.
              </p>

              <v-row class="mx-10" justify="center" align="center">
                <v-col>
                  <span class="text-body-2"> {{ invitationLink }}</span>
                </v-col>
                <v-col cols="3">
                  <v-btn
                    id="copy-clipboard-btn-modal"
                    :disabled="currentFilter === 'register'"
                    dark
                    strong
                    color="#F39200"
                    class="add-btn rounded-lg"
                    @click.stop="copyToClipboard"
                  >
                    Copia link
                    <v-icon right dark> mdi-content-copy </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-col>
        </v-row>
      </template>
    </Modal>

    <Modal
      v-if="dialogRemoveStudent"
      title="Conferma Rimozione Studente"
      :show-close-button="false"
      @closeModal="dialogRemoveStudent = false"
    >
      <template #content-center>
        <v-row>
          <v-col>
            <v-card-text class="modal-text">
              <p>
                <v-icon large left color="red"> mdi-alert </v-icon
                ><span class="red--text">ATTENZIONE!</span> Sei sicura/o di
                voler rimuovere lo studente dalla classe {{ title }}?
              </p>
              <v-row>
                <v-col class="ml-10">
                  <!-- testo per studente Pending -->
                  <span
                    v-if="
                      studentToRemove.stato_iscrizione ===
                      'IN ATTESA DI CONFERMA'
                    "
                  >
                    <span class="text-overline">MAIL: </span>
                    <span class="text-body-2">
                      {{ studentToRemove.email }}</span
                    >
                    <br />
                    <span class="text-overline">STATO ISCRIZIONE: </span>
                    <span class="text-body-2">
                      {{ studentToRemove.stato_iscrizione }}</span
                    >
                  </span>

                  <!-- testo per studente Iscritto -->

                  <span v-else>
                    <span class="text-overline">NOME: </span>
                    <span class="text-body-2"> {{ studentToRemove.name }}</span>
                    <br />
                    <span class="text-overline">COGNOME: </span>
                    <span class="text-body-2">
                      {{ studentToRemove.surname }}</span
                    >
                    <br />

                    <span class="text-overline">STATO ISCRIZIONE: </span>
                    <span class="text-body-2">
                      {{ studentToRemove.stato_iscrizione }}</span
                    >
                  </span>
                </v-col>
              </v-row>

              <p class="font-weight-thin mt-3 red--text">
                La presenza ad un corso Live non viene contata se lo studente
                non è associato ad una classe!
              </p>
            </v-card-text>
          </v-col>
        </v-row>
        <v-card-actions class="mt-3">
          <v-row justify="center">
            <v-btn
              large
              plain
              class="white--text mx-3"
              data-cy="remove-student-btn"
              @click="dialogRemoveStudent = false"
            >
              <v-icon class="mr-1"> mdi-close </v-icon>
              <span>ANNULLA</span>
            </v-btn>
            <v-btn
              color="red darken-2"
              class="white--text mx-3 rounded-lg"
              large
              data-cy="confirm-delete-student"
              @click="confirmDelete"
            >
              <v-icon left> mdi-delete-outline </v-icon>
              RIMUOVI
            </v-btn>
          </v-row>
        </v-card-actions>
      </template>
    </Modal>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import classServices from "@services/classroom";
import { sendNotification } from "@utils/notifications";
import Modal from "@cmp-shared/utils/Modal";
import AddStudentsModal from "./AddStudentsModal.vue";

export default {
  name: "ClassCard",
  components: { Modal, AddStudentsModal },
  props: {
    title: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    showCopyDialog: {
      type: Boolean,
    },
    closeCopyDialog: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      dialogCopyLink: false,
      isLoadingDownload: false,
      studentsGroupModal: false,
      dialogRemoveStudent: false,
      studentToRemove: "",
      loading: false,
      currentFilter: "all",
      studentHeader: [
        {
          text: "Nome",
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          text: "Cognome",
          align: "start",
          sortable: true,
          value: "surname",
        },
        { text: "Email", value: "email" },
        {
          text: "Stato Iscrizione",
          value: "stato_iscrizione",
          sortable: false,
        },
        {
          text: "Azioni",
          value: "actions",
          sortable: false,
        },
      ],
      registrationFilters: [
        {
          text: "Tutti",
          value: "all",
        },
        {
          text: "Iscritti",
          value: "register",
        },
        {
          text: "In attesa di conferma",
          value: "pending",
        },
      ],
      email: "",
      isEmailUsed: false,
      emailTimeout: null,
      form: false,
      rules: {
        required: (value) => Boolean(value) || "Valore richiesto",
        email: (v) =>
          !v ||
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) ||
          `L'e-mail deve essere valida`,
      },
    };
  },
  computed: {
    ...mapGetters("classrooms", {
      classroomStudents: "getStudents",
    }),
    ...mapGetters("config", ["backendUrl", "landingUrl"]),
    ...mapState("classrooms", ["students"]),
    getHintMessage() {
      return this.isEmailUsed
        ? "Attenzione: lo studente inserito fa gia' parte di un'altra classe"
        : "Utilizza solo gli indirizzi e-mail istituzionali degli studenti";
    },
    getFilteredStudents() {
      if (this.id) {
        if (this.currentFilter === "all") {
          return this.classroomStudents(this.id);
        } else if (this.currentFilter === "register") {
          return this.classroomStudents(this.id).filter(
            (student) => student.id
          );
        } else {
          return this.classroomStudents(this.id).filter(
            (student) => !student.id
          );
        }
      }
      return [];
    },
    invitationLink() {
      return `${this.landingUrl}/sign-up-student/${this.id}`;
    },
  },
  watch: {
    id(value) {
      if (value) {
        this.getClassroomStudents(value);
      }
    },
    showCopyDialog(value) {
      this.dialogCopyLink = value;
    },
  },
  async created() {
    if (this.id) {
      await this.getClassroomStudents(this.id);
    }
  },
  methods: {
    ...mapActions("classrooms", [
      "addStudent",
      "removeStudent",
      "getClassroomStudents",
      "uploadStudents",
    ]),
    async addItem(email) {
      await this.addStudent({ email, classroomId: this.id });
      this.email = "";
      this.$refs.email.resetValidation();
    },
    onKeyDownEmail() {
      if (this.rules.email(this.email) === true) {
        this.isEmailUsed = false;
      }
      clearTimeout(this.emailTimeout);
    },
    onKeyUpEmail() {
      if (this.rules.email(this.email) === true) {
        this.loading = true;
        this.emailTimeout = setTimeout(this.checkEmail, 100);
      }
    },
    async checkEmail() {
      if (this.rules.email(this.email) === true) {
        try {
          const { data } = await classServices.checkStudent(this.email);
          this.isEmailUsed = data;
        } catch (err) {
          /* do nothing */
        } finally {
          this.loading = false;
        }
      }
    },

    async copyToClipboard() {
      await navigator.clipboard.writeText(this.invitationLink);
      sendNotification(`Link di invito copiato nella clipboard.`, `success`);
    },
    openDeleteModal(student) {
      this.dialogRemoveStudent = true;
      this.studentToRemove = student;
    },
    confirmDelete() {
      this.removeStudent({
        classroomId: this.id,
        studentId: this.studentToRemove.id,
        studentEmail: this.studentToRemove.email,
      });
      this.dialogRemoveStudent = false;
    },
    async downloadExample() {
      try {
        this.isLoadingDownload = true;
        const response = await fetch("/studenti.xlsx");
        if (!response.ok)
          throw new Error("An error occurred while fetching the file");
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "esempio.xlsx");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (e) {
        this.$logger(e);
      } finally {
        this.isLoadingDownload = false;
      }
    },
    async uploadFile(file) {
      await this.uploadStudents({ file, classroomId: this.id });
      this.studentsGroupModal = false;
    },
  },
};
</script>

<style scoped>
.sub-title {
  font-family: "Karasuma Gothic", sans-serif;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 1px;
  padding-left: 8px;
  text-transform: uppercase;
}

.input-field {
  padding: 7px 14px;
  border: 1px solid #e8ebed;
  border-radius: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.75px;
  color: #fff;
  width: 100%;
}

.input-label {
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.75px;
}

.add-btn {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #f2f2f2;
  text-transform: none;
}

.text-p {
  font-family: Karasuma Gothic;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.subscribed_label {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
}

.content-card {
  text-align: start;
  font-family: "Karasuma Gothic", sans-serif;
  font-weight: 100;
  font-size: 14px;
  background-color: #1d1c4c;
  backdrop-filter: blur(30px);
  border-radius: 12px;
  color: #ffffff;
}

.cursor-pointer {
  cursor: pointer;
}
</style>
