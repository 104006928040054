<template>
  <div class="">
    <Modal
      width="928px"
      title=""
      :show-close-button="false"
      :persistent="true"
      :scrollable="true"
      @closeModal="closeDialog"
    >
      <template #content-center>
        <v-card-title class="modal-title pt-8">
          <span class="orange-journey ml-1">attenzione!</span></v-card-title
        >

        <v-card-text class="modal-text mx-auto my-3">
          Stai accedendo alla
          <b class="orange-journey">versione precedente</b> della piattaforma
          HackersGen.

          <p class="mb-10">
            Questo accesso è disponibile
            <b class="orange-journey">esclusivamente ai docenti</b> per
            eventuali verifiche sui report antecedenti alla data di migrazione
            definitiva: <span class="orange-journey">23 Ottobre 2024</span>.
          </p>

          <h3 class="orange-journey text-uppercase mb-2">
            Informazioni importanti
          </h3>

          <span class="orange-journey"> Nuova registrazione dei corsi: </span>
          <ul class="mb-8">
            <li>
              I corsi seguiti a partire dal 23 Ottobre 2024 saranno registrati
              nell'area report della
              <span class="orange-journey"> nuova piattaforma</span>.
            </li>
            <li>
              Questi corsi
              <span class="orange-journey">non saranno visibili</span>
              nell'area report della versione precedente.
            </li>
          </ul>

          <span class="orange-journey"> Sincronizzazione completata: </span>
          <ul>
            <li>
              Tutte le attività svolte dagli studenti prima del 23 Ottobre 2024
              sono già sincronizzate nella
              <span class="orange-journey">nuova piattaforma</span>.
            </li>
            <li>
              Gli studenti ritroveranno i corsi esattamente dove li hanno
              lasciati.
            </li>
            <li>
              Nella nuova area report sono già disponibili tutti i corsi
              completati negli anni precedenti.
            </li>
          </ul>

          <p class="mt-10">
            Per ulteriori informazioni o assistenza, contattare il supporto
            tecnico a
            <a
              class="orange--text text-decoration-none"
              href="
                      mailto:'hackersgen@sorint.com'"
            >
              hackersgen@sorint.com</a
            >.
          </p>
        </v-card-text>

        <v-card-actions>
          <v-btn
            text
            class="no-thanks white--text mx-3 rounded-lg mx-auto"
            large
            @click="closeDialog"
          >
            <v-icon class="mx-2">mdi-close</v-icon>
            Chiudi
          </v-btn>
        </v-card-actions>
      </template>
    </Modal>
  </div>
</template>

<script>
import Modal from "@cmp-shared/utils/Modal";
import matomoTracker from "@mixins/matomoTracker";

export default {
  name: "ReportPopup",
  mixins: [matomoTracker],
  components: { Modal },
  methods: {
    closeDialog() {
      this.$emit("close-dialog");
    },
  },
};
</script>

<style scoped>
.modal-title {
  font-family: "Karasuma Gothic", sans-serif;
  justify-content: center;
  font-size: 1.2em;
  font-family: "outerorbit", "Orbitron" !important;
  font-style: normal !important;
  font-weight: 300 !important;
  font-size: 24px !important;
  line-height: 24px !important;
  text-transform: uppercase !important;
  color: #f4f5f6 !important;
}

.modal-text {
  font-family: Karasuma Gothic;
  font-size: 20px;
  font-weight: 350;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
  color: #f4f5f6;
  max-width: 820px;
}

.orange-journey {
  background: linear-gradient(#f39200, #fcee21);
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  font-weight: 700;
}

.no-thanks {
  text-transform: unset !important;
  font-family: Karasuma Gothic;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
}
</style>
