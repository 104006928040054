<template>
  <v-main class="login-container">
    <v-container class="login-wrapper">
      <v-row justify="space-around">
        <!-- ROUTER VIEW -->
        <Stars />
        <BackgroundPlanets :max-planets-number="3" />
        <v-slide-x-reverse-transition mode="out-in">
          <router-view />
        </v-slide-x-reverse-transition>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import BackgroundPlanets from "../../components/landing/BackgroundPlanets.vue";
import Stars from "../../components/platform/space/Stars.vue";
export default {
  name: "Login",
  components: { BackgroundPlanets, Stars },
};
</script>

<style scoped>
.gradient {
  background: rgb(2, 0, 36);
  background: linear-gradient(
    180deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(65, 9, 121, 1) 0%,
    rgba(65, 9, 121, 0) 100%
  );
}

h2,
h4 {
  font-family: "Karasuma Gothic", sans-serif;
}

.login-container {
  background-color: #01002e;
  background-size: cover;
  position: relative;
}
.login-wrapper {
  max-width: 1200px;
}
</style>
