<template>
  <div class="exercise-wrapper px-2 mt-2 mb-5">
    <v-row class="mt-2 mb-5">
      <v-col>
        <h2>Esercizio</h2>
      </v-col>
      <v-col cols="12" class="mb-2">
        <h4>{{ exercise.title }}</h4>
      </v-col>
      <v-col cols="12" v-dompurify-html="exercise.text"> /> </v-col>
      <v-col v-if="hint !== ''" cols="12">
        <p>{{ hint.hint }}</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="8" class="d-flex jusitfy-start">
        <v-btn
          v-if="hint === ''"
          color="#F39200"
          class="white--text font-weight-bold btn-size rounded-lg"
          :width="$vuetify.breakpoint.smAndDown ? '100%' : ''"
          @click="showHint"
        >
          Mostra suggerimento
        </v-btn>
        <v-btn
          v-else
          color="#F39200"
          class="white--text font-weight-bold btn-size rounded-lg"
          :width="$vuetify.breakpoint.smAndDown ? '100%' : ''"
          @click="hint = ''"
        >
          Nascondi suggerimento
        </v-btn>
      </v-col>
      <v-col cols="12" md="4" class="d-flex justify-end">
        <v-btn
          v-if="isStudent"
          color="#F39200"
          class="white--text font-weight-bold btn-size rounded-lg"
          :width="$vuetify.breakpoint.smAndDown ? '100%' : ''"
          @click="completeExercise"
        >
          Completato
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import userHandler from "@mixins/userHandler";
import trigger from "@mixins/trigger";
export default {
  name: "OndemandProgExercise",
  mixins: [userHandler, trigger],
  data: () => ({
    hint: "",
    exercise: {},
  }),
  computed: {
    resourceId() {
      return this.$route.params.resourceId;
    },
  },
  async mounted() {
    await this.getExercise();
  },
  methods: {
    ...mapActions("learningResources", [
      "getOndemandExerciseHint",
      "completeOndemandExercise",
      "getOndemandExerciseDetails",
    ]),
    async showHint() {
      this.hint = await this.getOndemandExerciseHint({ id: this.resourceId });
    },
    async completeExercise() {
      await this.completeOndemandExercise({ id: this.resourceId });
      this.triggerUpdate();
    },
    async getExercise() {
      this.exercise = await this.getOndemandExerciseDetails({
        id: this.resourceId,
      });
    },
  },
};
</script>

<style scoped>
.exercise-wrapper {
  border: 1px #5a6972 solid;
  border-radius: 8px;
}
</style>
