<template>
  <v-btn
    width="40px"
    height="40px"
    min-width="40px"
    color="#F39200"
    class="rounded-lg"
    :disabled="disabled"
    @click="download"
  >
    <v-img
      max-width="24"
      src="@assets/landingPage/download.svg"
    />
  </v-btn>
</template>

<script>
export default {
  name: "DownloadAction",
  props: {
    storeName:{
      type:String,
      required:true
    },
    storeAction:{
      type:String,
      required:true
    },
    objectToSend:{
      type:Object,
      required:true
    },
    disabled:{
      type:Boolean
    }
  },
  methods: {
    download() {
      this.$store.dispatch(`${this.storeName}/${this.storeAction}`, this.objectToSend)
    },
  },
};
</script>

<style></style>
