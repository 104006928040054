<template>
  <v-app class="app-container">
    <v-main class="route-wrapper">
      <Toolbar
        :is-project-area="true"
        :tabs="[
          getCourseById(id) ? getCourseById(id).title : 'Caricamento corso...',
        ]"
        reverse-bg
      />
      <v-container class="pt-0">
        <div v-if="loaded">
          <LivePlayer
            :course="course"
            :episode="episode"
            :error-while-pinging="errorWhilePinging"
          />
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import store from "@store";
import Toolbar from "@cmp-shared/toolbar/Toolbar";
import LivePlayer from "@cmp/courses/LivePlayer";
import userHandler from "@mixins/userHandler";
import dayjs from "dayjs";
import { pushAuthUrl } from "@/utils/externalRouter";

const FIFTEEN_SECONDS_IN_MS = 15 * 1000; // 15 seconds in ms
const HALF_HOUR_IN_MS = 30 * 60 * 1000; // 30 minutes in ms
const ONE_HOUR = 60 * 60; // 60 minutes in sec
const HALF_HOUR = 30 * 60; // 30 minutes in sec

export default {
  name: "VideoPage",
  components: {
    LivePlayer,
    Toolbar,
  },
  mixins: [userHandler],
  props: {
    id: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      subscribed: false,
      rating: 3.5,
      drawer: false,
      loaded: false,
      errorWhilePinging: false,
      interval: null,
      remainingTime: null,
      timeout: null,
    };
  },
  computed: {
    ...mapGetters("courses", ["getCourseById", "getEpisodeByVideoId"]),
    course() {
      return this.getCourseById(this.id);
    },
    episode: {
      get() {
        if (!this.course) {
          return {};
        }
        return this.getEpisodeByVideoId(this.id, this.$route.params.videoId);
      },
      set(newValue) {
        return newValue;
      },
    },
    shouldPing() {
      return this.isStudent && this.course.subscribed;
    },
  },
  watch: {
    async episode() {
      this.$logger("changed Episode");
      this.stopPinging();

      if (this.shouldPing) {
        const now = Math.floor(Date.now() / 1000);

        // check if live has started (bewtween -30min before start and +1hour after end)
        if (
          now >= this.episode.start - HALF_HOUR &&
          now <= this.episode.end + ONE_HOUR
        ) {
          await this.getTrackerToken(this.episode.id);
          this.startPinging();
        }

        // check if the student is already on the page before the live starts and there are more than 30 minutes left (timeout starts)
        else if (now <= this.episode.start - HALF_HOUR) {
          this.getRemainingTime();

          this.timeout = setTimeout(async () => {
            await this.getTrackerToken(this.episode.id);
            this.startPinging();
          }, this.remainingTime - HALF_HOUR_IN_MS);
        }
      }
    },
  },
  async mounted() {
    await store.dispatch("courses/getAllCourses");
    if (!this.getCourseById(this.id)) {
      try {
        await store.dispatch("courses/getAllCourses");
        if (!this.episode.id) {
          return;
        }
        this.loaded = true;
      } catch (err) {
        await this.$router.push("/404");
      }
    } else {
      this.loaded = true;
    }

    await Promise.all([
      store.dispatch("courses/getRanks", this.id),
      store.dispatch("courses/getMyRank", {
        courseId: this.id,
        episodeId: this.episode.id,
      }),
    ]);

    window.addEventListener("beforeunload", () => this.stopPinging());
  },
  beforeDestroy() {
    this.$logger("before destroy");
    this.stopPinging();
  },
  methods: {
    ...mapActions("courses", [
      "getAllCourses",
      "createRank",
      "getNextLiveInfo",
    ]),
    ...mapActions("liveTracker", ["getTrackerToken", "pingVideo"]),
    ...mapActions("auth", ["logout"]),
    getRemainingTime() {
      return (this.remainingTime = dayjs
        .unix(this.episode.start)
        .diff(dayjs(Date.now()), "milliseconds"));
    },
    async quit() {
      await this.logout();
      await pushAuthUrl();
    },
    startPinging() {
      this.$logger("Start Pinging");
      clearTimeout(this.timeout);
      clearInterval(this.interval);
      this.$logger("Set Interval");
      this.interval = setInterval(
        this.doPing,
        FIFTEEN_SECONDS_IN_MS // every 15 seconds
      );
    },
    async doPing() {
      const now = Math.floor(Date.now() / 1000);
      if (
        now >= this.episode.start - HALF_HOUR &&
        now <= this.episode.end + ONE_HOUR
      ) {
        await this.tryPing(2);
      } else this.stopPinging();
    },
    async tryPing(maxRetries, delay = 3000) {
      if (this.shouldPing) {
        try {
          await this.sleep(delay);
          this.$logger(`${maxRetries} tentativi rimasti`);
          await this.pingVideo();
          this.$logger(`Pinged!`);
          this.errorWhilePinging = false;
          return;
        } catch (_) {
          if (maxRetries <= 0) {
            this.errorWhilePinging = true;
          } else {
            await this.tryPing(maxRetries - 1);
          }
        }
      }
    },
    sleep(ms) {
      return new Promise((resolve) => {
        setTimeout(resolve, ms);
      });
    },
    stopPinging() {
      this.$logger("stop Pinging");
      clearInterval(this.interval);
      clearTimeout(this.timeout);
      this.interval = null;
      this.timeout = null;
    },
  },
};
</script>

<style scoped>
.bit-text {
  font-family: "Karasuma Gothic", sans-serif;
}

#background-gradient {
  background: rgb(89, 60, 140);
  background: radial-gradient(
    circle,
    rgba(89, 60, 140, 1) 0%,
    rgba(18, 6, 39, 1) 100%
  );
}

.avenir {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-transform: uppercase;
}
</style>
