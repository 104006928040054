<template>
  <v-container id="container-report" class="pt-0">
    <v-col class="pa-0" cols="12">
      <h1
        class="page-title mb-10"
        :class="$vuetify.breakpoint.mdAndDown ? 'text-center' : 'text-left'"
      >
        Report
      </h1>
      <h5 class="page-subtitle mb-10">
        Scegli la classe e scarica i report relativi ai corsi Ondemand, Live e
        Test.
      </h5>
      <h3 class="class-list-title mb-10">Lista classi</h3>
      <v-row no-gutters>
        <v-col
          v-for="(classroom, index) in classrooms"
          :key="index"
          cols="12"
          sm="6"
          md="2"
          :class="{
            'px-2': $vuetify.breakpoint.smAndDown,
          }"
        >
          <SmallClassCard
            :id="classroom.id"
            :name="classroom.name"
            :number-of-students="classroom.students.length"
            :current-id="selectedClassId"
            @onClassSelected="selectClass"
          />
        </v-col>
      </v-row>

      <v-col v-if="selectedClassId" cols="12" class="px-0">
        <h3 class="class-list-title mt-7 mb-5">Downloads</h3>

        <v-tabs
          v-model="tab"
          background-color="transparent"
          dark
          color="#F39200"
          class="mb-5 flex-wrap"
          active-class="tab-selected"
          hide-slider
        >
          <v-tab v-for="page in tabs" :key="page" class="tabs-title">
            {{ page }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab" class="tabs-item-container glassify">
          <v-tab-item :key="'Ondemand'">
            <v-col cols="12">
              <ReportResourcesStatus
                v-if="courseSelected.id"
                :select-course="selectCourse"
                :course="courseSelected"
                :class-id="selectedClassId"
              />
              <template v-else>
                <h5
                  class="pt-2 mb-4 card-title flex-grow-1"
                  :class="
                    $vuetify.breakpoint.smAndDown
                      ? 'text-center'
                      : 'text-start pl-4'
                  "
                >
                  Ondemand
                </h5>
                <h6 class="page-subtitle pl-4 mb-5">
                  Clicca sul corso ondemand del quale vuoi visualizzare i dati.
                </h6>
                <ReportOnDemandTable
                  :class-id="selectedClassId"
                  :select-course="selectCourse"
                />
              </template>
            </v-col>
          </v-tab-item>
          <v-tab-item :key="'Live'">
            <v-col cols="12">
              <h5
                class="pt-2 mb-4 card-title flex-grow-1"
                :class="
                  $vuetify.breakpoint.smAndDown
                    ? 'text-center'
                    : 'text-start pl-4'
                "
              >
                Live
              </h5>
              <h6 class="page-subtitle pl-4 mb-5">
                Scarica il report delle presenze dei tuoi alunni ai corsi live.
              </h6>
              <ReportPing
                :class-id="selectedClassId"
                :selected-class="selectedLiveClass"
                :select-course="selectCourse"
              />
            </v-col>
          </v-tab-item>
          <v-tab-item :key="'Test'">
            <v-col v-if="!quizReport" cols="12">
              <h5
                class="pt-2 mb-4 card-title flex-grow-1"
                :class="
                  $vuetify.breakpoint.smAndDown
                    ? 'text-center'
                    : 'text-start pl-4'
                "
              >
                Test
              </h5>
              <h6 class="page-subtitle pl-4 mb-5">
                Clicca sul quiz del quale vuoi visualizzare i dati.
              </h6>
              <QuizAggregatedTable
                :class-id="selectedClassId"
                @onReportDownloaded="populateQuizReport"
              />
            </v-col>
            <v-col v-else>
              <v-btn
                color="white--text transparent"
                elevation="0"
                @click="() => populateQuizReport(null)"
              >
                <img
                  src="@assets/exam/left-arrow.webp"
                  alt="return to quiz list"
                  class="mr-5"
                />
                Torna a lista corsi
              </v-btn>
              <QuizStudentListTable
                :quiz-report="quizReport"
                :selected-class-id="selectedLiveClass.id"
                :selected-class-name="selectedLiveClass.name"
              />
            </v-col>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-col>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import SmallClassCard from "../../components/platform/teacher/SmallClassCard.vue";
import QuizAggregatedTable from "../../components/platform/report/QuizAggregatedTable.vue";
import QuizStudentListTable from "../../components/platform/report/QuizStudentListTable.vue";
import ReportOnDemandTable from "../../components/platform/teacher/ReportOnDemandTable.vue";
import ReportPing from "../../components/platform/teacher/ReportPing.vue";
import ReportResourcesStatus from "../../components/platform/teacher/ReportResourcesStatus.vue";

export default {
  name: "Report",
  components: {
    SmallClassCard,
    ReportOnDemandTable,
    QuizAggregatedTable,
    QuizStudentListTable,
    ReportResourcesStatus,
    ReportPing,
  },
  data() {
    return {
      selectedClassId: null,
      selectedLiveClass: null,
      tabs: ["Ondemand", "Live", "Test"],
      tab: "",
      quizReport: null,
      courseSelected: false,
    };
  },
  computed: {
    ...mapGetters("classrooms", {
      classrooms: "getClassrooms",
    }),
    hasClasses() {
      return this.classrooms.length !== 0;
    },
  },
  methods: {
    selectClass({ id }) {
      this.populateQuizReport(null);
      this.selectCourse('');
      this.selectedClassId = id;
      this.selectedLiveClass = this.classrooms.find(
        (classroom) => classroom.id === this.selectedClassId
      );
    },
    populateQuizReport(quizReport) {
      this.quizReport = quizReport;
    },
    selectCourse(item) {
      this.courseSelected = item;
    },
  },
};
</script>

<style scoped>
.page-title {
  font-weight: 700;
  font-size: 48.83px;
  line-height: 50px;
  letter-spacing: 1px;
  color: #ffffff;
}
.page-subtitle {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #98a6ae;
}

.class-list-title {
  font-family: "outerorbit", "Orbitron";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  color: #f4f5f6;
}

.tabs-title {
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  padding: 0;
  min-width: 0;
  margin-right: 60px;
  text-transform: none;
}

.tab-selected {
  font-weight: 700;
}

.card-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 27.5px;
  letter-spacing: 1px;
  color: #ffffff;
}

.tabs-item-container {
  background: transparent;
}
</style>
