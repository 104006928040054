var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pt-0",attrs:{"id":"container-report"}},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('h1',{staticClass:"page-title mb-10",class:_vm.$vuetify.breakpoint.mdAndDown ? 'text-center' : 'text-left'},[_vm._v(" Report ")]),_c('h5',{staticClass:"page-subtitle mb-10"},[_vm._v(" Scegli la classe e scarica i report relativi ai corsi Ondemand, Live e Test. ")]),_c('h3',{staticClass:"class-list-title mb-10"},[_vm._v("Lista classi")]),_c('v-row',{attrs:{"no-gutters":""}},_vm._l((_vm.classrooms),function(classroom,index){return _c('v-col',{key:index,class:{
          'px-2': _vm.$vuetify.breakpoint.smAndDown,
        },attrs:{"cols":"12","sm":"6","md":"2"}},[_c('SmallClassCard',{attrs:{"id":classroom.id,"name":classroom.name,"number-of-students":classroom.students.length,"current-id":_vm.selectedClassId},on:{"onClassSelected":_vm.selectClass}})],1)}),1),(_vm.selectedClassId)?_c('v-col',{staticClass:"px-0",attrs:{"cols":"12"}},[_c('h3',{staticClass:"class-list-title mt-7 mb-5"},[_vm._v("Downloads")]),_c('v-tabs',{staticClass:"mb-5 flex-wrap",attrs:{"background-color":"transparent","dark":"","color":"#F39200","active-class":"tab-selected","hide-slider":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.tabs),function(page){return _c('v-tab',{key:page,staticClass:"tabs-title"},[_vm._v(" "+_vm._s(page)+" ")])}),1),_c('v-tabs-items',{staticClass:"tabs-item-container glassify",model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{key:'Ondemand'},[_c('v-col',{attrs:{"cols":"12"}},[(_vm.courseSelected.id)?_c('ReportResourcesStatus',{attrs:{"select-course":_vm.selectCourse,"course":_vm.courseSelected,"class-id":_vm.selectedClassId}}):[_c('h5',{staticClass:"pt-2 mb-4 card-title flex-grow-1",class:_vm.$vuetify.breakpoint.smAndDown
                    ? 'text-center'
                    : 'text-start pl-4'},[_vm._v(" Ondemand ")]),_c('h6',{staticClass:"page-subtitle pl-4 mb-5"},[_vm._v(" Clicca sul corso ondemand del quale vuoi visualizzare i dati. ")]),_c('ReportOnDemandTable',{attrs:{"class-id":_vm.selectedClassId,"select-course":_vm.selectCourse}})]],2)],1),_c('v-tab-item',{key:'Live'},[_c('v-col',{attrs:{"cols":"12"}},[_c('h5',{staticClass:"pt-2 mb-4 card-title flex-grow-1",class:_vm.$vuetify.breakpoint.smAndDown
                  ? 'text-center'
                  : 'text-start pl-4'},[_vm._v(" Live ")]),_c('h6',{staticClass:"page-subtitle pl-4 mb-5"},[_vm._v(" Scarica il report delle presenze dei tuoi alunni ai corsi live. ")]),_c('ReportPing',{attrs:{"class-id":_vm.selectedClassId,"selected-class":_vm.selectedLiveClass,"select-course":_vm.selectCourse}})],1)],1),_c('v-tab-item',{key:'Test'},[(!_vm.quizReport)?_c('v-col',{attrs:{"cols":"12"}},[_c('h5',{staticClass:"pt-2 mb-4 card-title flex-grow-1",class:_vm.$vuetify.breakpoint.smAndDown
                  ? 'text-center'
                  : 'text-start pl-4'},[_vm._v(" Test ")]),_c('h6',{staticClass:"page-subtitle pl-4 mb-5"},[_vm._v(" Clicca sul quiz del quale vuoi visualizzare i dati. ")]),_c('QuizAggregatedTable',{attrs:{"class-id":_vm.selectedClassId},on:{"onReportDownloaded":_vm.populateQuizReport}})],1):_c('v-col',[_c('v-btn',{attrs:{"color":"white--text transparent","elevation":"0"},on:{"click":() => _vm.populateQuizReport(null)}},[_c('img',{staticClass:"mr-5",attrs:{"src":require("@assets/exam/left-arrow.webp"),"alt":"return to quiz list"}}),_vm._v(" Torna a lista corsi ")]),_c('QuizStudentListTable',{attrs:{"quiz-report":_vm.quizReport,"selected-class-id":_vm.selectedLiveClass.id,"selected-class-name":_vm.selectedLiveClass.name}})],1)],1)],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }