<template>
  <div
    style="
      background-color: #01002e;
      width: 100vw;
      height: 100vh;
      text-align: center;
    "
  >
    <v-icon
      class="animate-spin"
      size="4em"
      style="color: white; margin-top: 40vh"
      >mdi-loading</v-icon
    >
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { pushAuthUrl, pushAdminUrl } from "@/utils/externalRouter";

export default {
  async mounted() {
    const token = this.$cookies.get("hg-token");
    if (token == null) {
      pushAuthUrl();
    }
    try {
      const claims = JSON.parse(Buffer.from(token.split(".")[1], "base64"));
      if (claims.access_level === 1) {
        pushAdminUrl("/auth/sso", token);
      } else {
        await this.setToken({ token, claims });
        if (this.$route.query && this.$route.query.redirect) {
          const redirectUri = this.$route.query.redirect;
          const parsedUri = decodeURIComponent(redirectUri);
          this.$router.push(parsedUri);
          return;
        }
        switch (claims.access_level) {
          case 2:
            this.$router.push({ name: "teacher-platform-home-page" });
            return;
          case 3:
            this.$router.push({ name: "student-platform-home-page" });
            return;
          case 5:
            this.$router.push({ name: "sorint-platform-home-page" });
            return;
          default:
            this.$router.push({ name: "guest-platform-home-page" });
        }
      }
    } catch (error) {
      console.log(error);
      pushAuthUrl();
    }
  },
  methods: {
    ...mapActions("auth", ["setToken"]),
  },
};
</script>

<style scoped>
.token {
  z-index: 3;
}
</style>
